import Vuex from 'vuex';
import Vue from 'vue';
import createPersistedState from "vuex-persistedstate";
import auth from './modules/auth';
import axios from 'axios';
// Load Vuex
Vue.use(Vuex);
// Create store
export default new Vuex.Store({
  modules: {
    auth
  },
  plugins: [createPersistedState()],
  state: {
      products: null,
      cart: [],
      toast: { 
          text: "",
          show: false
      },
      products_id : [],
      status_bucatarie : 1,
      tip_tranzactie : 2,
      durata_livrare_state : 0 ,
      eroare_generala_paid : '',
      location_shop : ''
  },
  getters: {
      cartSize: (state) => {
          return state.cart.length;
      },
      cartTotalAmount: (state) => {
          return state.cart.reduce((total, product) => {
              return total + (product.price * product.quantity);
          }, 0);
      },
      toast: (state) => {
          return state.toast;
      }
  },
  mutations: {
      seteazaIdApp : (state, valoare) => {
        state.location_shop = valoare;
      },
      seteazaProduseFavorite : (state, valoare) => {
        state.products_id = valoare;
      },
      seteazaTipTranzactie : (state, valoare) => {
        state.tip_tranzactie = valoare;
      },
      seteazaEroare : (state, valoare) => {
        state.eroare_generala_paid = valoare;
      },
      seteazaDurataLivrare : (state, valoare) => {
        state.durata_livrare_state = valoare;
      },
      seteazaStatusBucatarie: (state,valoare) => {
          state.status_bucatarie = valoare;
      },
      setUpProducts: (state, productsPayload) => {
          //sets the state's  products property to the products array recieved as payload
          state.products = productsPayload;
      },
      addToCart: (state, productId) => {
          //find the product in the products list
          let product = state.products.find((product) => product.id === productId);
          //find the product in the cart list
          let cartProduct = state.cart.find((product) => product.id === productId);

          if (cartProduct) {
              //product already present in the cart. so increase the quantity
              cartProduct.quantity++;
          } else {
              state.cart.push({
                  // product newly added to cart
                  ...product,
                  stock: product.quantity,
                  quantity: 1,
              });
          }
          //reduce the quantity in products list by 1
          product.quantity--;
      },
      removeFromCart: (state, productId) => {
          //find the product in the products list
          let product = state.products.find((product) => product.id === productId);
          //find the product in the cart list
          let cartProduct = state.cart.find((product) => product.id === productId);

          cartProduct.quantity--;
          //Add back the quantity in products list by 1
          product.quantity++;
      },
      deleteFromCart: (state, productId) => {
          //find the product in the products list
          let product = state.products.find((product) => product.id === productId);
          //find the product index in the cart list
          let cartProductIndex = state.cart.findIndex((product) => product.id === productId);
          //srt back the quantity of the product to intial quantity
          product.quantity = state.cart[cartProductIndex].stock;
          // remove the product from the cart
          state.cart.splice(cartProductIndex, 1);
      },
      showToast: (state, toastText) => {
          state.toast.show = true;
          state.toast.text = toastText;
      },
      hideToast: (state) => {
          state.toast.show = false;
          state.toast.text = "";
      }
  },
  actions: {
      
  }
});