<template>
 <div id="nav">
   
   <menu class="menu">
      <div class="menu__blackout"></div>
      <div class="menu__collapse">
        <div class="menu_title">
          <div class="titlemenu">{{$t('meniu') }}</div>
          <div class="close_menu"><i class="ri-close-line"></i></div>
        </div>
        <div v-if="isLoggedIn" class="menu_list autentificat">
          <div class="categorii_menu">
            <div v-for="categorie in categorii" :key="categorie.id">
              <a class="categorii_item"  v-if="categorie.products.length > 0"  :href="'/meniu#produse-'+categorie.slug">{{categorie.name}}</a>
            </div>
          </div>
          <a href="/meniu" class="menu_item"><i class="ri-file-list-3-line"></i>{{ $t('vezi_tot_meniul') }}</a> 
           <div class="despartitor_meniu"></div>
          <div class="menu_item_profil vezi_contul_profilului" @click="vezi_contul_profilului()">
            <div class="menu_item_profil_left">
              <i class="ri-user-3-fill"></i>{{ $t('my_profile') }}
            </div>
            <div class="menu_item_profil_right">
                <i class="ri-arrow-down-s-line sageata_block"></i>
                <i class="ri-arrow-up-s-line sageata_block sageata_none"></i>
            </div>
          </div>
          <div class="meniu_profil_ascuns">
            <div class="menu_item vezi_profil_autentificat"  v-on:click="$refs.modal_profil.open()"><div class="profil_autentificat"><i class="ri-contacts-line"></i></div>{{ $t('datele_mele') }}</div>
            <div class="menu_item vezi_comenzi_btn"  v-on:click="$refs.modal_orders.open()"><i class="ri-shopping-bag-line"></i>{{ $t('my_orders') }}</div>
            <div class="menu_item vezi_adresele"  v-on:click="$refs.modal_addresses.open()"><i class="ri-map-pin-line"></i>{{ $t('my_addresses') }}</div>
            <div class="menu_item display_none"  v-on:click="$refs.modal_cards.open()"><i class="ri-bank-card-2-line"></i>{{ $t('my_cards') }}</div>
            <div class="menu_item vezi_produse_favorite"  v-on:click="$refs.modal_favorite.open()"><i class="ri-heart-line"></i>{{ $t('my_favorites') }}</div>
            <div class="menu_item vezi_setari"  v-on:click="$refs.modal_setari.open()"><i class="ri-settings-2-line"></i>{{ $t('settings') }}</div>
          </div> 
          <router-link to="/news" class="menu_item"><i class="ri-file-text-line"></i>{{ $t('news') }}</router-link>
          <router-link to="/about" class="menu_item"><i class="ri-information-line"></i>{{ $t('about_us') }}</router-link>
          <router-link to="/contact" class="menu_item"><i class="ri-chat-smile-2-line"></i>{{ $t('contact') }}</router-link>
          <div class="despartitor_meniu"></div>
        </div>
        <div v-else class="menu_list neautentificat">
          <div class="menu_item"  v-on:click="$refs.modal_profil_neautentificat.open()"><i class="ri-user-3-line"></i>{{ $t('my_profile') }}</div>
          <a href="/meniu" class="menu_item"><i class="ri-file-list-3-line"></i>{{ $t('vezi_tot_meniul') }}</a> 
          <router-link to="/news" class="menu_item"><i class="ri-file-text-line"></i>{{ $t('news') }}</router-link>
          <router-link to="/about" class="menu_item"><i class="ri-information-line"></i>{{ $t('about_us') }}</router-link>
          <router-link to="/contact" class="menu_item"><i class="ri-chat-smile-2-line"></i>{{ $t('contact') }}</router-link>
        </div>
        
        
        <div class="menu_bottom">
          <router-link to="/termeni_si_conditii" class="bottom_item">{{ $t('term_cond') }}</router-link>
          <router-link to="/politica_de_confidentialitate" class="bottom_item">{{ $t('priv_poli') }}</router-link>
          <router-link to="/contact"  class="bottom_item">{{ $t('faq_support') }}</router-link>
          <div  v-if="isLoggedIn"  class="logout_button" @click="logout"><i class="ri-logout-circle-line"></i>{{ $t('log_out') }}</div>
        </div>
      </div>
    </menu>

    <div class="cos">
      <div class="menu__blackout1"></div>
      <div class="menu__collapse1">
        <router-link to="/" class="logo element_mobile logo_cos_mobile"><img src= '@/assets/images/logo-tiki-bistro.png' /></router-link>
        <div class="cos_title_between">
          <div class="direction_column">
            <div class="title_cos">{{ $t('shopping_bag') }}</div>
            <div class="nr_produse_cos" v-if="total_produse_cos != 1">{{ total_produse_cos }} {{ $t('products') }}</div>
            <div class="nr_produse_cos" v-else>{{ total_produse_cos }} {{ $t('one_product') }}</div>
          </div>
          <div class="close_menu1">
            <i class="ri-close-line cart_inapoi_desktop"></i>
            <i class="ri-arrow-left-s-line cart_inapoi_mobile"></i>          
          </div>
        </div>
        
        <div class="produse_cos">
        <div class="border_cos_produs">
            <div v-for="(produs_cos, index) in cosCumparaturi.produse" :key="produs_cos.id" class="cos_produs">
                <div class="img_cos">
                  <img :src='produs_cos.poza_produs' />
                  </div>
                <div class="produs_right">
                  <div class="space_between_cos margin_bottom_cos">
                    <div class="titlu_desc_cos">
                      <div class="titlu_produs_cos"  v-if="$i18n.locale == 'ro'">
                        {{produs_cos.nume_produs}}
                      </div>
                      <div class="titlu_produs_cos"  v-else-if="$i18n.locale == 'en'">
                        {{produs_cos.nume_produs_en}}
                      </div>
                      <div class="desc_cos" v-if="$i18n.locale == 'ro'">{{produs_cos.descriere_produs | strippedContent}}</div>
                      <div class="desc_cos" v-else-if="$i18n.locale == 'en'">{{produs_cos.descriere_produs_en | strippedContent}}</div>
                      <div v-if="produs_cos.produse_extra && produs_cos.produse_extra.length > 0" class="produs e_extra">
                           <div v-for="produs_extra in produs_cos.produse_extra" :key="produs_extra.id" class="produse_extra_desc">{{produs_extra[0]}}</div>      
                      </div>
                      <div v-if="produs_cos.optiuni_extra_produs && produs_cos.optiuni_extra_produs.length > 0" class="produs e_extra">
                           <div class="produse_extra_desc">{{produs_cos.optiuni_extra_produs[0]}} </div>      
                      </div>
                       <div v-if="produs_cos.nota_produs_individual != ''" class="produs e_extra">
                           <div class="produse_extra_desc">{{produs_cos.nota_produs_individual}}</div>      
                      </div>
                      
                      <div class="reducere_icon reducere_custom" v-if="produs_cos.discount_procent > 0">
                        -{{produs_cos.discount_procent}}%
                      </div>
                    </div>
                    <div class="sterge_produs_cos" @click="deleteItem(index)"><i class="ri-delete-bin-6-line"></i></div>
                  </div>
                  <div class="space_between_cos">
                      <div class="adauga_produse_plus_minus">
                      <font-awesome-icon v-on:click="produs_cos.cantitate_produs--" @click="deleteItemCount(produs_cos.cantitate_produs,index)" :icon="{ prefix: 'fas', iconName: 'minus' }" class="minus_plus_cos minus_cos minus_plus_cos_activ"/>
                      <div class="numar_cos_prd noselect">{{produs_cos.cantitate_produs}}</div>
                      <font-awesome-icon  v-on:click="produs_cos.cantitate_produs++" :icon="{ prefix: 'fas', iconName: 'plus' }" class="minus_plus_cos plus_cos minus_plus_cos_activ"/>
                    </div>
                    
                    <div class="pret_cos_produs noselect">


                        <div v-if="produs_cos.discount_procent > 0">
                           
                          <div class="pret_box_taiat" >{{(produs_cos.total_produs*produs_cos.cantitate_produs).toFixed(2)}} Lei</div>
                          <div class="pret_box" >{{ (produs_cos.discount_price_final*produs_cos.cantitate_produs + produs_cos.total_extra).toFixed(2) }} lei</div>
                        </div>
                        <div class="box_pret_container" v-else>
                           {{(produs_cos.total_produs*produs_cos.cantitate_produs).toFixed(2)}} Lei
                        </div>

                    </div>
                  </div>
                </div>
              </div>
        </div>
        <!-- <div class="total_cos">
          <div class="text_total">{{ $t('total') }}</div>
          <div class="pret_total_cos">{{total_pret_cos}} Lei</div>
        </div> -->
        <div class="subtotal_total total_cos_padding">
          <div class="general_box_total">
              <div class="general_text_total">Subtotal</div>
              <div class="general_text_total"> {{total_pret_cos.toFixed(2)}} Lei</div>
          </div>
          <!-- <div class="general_box_total">
              <div class="general_text_total">{{ $t('delivery') }}</div>
              <div class="general_text_total">{{ pret_livrare }} Lei</div>
          </div>
          <div class="general_box_total" v-if="voucher_status_id == 2 && voucher_status_id != 0">
              <div class="general_text_total">Discount</div>
              <div class="general_text_total">{{voucher_value}} {{voucher_currency}}</div>
          </div>
          
          <div class="general_box_total orange_total">
              <div class="general_text_total">Total</div>
              <div class="general_text_total">{{parseFloat(pret_final_comanda).toFixed(2)}} Lei</div>
          </div> -->
        </div>
        <!-- <div v-if="total_pret_cos < this.comanda_minima_livrare_gratuita && total_pret_cos > (this.comanda_minima_livrare_gratuita * 0.8) && pret_livrare != 0" class="livrare_generala livrare_mai_mica">{{ $t('livrare_mai_mica_1') }} {{this.comanda_minima_livrare_gratuita - total_pret_cos}} {{ $t('livrare_mai_mica_2') }}</div>
        <div v-else-if="total_pret_cos >= this.comanda_minima_livrare_gratuita" class="livrare_generala livrare_gratuita">{{ $t('livrare_gratuita') }}</div> -->

        <!-- <div v-if="total_pret_cos < this.comanda_minima" class="livrare_generala livrare_mai_mica">{{ $t('livrare_mai_mica_1') }} {{this.comanda_minima - total_pret_cos}} {{ $t('livrare_minima') }}</div> -->

          <div class="butoane_cos">
              <div  v-if="isLoggedIn && this.cosCumparaturi.produse.length > 0" class="finalizeaza_comanda afisare_checkout buton_cos_general get_informatii" v-on:click="$refs.modal_checkout.open()">{{ $t('finalizeaza_comanda') }}</div>
              <div  v-else-if="isLoggedIn && this.cosCumparaturi.produse.length == 0" class="buton_cos_general">{{ $t('finalizeaza_comanda') }}</div>
              <div  v-else-if="!isLoggedIn && this.cosCumparaturi.produse.length == 0" class="buton_cos_general" v-on:click="$refs.modal_profil_neautentificat.open()">{{ $t('finalizeaza_comanda') }}</div>
              <div  v-else class="finalizeaza_comanda buton_cos_general" v-on:click="$refs.modal_profil_neautentificat.open()">{{ $t('finalizeaza_comanda') }}</div>
              <router-link to="/meniu" class="continua_cumparaturi buton_cos_general">{{ $t('continua_cumparaturile') }}</router-link>
          </div>
             
        </div>
      </div>
    </div>

    <div class="containerg no_padding_top_mobile">
      <div class="meniu_site">
        <div class="meniu_site_stanga">
          <div class="menu_logo_width">
          <div class="menu_header no_decoration menu__toggle"><i class="ri-menu-line"></i></div>
         
          <div class="limba_section">
          <div class="limba element_mobile" v-if="$i18n.locale == 'ro'"><img src= '@/assets/images/icon-romanian-flag.png' /></div>
          <div class="limba element_mobile" v-else><img src= '@/assets/images/Group 2225.png' /></div>
          <div class="change_lang">
              <div class="lang_item" v-for="entry in languages" :key="entry.title" @click="changeLocale(entry.language)">
                  <img :src= 'entry.flag' /> {{entry.title}}
              </div>
            </div>
          </div>
         
          <router-link to="/" class="logo element_desktop"><img src= '@/assets/images/logo-tiki-bistro.png' /></router-link>
          </div>
          <form action="/search">
          <div class="search_relative element_desktop">       
              <input class="search_header" autocomplete="none" type="search" name="keywords" :placeholder="$t('search_food') " />
              {{search_key}}{{this.search_key}}
              <i class="ri-close-line close_search_header"></i>
              <font-awesome-icon :icon="{ prefix: 'fas', iconName: 'search' }" class="icon_search"/>
            
          </div>
          </form>
        </div>
        <div class="meniu_site_centru element_mobile">
          <router-link to="/" class="logo"><img src= '@/assets/images/logo-tiki-bistro.png' /></router-link>
        </div>
        <div class="meniu_site_dreapta">
          <div class="comanda_acun element_desktop vezi_comenzi_btn" v-if="isLoggedIn" v-on:click="$refs.modal_orders.open()">{{ $t('my_orders') }}</div>
          <div class="comanda_acun element_desktop" v-else v-on:click="$refs.modal_profil_neautentificat.open()">{{ $t('comanda_acum') }}</div>
          <a href="tel:0747247876" class="no_decoration element_desktop display_flex_desk"><i class="ri-phone-line icon_header "></i></a>
          <div class="limba_section">
            <div class="limba element_desktop" v-if="$i18n.locale == 'ro'"><img src= '@/assets/images/icon-romanian-flag.png' /></div>
            <div class="limba element_desktop" v-else><img src= '@/assets/images/Group 2225.png' /></div>
            <div class="change_lang">
              <div class="lang_item" v-for="entry in languages" :key="entry.title" @click="changeLocale(entry.language)">
                  <img :src= 'entry.flag' /> {{entry.title}}
              </div>
            </div>
          </div>
          <div class="no_decoration imagine_autentificat position_relative"  v-if="isLoggedIn"  @click="vezi_dw_profil()">
            <i class="ri-user-3-fill i_inactiv"></i>
            <div  class="modal_profilul_meu_date">
                    <div class="menu_item_modal vezi_profil_autentificat"  v-on:click="$refs.modal_profil.open()"><div class="profil_autentificat"><i class="ri-contacts-line"></i></div>{{ $t('datele_mele') }}</div>
                    <div class="menu_item_modal vezi_comenzi_btn"  v-on:click="$refs.modal_orders.open()"><i class="ri-shopping-bag-line"></i>{{ $t('my_orders') }}</div>
                    <div class="menu_item_modal vezi_adresele"  v-on:click="$refs.modal_addresses.open()"><i class="ri-map-pin-line"></i>{{ $t('my_addresses') }}</div>
                    <div class="menu_item_modal display_none"  v-on:click="$refs.modal_cards.open()"><i class="ri-bank-card-2-line"></i>{{ $t('my_cards') }}</div>
                    <div class="menu_item_modal vezi_produse_favorite"  v-on:click="$refs.modal_favorite.open()"><i class="ri-heart-line"></i>{{ $t('my_favorites') }}</div>
                    <div class="menu_item_modal vezi_setari"  v-on:click="$refs.modal_setari.open()"><i class="ri-settings-2-line"></i>{{ $t('settings') }}</div>
                    <div v-if="isLoggedIn" @click="logout" class="menu_item_modal"><i class="ri-logout-circle-line"></i>{{ $t('log_out') }}</div>
            </div>
          </div>
          
          <div class="no_decoration" v-else v-on:click="$refs.modal_profil_neautentificat.open()"><i class="ri-user-3-line icon_header click_autentificare"></i></div>
          <div class="no_decoration cos_header">
            <div class="positionrelative">
              <i class="ri-shopping-bag-line icon_header no_margin_icon "></i>
              <div class="rosu">{{ total_produse_cos }}</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  <sweet-modal  ref="modal_telefon_null" class="modal_telefon_null" blocking  v-on:open="vezi_modal"> 
    <div class="modal_telefon_null_1">
          <div class="titlu_modal_checkout">{{ $t('enter_your_phone_number') }}</div>
          <div class="sectiune_jos_modal">
            <VuePhoneNumberInput default-country-code="RO" v-if="$i18n.locale == 'ro'"  :translations="{countrySelectorLabel: 'Codul tarii',phoneNumberLabel: 'Numar de telefon',example: 'Exemplu :'}" v-model="telefon" class="telefon_checkout_modal_4" @update="updatePhoneNumber" />
             <VuePhoneNumberInput default-country-code="RO" v-else v-model="telefon" class="telefon_checkout_modal_4" @update="updatePhoneNumber" />
              <div class="complete_adr complete_tlf_activ_null">{{ $t('verifica_phone') }}</div>
              <div v-if="$i18n.locale == 'ro'" class="eroare">{{eroare_telefon_ro}}</div>
              <div v-else-if="$i18n.locale == 'en'" class="eroare">{{eroare_telefon_en}}</div>
          </div>  
      </div>
      <div class="verificare_telefon_null">
              <div class="element_mobile"><img src= '@/assets/images/logo-tiki-bistro.png' /></div>
              <div @click="inapoi_modal_telefon_null" class="inapoi_modal_telefon_null"><i class="ri-arrow-left-s-line"></i></div>
              <div class="titlu_recupereaza_parola color_verifica_telefon">{{ $t('introdu_codul')}}<span>{{ this.cod_tara_nefinalizat  }} {{this.telefon_comanda_nefinalizat}}</span></div>
              <div class="inputuri_cod_telefon inputuri_cod_telefon_null">
                  <input autocomplete="none" type="number" name="nr1_tel" class="nr1_tel nr_tel_general" value="" maxlength="1" />
                  <input autocomplete="none" type="number" name="nr2_tel" class="nr2_tel nr_tel_general" value="" maxlength="1"/>
                  <input autocomplete="none" type="number" name="nr3_tel" class="nr3_tel nr_tel_general" value="" maxlength="1"/>
                  <input autocomplete="none" type="number" name="nr4_tel" class="nr4_tel nr_tel_general" value=""  maxlength="1"/>
              </div>
              <div class="cod_neprimit">{{ $t('cod_neprimit')}}</div>
              <button class="next_modal_telefon actualizeaza_numar_null">{{ $t('complete_phone')}}</button>
              <div v-if="$i18n.locale == 'ro'" class="eroare">{{eroare_cod_telefon}}</div>
              <div v-else-if="$i18n.locale == 'en'" class="eroare">{{eroare_cod_telefon_en}}</div>
            
      </div>
  </sweet-modal>
  
  <sweet-modal  ref="modal_checkout" class="modal_checkout" blocking v-on:close="afisareCheckoutInitial()"  v-on:open="vezi_modal"> 
      <div class="element_mobile "><img src= '@/assets/images/logo-tiki-bistro.png' /></div>
      <div class="modal_start_checkout">
      <div class="titlu_modal_checkout">Checkout</div>
      <div class="sectiune_jos_modal">
          <div class="sectiune_produse_checkout">
            <div class="produs_checkout" v-for="(produs_cos, index) in cosCumparaturi.produse" :key="produs_cos.id">
                <div class="img_produs_checkout">
                  
                  <img :src='produs_cos.poza_produs' />
                  </div>
                <div class="produs_checkout_dreapta">
                    <div class="space_between">
                        <div class="nume_produs_checkout"  v-if="$i18n.locale == 'ro'">{{produs_cos.nume_produs}}</div>
                        <div class="nume_produs_checkout"  v-else-if="$i18n.locale == 'en'">{{produs_cos.nume_produs_en}}</div>
                        
                        <div class="plus_minus_checkout">
                            <font-awesome-icon v-on:click="produs_cos.cantitate_produs--" @click="deleteItemCount(produs_cos.cantitate_produs,index)" :icon="{ prefix: 'fas', iconName: 'minus' }" class="minus_checkout"/>
                            <font-awesome-icon v-on:click="produs_cos.cantitate_produs++" :icon="{ prefix: 'fas', iconName: 'plus' }" class="plus_checkout"/>
                        </div>
                    </div>
                    <div class="reducere_icon reducere_custom" v-if="produs_cos.discount_procent > 0">
                      -{{produs_cos.discount_procent}}%
                    </div>
                    <div v-if="produs_cos.produse_extra && produs_cos.produse_extra.length > 0" class="produse_extra_checkout">
                        <div v-for="produs_extra in produs_cos.produse_extra" :key="produs_extra.id" class="produse_extra_desc">{{produs_extra[0]}}</div>      
                    </div>
                    <div v-if="produs_cos.optiuni_extra_produs && produs_cos.optiuni_extra_produs.length > 0" class="produs e_extra">
                           <div class="produse_extra_desc" style="text-align:left;">{{produs_cos.optiuni_extra_produs[0]}}</div>      
                    </div>
                    <div v-if="produs_cos.nota_produs_individual != ''" class="produs e_extra">
                          <div class="produse_extra_desc" style="text-align:left;">{{produs_cos.nota_produs_individual}}</div>      
                    </div>
                    <div class="space_between">

                          <div v-if="produs_cos.discount_procent > 0">
                            <div class="items_pret pret_box_taiat"  v-if="produs_cos.cantitate_produs != 1 && produs_cos.cantitate_produs > 0">{{produs_cos.cantitate_produs}} {{ $t('items') }} × {{(produs_cos.total_produs).toFixed(2)}} Lei</div>
                            <div class="items_pret pret_box_taiat"  v-else-if="produs_cos.cantitate_produs == 1 ">{{produs_cos.cantitate_produs}} {{ $t('one_product') }} × {{produs_cos.total_produs}} Lei</div>

                            <div class="items_pret"  v-if="produs_cos.cantitate_produs != 1 && produs_cos.cantitate_produs > 0">{{produs_cos.cantitate_produs}} {{ $t('items') }} × {{(produs_cos.discount_price_final).toFixed(2)}} Lei</div>
                            <div class="items_pret"  v-else-if="produs_cos.cantitate_produs == 1 ">{{produs_cos.cantitate_produs}} {{ $t('one_product') }} × {{produs_cos.discount_price_final}} Lei</div>

                          </div>
                          <div class="box_pret_container" v-else>
                            <div class="items_pret"  v-if="produs_cos.cantitate_produs != 1 && produs_cos.cantitate_produs > 0">{{produs_cos.cantitate_produs}} {{ $t('items') }} × {{produs_cos.total_produs}} Lei</div>
                            <div class="items_pret"  v-else-if="produs_cos.cantitate_produs == 1 ">{{produs_cos.cantitate_produs}} {{ $t('one_product') }} × {{produs_cos.total_produs}} Lei</div>
                          </div>
 
                          <div class="pret_total_produs">
                              <div v-if="produs_cos.discount_procent > 0">
                                <div class="pret_box_taiat" style="margin-right:0;">{{(produs_cos.total_produs*produs_cos.cantitate_produs).toFixed(2)}} Lei</div>
                                <div class="pret_box" >{{ (produs_cos.discount_price_final*produs_cos.cantitate_produs + produs_cos.total_extra).toFixed(2) }} lei</div>
                              </div>
                              <div class="box_pret_container" v-else>
                                {{(produs_cos.total_produs*produs_cos.cantitate_produs).toFixed(2)}} Lei
                              </div>
                            </div>
                      </div>
                  </div>
              </div>
            </div>
            <div class="detalii_livrare">
              <div class="title_box_livrare">
                <div class="title_livrare">{{ $t('delivery_address') }}</div>
                <div class="position_relative">
                  <div class="box_detalii_livrare completeaza_adresa">
                    <div class="adresa_completa_modal_start" v-if="adresa_selectata.prima_adresa !='' && adresa_selectata.adresa_2 !='' ">
                      
                      <div v-if="this.address_id == 0">
                        <div class="adresa_user">{{ $t('ridicare_personala') }} </div>
                          <div class="adresa_descriere">{{ adresa_selectata.adresa_2 }}</div>
                        </div>
                      <div v-else>   
                        <div class="adresa_user">{{ adresa_selectata.prima_adresa }}</div>
                          <div class="adresa_descriere">{{ adresa_selectata.adresa_2 }}</div>
                        </div>
                    </div>
                      <div v-else>{{ $t('where_to_deliver') }}</div>
                      <i class="ri-arrow-right-s-line icon_box_livrare"></i>
                    </div>
                  
                </div>
              </div>
              <div class="title_box_livrare">
                <div class="title_livrare">{{ $t('payment_method') }}</div>
                <div class="position_relative"> 
                  <div v-if="metoda_de_plata !='' && $i18n.locale == 'ro'" class="box_detalii_livrare selecteaza_metoda_plata color_metoda">{{ metoda_de_plata}}
                    <i class="ri-arrow-down-s-line icon_box_livrare"></i>
                  </div>
                  <div v-else-if="metoda_de_plata !='' && $i18n.locale == 'en'" class="box_detalii_livrare selecteaza_metoda_plata color_metoda">{{ metoda_de_plata_en}}
                    <i class="ri-arrow-down-s-line icon_box_livrare"></i>
                  </div>
                  <div v-else class="box_detalii_livrare selecteaza_metoda_plata">{{ $t('select_payment_method') }}
                    <i class="ri-arrow-down-s-line icon_box_livrare"></i>
                  </div>
                  
                </div>
              </div>
              <div class="title_box_livrare">
                <div class="title_livrare">{{ $t('phone_number') }}</div>
                <div class="position_relative">
                  <div class="box_detalii_livrare editeaza_numarul">
                    <div class="adresa_completa_modal_start" v-if="telefon_comanda">
                      <div class="adresa_user">{{ $t('telefon') }}</div>
                      <div class="adresa_descriere">{{ cod_tara }} {{ telefon_comanda }}</div>
                    </div>
                    <div v-else>{{ $t('your_phone_number') }}</div>
                    <i class="ri-arrow-right-s-line icon_box_livrare"></i>
                  </div>
                </div>
              </div>

              <div class="title_box_livrare">
                <div class="title_livrare">Voucher</div>
                <div class="position_relative voucher_container box_detalii_livrare">
                    <input type="text" v-if="voucher_type == 0" autocomplete="none" name="voucher_input" class="voucher_input input_profil" placeholder="Voucher" :value="code_voucher" />    
                    <input type="text" v-else readonly autocomplete="none" name="voucher_input" class="voucher_input input_profil" placeholder="Voucher" :value="code_voucher"/>

                    <button v-if="voucher_type == 0" class="aplica_voucher" @click="aplicaVoucher">{{ $t('apply_voucher') }}</button>
                    <button v-else class="sterge_voucher" @click="stergeVoucher">{{ $t('sterge_voucher') }}</button>
                </div>
                <div v-if="voucher_status_id != 0" class="voucher_produse_reduse">{{ $t('voucher_status_produse_reduse') }}</div>
                <div v-if="voucher_status_id == 1 && voucher_status_id != 0" class="voucher_error">{{ $t('voucher_status_expired') }}</div>
                <div v-else-if="voucher_status_id == 2 && voucher_status_id != 0" class="voucher_ok">
                  <div>
                    {{voucher_description}}
                  </div>
                  <div>
                  {{ $t('voucher_status_valid') }}
                  </div>
                  </div>
                
              </div>


              <div class="title_box_livrare">
                <div class="title_livrare">{{ $t('descriere_comanda') }}</div>
                <div class="position_relative">
                  <textarea name="informatii_comanda" rows="2" maxlength="150" class="informatii_comanda" v-model="informatii_comanda"></textarea>
                </div>
              </div>


              <div class="title_box_livrare box_tacamuri">

                <label class="container">
                  <div class="nume_extra" for="tacamuri_check">{{ $t('tacamuri') }}</div>
                  <input type="checkbox" class="form-check-input tacamuri_check" name="tacamuri_check" id="tacamuri_check" v-model="tacamuri_check" true-value="1" false-value="0">
                  <span class="checkmark"></span>
                  </label> 

              </div>

            </div>
            <div class="subtotal_total">
              <div class="general_box_total">
                  <div class="general_text_total">Subtotal</div>
                  <div class="general_text_total">

                    <div class="flex" v-if="voucher_activ == 1">
                      <div class="pret_rosu">
                      {{pret_dupa_voucher_fara_livrare}} Lei 
                      </div>
                      <div class="pret_taiat">
                      {{total_pret_cos.toFixed(2)}} Lei   
                      </div>   
                      </div>
                    <div v-else>    
                      {{total_pret_cos.toFixed(2)}} Lei
                    </div> 
                  </div>
              </div>
              <div class="general_box_total">
                  <div class="general_text_total">{{ $t('delivery') }}</div>
                  <div class="general_text_total">{{pret_livrare}} Lei</div>
              </div>
              <div class="general_box_total" v-if="voucher_status_id == 2 && voucher_status_id != 0">
                  <div class="general_text_total">Discount</div>
                  <div class="general_text_total">{{voucher_value}} {{voucher_currency}}</div>
              </div>
              
              <div class="general_box_total orange_total">
                  <div class="general_text_total">Total</div>
                  <div class="general_text_total">{{parseFloat(pret_final_comanda).toFixed(2)}} Lei</div>
              </div>
            </div>
            <div class="produse_indisponibile">
              <span v-html="eroare_produs_bucatarie"></span>
            </div>
          
          <div v-if="adresa_a_fost_setata == 1">
            <div v-if="(pret_dupa_voucher_fara_livrare != 0 && pret_dupa_voucher_fara_livrare < this.comanda_minima_livrare_gratuita && pret_dupa_voucher_fara_livrare > (this.comanda_minima_livrare_gratuita * 0.8) && pret_livrare > 0)" class="livrare_generala livrare_mai_mica margin_bottom_livrare">{{ $t('livrare_mai_mica_1') }} {{(this.comanda_minima_livrare_gratuita - pret_dupa_voucher_fara_livrare).toFixed(2)}} {{ $t('livrare_mai_mica_2') }}</div>

            <div v-else-if="(pret_dupa_voucher_fara_livrare == 0 && total_pret_cos < this.comanda_minima_livrare_gratuita && total_pret_cos > (this.comanda_minima_livrare_gratuita * 0.8) && pret_livrare > 0)" class="livrare_generala livrare_mai_mica margin_bottom_livrare">{{ $t('livrare_mai_mica_1') }} {{(this.comanda_minima_livrare_gratuita - total_pret_cos).toFixed(2)}} {{ $t('livrare_mai_mica_2') }}</div>

            <div v-else-if="pret_dupa_voucher_fara_livrare == 0 && total_pret_cos >= this.comanda_minima_livrare_gratuita && this.comanda_minima_livrare_gratuita != 0" class="livrare_generala livrare_gratuita margin_bottom_livrare">{{ $t('livrare_gratuita') }}</div>

            <div v-else-if="pret_dupa_voucher_fara_livrare != 0 && pret_dupa_voucher_fara_livrare >= this.comanda_minima_livrare_gratuita && this.comanda_minima_livrare_gratuita != 0" class="livrare_generala livrare_gratuita margin_bottom_livrare">{{ $t('livrare_gratuita') }}</div>
           </div>
           <div v-else>
              <div class="livrare_generala livrare_mai_mica margin_bottom_livrare">
                {{ $t('livrare_generala') }}
              </div>
           </div>

            <button v-if="telefon_comanda && adresa_selectata.prima_adresa !='' && adresa_selectata.adresa_2 !='' && value_plata != 0 && this.cosCumparaturi.produse.length > 0" class="confirma_comanda confirma_comanda_activ">{{ $t('confirm_order') }}</button>
            <div v-else class="confirma_comanda">{{ $t('confirm_order') }}</div>

            <div v-if="$i18n.locale == 'ro'" class="eroare">{{eroare_comanda_minima}}</div>
            <div v-else-if="$i18n.locale == 'en'" class="eroare">{{eroare_comanda_minima_en}}</div>

            <div v-if="this.$store.state.status_bucatarie == '0'" class="mesaj_bucatarie_inchisa">
              <div v-if="$i18n.locale == 'ro'">{{msg_status_Closed_ro}}</div>
              <div v-else-if="$i18n.locale == 'en'">{{msg_status_Closed_en}}</div>
            </div>
        </div>
      </div>
      <div class="modal_2_checkout">
        <i class="ri-arrow-left-s-line inapoi_modal_checkout inapoi_modal_start_checkout"></i>
          <!-- <div class="titlu_modal_checkout">{{ $t('where_to_deliver') }}</div> -->
          <div class="sectiune_jos_modal">
              <h2 class="adrese_alb">{{ $t('where_to_deliver') }}</h2>
               <div class="orders_list min_height_zero">
                <div v-for="adresa in adrese_user" :key="adresa.id">
                  <div  v-if="adresa.address_id != 0" class="box_general_profil adresa_pt_livrare" :address_lat="adresa.address_lat" :address_lng="adresa.address_lng" :address_id="adresa.address_id" :address_pret="adresa.price" :address_minute="adresa.delivery_time">
                        <div class="label_form_profil">
                          <div :adresa_user_livrare="adresa.address" class="adresa_user adresa_user_livrare">{{adresa.address}}</div>
                          <div :adresa_descriere_livrare="adresa.description" class="adresa_descriere adresa_descriere_livrare">{{adresa.description}}</div>
                        </div>
                      <div class="icon_edit_profil">
                        <i class="ri-arrow-right-s-line "></i>
                      </div>
                  </div>
                </div>
              </div>
              <div class="adauga_adresa_noua_buton" @click="adauga_adresa_noua_buton()">{{ $t('adauga_adresa_noua') }}</div>
              <h2 class="adrese_alb">{{ $t('ridicare_personala') }}</h2>
              <div class="orders_list min_height_zero">
                <div v-for="adresa in adrese_user" :key="adresa.id">
                  <div v-if="adresa.address_id == 0" class="box_general_profil adresa_pt_livrare" :address_lat="adresa.address_lat" :address_lng="adresa.address_lng" :address_id="adresa.address_id" :address_pret="adresa.price" :address_minute="adresa.delivery_time">
                        <div class="label_form_profil">
                          <div :adresa_user_livrare="adresa.address" :adresa_user_livrare_afisare="$t('ridicare_personala')" class="adresa_user adresa_user_livrare">{{ $t('ridicare_personala') }}</div>
                          <div :adresa_descriere_livrare="adresa.description" class="adresa_descriere adresa_descriere_livrare">{{adresa.description}}</div>
                        </div>
                      <div class="icon_edit_profil">
                        <i class="ri-arrow-right-s-line "></i>
                      </div>
                  </div>
                </div>
              </div>
          </div>  
      </div>
      <div class="modal_3_checkout">
        <i class="ri-arrow-left-s-line inapoi_modal_checkout inapoi_modal_2_checkout"></i>
          <div class="titlu_modal_checkout">{{ $t('address_details') }}</div>
          <div class="sectiune_jos_modal">


              <div v-if="$i18n.locale == 'ro'" class="eroare">{{eroare_completare_adresa_nr}}</div>
              <div v-else-if="$i18n.locale == 'en'" class="eroare">{{eroare_completare_adresa_nr_en}}</div>
              <div class="position_relative margin_top_address">
                  <!-- <input v-model="location_gmaps" name="locatie_checkout" class="locatie_checkout" :placeholder="$t('introduceti_adresa_noua')" value=""> -->
                  <input id="pac-input" name="locatie_checkout" class="locatie_checkout" type="text" :placeholder="$t('introduceti_adresa_noua')" autocomplete="none" />
                  <i class="ri-send-plane-line icon_locatie_checkout"></i>
              </div> 
              <input name="locatie_checkout_modal_3" class="locatie_checkout_modal_3 margin_top_address" readonly :placeholder="$t('detalii_adresa')" value="" autocomplete="none" maxlength="99"/> 

              <div class="mapssssss">
                <div class="pac-card" id="pac-card">
                  <div>
                    <div id="title">Autocomplete search</div>
                    <div id="type-selector" class="pac-controls">
                      <input type="radio" checked="checked" name="type" id="changetype-address" />
                      <label for="changetype-address">address</label>
                    </div>
                    <br />
                    <div id="strict-bounds-selector" class="pac-controls">
                      <input type="checkbox" id="use-location-bias" value="" />
                      <label for="use-location-bias">Bias to map viewport</label>

                      <input type="checkbox" id="use-strict-bounds" value="" checked />
                      <label for="use-strict-bounds">Strict bounds</label>
                    </div>
                  </div>
                  <div id="pac-container">
                  </div>
                </div>
                <div id="map"></div>
                <div id="infowindow-content">
                  <span id="place-name" class="title"></span><br />
                  <span id="place-address"></span>
                </div>
              </div>

              

              <div class="rezultate_cautare">
                <ul>
                    <li v-for="(result, i) in searchResults" :key="i" v-on:click="selecteazaAdresa(result)">
                      {{ result }} 
                    </li>
                  </ul>
              </div>
              <div class="info_adresa" v-html="$t('info_adr')"></div>
              


              <!-- <div class="informatii_modal_2">
                <div class="info_modal_dreapta">
                    <div class="strada_nr">{{ prima_adresa_neschimbata }}</div>
                    <div class="oras_tara">Iasi, Romania</div>
                </div> 
                <i class="ri-arrow-right-s-line editeaza_adresa_livrare"></i> 

              </div> -->
              <button class="complete_adr complete_adr_activ">{{ $t('complete') }}</button>
              <div v-if="$i18n.locale == 'ro'" class="eroare">{{eroare_completare_adresa}}</div>
              <div v-else-if="$i18n.locale == 'en'" class="eroare">{{eroare_completare_adresa_en}}</div>
          </div>  
      </div>
      <div class="modal_4_checkout">
        <i class="ri-arrow-left-s-line inapoi_modal_checkout inapoi_modal_start_checkout"></i>
          <div class="titlu_modal_checkout">{{ $t('enter_your_phone_number') }}</div>
          <div class="sectiune_jos_modal">
            <VuePhoneNumberInput default-country-code="RO" v-if="$i18n.locale == 'ro'"  :translations="{countrySelectorLabel: 'Codul tarii',phoneNumberLabel: 'Numar de telefon',example: 'Exemplu :'}" v-model="telefon" class="telefon_checkout_modal_4" @update="updatePhoneNumber" />
             <VuePhoneNumberInput default-country-code="RO" v-else v-model="telefon" class="telefon_checkout_modal_4" @update="updatePhoneNumber" />
              <div class="complete_adr complete_tlf_activ">{{ $t('complete_phone') }}</div>
              <div v-if="$i18n.locale == 'ro'" class="eroare">{{eroare_telefon_ro}}</div>
              <div v-else-if="$i18n.locale == 'en'" class="eroare">{{eroare_telefon_en}}</div>
          </div>  
      </div>
      <div class="modal_4_checkout_verificare_telefon">
              <div @click="inapoi_modal_telefon_checkout_user" class="inapoi_modal_start_profil_user"><i class="ri-arrow-left-s-line"></i></div>
              <div class="titlu_recupereaza_parola color_verifica_telefon">{{ $t('introdu_codul')}}<span>{{ this.cod_tara_nefinalizat  }} {{this.telefon_comanda_nefinalizat}}</span></div>
              <div class="inputuri_cod_telefon inputuri_cod_telefon_checkout">
                  <input autocomplete="none" type="number" name="nr1_tel" class="nr1_tel nr_tel_general" value="" maxlength="1" />
                  <input autocomplete="none" type="number" name="nr2_tel" class="nr2_tel nr_tel_general" value="" maxlength="1"/>
                  <input autocomplete="none" type="number" name="nr3_tel" class="nr3_tel nr_tel_general" value="" maxlength="1"/>
                  <input autocomplete="none" type="number" name="nr4_tel" class="nr4_tel nr_tel_general" value=""  maxlength="1"/>
              </div>
              <div class="cod_neprimit">{{ $t('cod_neprimit')}}</div>
              <button class="next_modal_telefon actualizeaza_numar_checkout">{{ $t('next')}}</button>
              <div v-if="$i18n.locale == 'ro'" class="eroare">{{eroare_cod_telefon}}</div>
              <div v-else-if="$i18n.locale == 'en'" class="eroare">{{eroare_cod_telefon_en}}</div>
            
      </div>
      <div class="modal_5_checkout">
        <i class="ri-arrow-left-s-line inapoi_modal_checkout inapoi_modal_start_checkout"></i>
          <div class="titlu_modal_checkout">{{ $t('select_payment_method') }}</div>
          <div class="sectiune_jos_modal">
             <div value_plata="1" tip_plate="Plata la preluarea comenzii" tip_plate_en="Cash on delivery" class="box_general_profil box_medota_plata ">
               {{ $t('plata_la_preluarea_comenzii') }} 
            </div>
            <div value_plata="2" tip_plate="Plata cu cardul" tip_plate_en="Online credit card" class="box_general_profil box_medota_plata">
               {{ $t('plata_cu_cardul') }} <img style="width: 80px; margin-left: 6px;" src="@/assets/images/pay.png" alt=""/>
            </div>
          </div>  
      </div>
      <div class="modal_6_checkout">
          <img src="@/assets/images/comanda_trimisa.svg"/>
          <div class="titlu_modal_checkout tilu_margin_zero">{{ $t('order_sent') }}</div>
          <div v-if="durata_livrare != 0" class="mesaj_timp_livrare">{{ $t('mesaj_timp_livrare1') }} {{durata_livrare}} {{ $t('mesaj_timp_livrare2') }}</div>
          <div v-else class="mesaj_timp_livrare">{{ $t('mesaj_timp_livrare3') }}</div>
          <!-- <div class="buton_cos_general culoare_buton_rosie"> {{ $t('go_it') }} </div>
          <div class="buton_cos_general "> New in town? Get directions </div> -->
      </div>

  </sweet-modal>

  <sweet-modal  ref="modal_profil" class="modal_profilul_meu modal_profilul_meu_tel "  v-on:open="vezi_modal" v-on:close="close_html"> 
    <div class="profil_ascuns_mobile">
      <div class="element_mobile profil_mobile">
        <div class="logo_profil"><img src= '@/assets/images/logo-tiki-bistro.png' /></div>
        <div class="titlu_profil_mobile">{{ $t('my_profile') }}</div>
      </div>
        <div class="profilul_meu_content">
          <div class="titlu_profilul_meu">
            <div class="img_profilul_meu">
                <!-- <img src= '@/assets/images/profilul_meu.png' /> -->
                <i class="ri-user-3-fill"></i>
            </div>
            <div class="titlu_dreapta_profilul_meu">
              <div class="nume_titlu">{{nume_user}} {{prenume_user}}</div>
              <div class="locatie_titlu">Iasi, Romania</div>
          </div>
          </div>
          <div class="box_general_profil">
              <div class="icon_box_profil"><i class="ri-user-line"></i></div>
              <div class="label_form_profil"> 
                  <div class="label_profil">{{ $t('nume') }}</div>
                  <input type="text" name="nume_profil" class="input_profil nume_profil" :value=nume_user >
              </div>
              <div class="icon_edit_profil"><i class="ri-edit-line"></i></div>
          </div>
          <div class="box_general_profil">
              <div class="icon_box_profil"><i class="ri-user-line"></i></div>
              <div class="label_form_profil"> 
                  <div class="label_profil">{{ $t('prenume') }}</div>
                  <input type="text" name="prenume_profil" class="input_profil prenume_profil" :value=prenume_user>
              </div>
              <div class="icon_edit_profil"><i class="ri-edit-line"></i></div>
          </div>
          <div class="box_general_profil">
              <div class="icon_box_profil"><i class="ri-mail-line"></i></div>
              <div class="label_form_profil"> 
                  <div class="label_profil">Email</div>
                  <input readonly type="text" name="email_profil" class="input_profil email_profil" :value=email>
              </div>
              <div class="icon_edit_profil"><i class="ri-edit-line"></i></div>
          </div>
          <div class="box_general_profil" @click="deschide_modal_telefon">
              <div class="icon_box_profil"><i class="ri-smartphone-line"></i></div>
              <div class="label_form_profil"> 
                  <div class="label_profil">{{ $t('telefon') }}</div>
                  <input  type="text" name="telefon_profil" class="input_profil telefon_profil" :value="cod_tara+' '+telefon_comanda" onpaste="return false">
              </div>
              <div class="icon_edit_profil"><i class="ri-edit-line"></i></div>
          </div>
          <button class="save_profil">{{ $t('salveaza') }}</button>
          <div class="eroare" v-if="showError_editare" id="error">{{ $t('eroare_editare') }}</div>
        </div>
    </div>
        <div class="modal_telefon_general">
          <div class="modal_2_content">
              <div class="element_mobile"><img src= '@/assets/images/logo-tiki-bistro.png' /></div>
              <div @click="inapoi_modal_start_profil_user" class="inapoi_modal_start_profil_user"><i class="ri-arrow-left-s-line"></i></div>
              <div class="titlu_recupereaza_parola line_height_telefon">{{ $t('introdu_telefonul')}}</div>
              <VuePhoneNumberInput default-country-code="RO" v-if="$i18n.locale == 'ro'"  :translations="{countrySelectorLabel: 'Codul tarii',phoneNumberLabel: 'Numar de telefon',example: 'Exemplu :'}" v-model="telefon" class="telefon_inregistrare_profil" @update="updatePhoneNumber" />
              <VuePhoneNumberInput default-country-code="RO" v-else  v-model="telefon" class="telefon_inregistrare_profil" @update="updatePhoneNumber" />
              <button class="next_modal_telefon mergi_la_verificare_cod_profil">{{ $t('next')}}</button>
              <div v-if="$i18n.locale == 'ro'" class="eroare">{{eroare_telefon_ro}}</div>
              <div v-else-if="$i18n.locale == 'en'" class="eroare">{{eroare_telefon_en}}</div>
            </div>
        </div>
        <div class="modal_verificare_telefon">
          <div class="modal_2_content">
              <div class="element_mobile"><img src= '@/assets/images/logo-tiki-bistro.png' /></div>
              <div @click="inapoi_modal_telefon_profil_user" class="inapoi_modal_start_profil_user"><i class="ri-arrow-left-s-line"></i></div>
              <div class="titlu_recupereaza_parola color_verifica_telefon">{{ $t('introdu_codul')}}<span>{{this.cod_tara_nefinalizat}} {{this.telefon_comanda_nefinalizat}}</span></div>
              <div class="inputuri_cod_telefon inputuri_cod_telefon_profil">
                  <input autocomplete="none" type="number" name="nr1_tel" class="nr1_tel nr_tel_general" value="" maxlength="1" />
                  <input autocomplete="none" type="number" name="nr2_tel" class="nr2_tel nr_tel_general" value="" maxlength="1"/>
                  <input autocomplete="none" type="number" name="nr3_tel" class="nr3_tel nr_tel_general" value="" maxlength="1"/>
                  <input autocomplete="none" type="number" name="nr4_tel" class="nr4_tel nr_tel_general" value=""  maxlength="1"/>
              </div>
              <div class="cod_neprimit">{{ $t('cod_neprimit')}}</div>
              <button class="next_modal_telefon actualizeaza_numar_profil">{{ $t('validare_telefon')}}</button>
              <div v-if="$i18n.locale == 'ro'" class="eroare">{{eroare_cod_telefon}}</div>
              <div v-else-if="$i18n.locale == 'en'" class="eroare">{{eroare_cod_telefon_en}}</div>
            </div>
          </div>
    </sweet-modal>
    <sweet-modal  ref="modal_profil_neautentificat" class="modal_profilul_meu_neautentificat"  v-on:open="vezi_modal" v-on:close="close_html"> 
        <div class="modal_start">
        <div class="img_modal_sus"><img src= '@/assets/images/logo-vertical.png' /></div>
        <div class="modal_content_autentificare">
            <div class="element_mobile logo_profil_neautentificat"><img src= '@/assets/images/logo-tiki-bistro.png' /></div>
            <div class="title_modal_autentificare">{{ $t('sign_up') }}</div>
            <div class="butoane_autentificare_modal">
              <div class="btn_autentif_modal autentif_facebook"  @click.prevent="loginWithFacebook"><img src= '@/assets/images/facebook_img.png' />{{ $t('continua_facebook') }}</div>
              <div class="despartitor_modal">{{ $t('or') }}</div>
              <div class="btn_autentif_modal log_in_cont">{{ $t('login') }}</div>
              <div class="cont_existent">
                  <div class="intrebare_cont_existent">{{ $t('ai_cont_deja') }}</div>
                  <div class="autentif_email">{{ $t('sign_upp') }}</div>
              </div>
            </div>
            <div class="termeni_conditii_acceptate">{{ $t('inscriere_acceptati') }} <router-link class="termeni_conditii_acceptate inchide_popup" to="/termeni_si_conditii"><span>{{ $t('term_cond') }}</span></router-link> {{ $t('si_datele') }} <router-link class="termeni_conditii_acceptate inchide_popup" to="/politica_de_confidentialitate"><span>{{ $t('priv_poli') }}</span></router-link>.</div>
        </div>
        </div>
        <div class="modal2">
            <div class="modal_2_content">
              <div class="element_mobile"><img src= '@/assets/images/logo-tiki-bistro.png' /></div>
              <div class="inapoi_modal_start"><i class="ri-arrow-left-s-line"></i></div>
              <div class="titlu_modal2">{{ $t('create_account') }}</div>
              <form @submit.prevent="submit_register">
                <div class="modal2_input">
                    <div class="input_form_modal2">
                      <div for="first_name" class="label_modal2">{{ $t('nume') }}</div>
                      <input type="text" name="first_name" value="" class="input_form first_name" v-model="form_register.first_name">
                    </div>
                    <div class="input_form_modal2">
                      <div for="last_name" class="label_modal2">{{ $t('prenume') }}</div>
                      <input type="text" name="last_name"  value="" class="input_form last_name" v-model="form_register.last_name">
                    </div>
                    <div class="input_form_modal2">
                      <div for="email" class="label_modal2">E-Mail</div>
                      <input type="text" name="email" v-model="form_register.email" value="" class="input_form email">
                    </div>
                    <div class="input_form_modal2">
                      <div for="password" class="label_modal2">{{ $t('password') }}</div>
                      <div class="position_relative">
                        <input type="password" name="input_parola" v-model="form_register.password" value="" class="input_form password input_parola">
                        <div class="ascunde_vezi_parola parola_eye vezi_parola"><i class="ri-eye-line"></i></div>
                        <div class="ascunde_vezi_parola parola_eye ascunde_parola"><i class="ri-eye-off-line"></i></div>
                      </div>
                    </div>  
                
                </div>
                <button type="submit" class="sign_up_cont">{{ $t('sign_up') }}</button>
              </form>
              <p v-if="showError" id="error" class="eroare">{{ $t('nume_existent') }} </p>
            </div>
        </div>
        <div class="modal3">
            <div class="modal_2_content">
              <div class="element_mobile"><img src= '@/assets/images/logo-tiki-bistro.png' /></div>
              <div class="inapoi_modal_start"><i class="ri-arrow-left-s-line"></i></div>
              <div class="titlu_modal2">{{ $t('welcome_back') }}</div>
              <form @submit.prevent="submit">
                  <div class="modal2_input">
                    <div class="input_form_modal2">
                      <div for="email" class="label_modal2">E-Mail</div>
                      <input type="text" name="email" v-model="form.email" value="" class="input_form email_cont_login">
                    </div>
                    <div class="input_form_modal2">
                      <div for="password" class="label_modal2">{{ $t('password') }}</div>
                      <div class="position_relative">
                        <input type="password" name="input_parola" v-model="form.password" value="" class="input_form parola_cont_login">
                        <div class="ascunde_vezi_parola2 parola_eye vezi_parola2"><i class="ri-eye-line"></i></div>
                        <div class="ascunde_vezi_parola2 parola_eye ascunde_parola2"><i class="ri-eye-off-line"></i></div>
                      </div>
                    </div>
                  </div>
                  <button type="submit" class="sign_up_cont">{{ $t('login') }}</button>
              </form>
               <div class="eroare" v-if="showError" id="error">{{ $t('eroare_login') }}</div>
              <div class="recuperare_parola">{{ $t('forgot_pass') }}</div>

            </div>
        </div>
         <div class="modal4">
            <div class="modal_2_content">
              <div class="element_mobile"><img src= '@/assets/images/logo-tiki-bistro.png' /></div>
              <div class="inapoi_modal_3"><i class="ri-arrow-left-s-line"></i></div>
              <div class="titlu_recupereaza_parola">{{ $t('forgot_pass') }}</div>
              <div class="descriere_parola_uitata">{{ $t('forgot_pass_text') }}</div>
              <div class="modal2_input">
                <div class="input_form_modal2">
                  <div class="label_modal2">E-Mail</div>
                  <input type="text" name="email_parola_uitata" value="" class="input_form email_parola_uitata" :placeholder="$t('enter_email_addr') ">
                </div>
              </div>
              <div class="recupereaza_parola activ_button" @click="recupereazaParola">{{ $t('recover_pass') }}</div>
              <div v-if="eroare_recuperare_parola != ''" class="eroare">{{eroare_recuperare_parola}}</div>

            </div>
        </div>
         <div class="modal5">
            <div class="modal_2_content">
              <div class="element_mobile"><img src= '@/assets/images/logo-tiki-bistro.png' /></div>
              <div class="inapoi_modal_start"><i class="ri-close-fill"></i></div>
              <div class="go_email_img"><img src= '@/assets/images/go_email.png' /></div>
              <div class="titlu_recupereaza_parola">{{ $t('check_email') }}</div>
              <div class="descriere_parola_uitata">{{ $t('check_email_text') }}</div>
              <!-- <div class="go_email_button">{{ $t('go_to_email') }}</div> -->
            </div>
        </div>
        <div class="modal6">
            <div class="modal_2_content">
              <div class="element_mobile"><img src= '@/assets/images/logo-tiki-bistro.png' /></div>
              <div class="inapoi_modal_start"><i class="ri-close-fill"></i></div>
              <div class="titlu_recupereaza_parola line_height_telefon">{{ $t('introdu_telefonul')}}</div>
              <VuePhoneNumberInput default-country-code="RO" v-if="$i18n.locale == 'ro'"  :translations="{countrySelectorLabel: 'Codul tarii',phoneNumberLabel: 'Numar de telefon',example: 'Exemplu :'}" v-model="telefon" class="telefon_inregistrare" @update="updatePhoneNumber" />
              <VuePhoneNumberInput default-country-code="RO" v-else  v-model="telefon" class="telefon_inregistrare" @update="updatePhoneNumber" />
              <button class="next_modal_telefon mergi_la_verificare_cod">{{ $t('next')}}</button>
              <div v-if="$i18n.locale == 'ro'" class="eroare">{{eroare_telefon_ro}}</div>
              <div v-else-if="$i18n.locale == 'en'" class="eroare">{{eroare_telefon_en}}</div>
            </div>
        </div>
        <!-- <div class="modal7">
            <div class="modal_2_content">
              <div class="element_mobile"><img src= '@/assets/images/logo-tiki-bistro.png' /></div>
              <div class="inapoi_modal_start"><i class="ri-close-fill"></i></div>
              <div class="titlu_recupereaza_parola color_verifica_telefon">{{ $t('introdu_codul')}}<span>{{this.phone}}</span></div>
              <div class="inputuri_cod_telefon">
                  <input autocomplete="none" type="number" name="nr1_tel" class="nr1_tel nr_tel_general" value="" maxlength="1" />
                  <input autocomplete="none" type="number" name="nr2_tel" class="nr2_tel nr_tel_general" value="" maxlength="1"/>
                  <input autocomplete="none" type="number" name="nr3_tel" class="nr3_tel nr_tel_general" value="" maxlength="1"/>
                  <input autocomplete="none" type="number" name="nr4_tel" class="nr4_tel nr_tel_general" value=""  maxlength="1"/>
              </div>
              <div class="cod_neprimit">{{ $t('cod_neprimit')}}</div>
              <button class="next_modal_telefon mergi_la_inregistrare">{{ $t('next')}}</button>
              <div v-if="$i18n.locale == 'ro'" class="eroare">{{eroare_cod_telefon}}</div>
              <div v-else-if="$i18n.locale == 'en'" class="eroare">{{eroare_cod_telefon_en}}</div>
            </div>
        </div> -->
    </sweet-modal>

    <!-- MODAL VERSIUNE BETA--> 
    <!-- <sweet-modal ref="modal_versiune_beta" class="modal_versiune_beta" blocking v-on:open="vezi_modal" v-on:close="close_html">  
        <div class="profilul_meu_content div_beta_content">
          <div class="div_beta">
              <img src= '@/assets/images/logo-tiki-bistro.png' />  
              Aceasta este o versiune BETA a aplicației. Dacă întâmpinați anumite erori am fi recunoscători să ne trimiteți un email pe adresa suport@tikibistro.ro sau contactați-ne la +40 (747) 247 876!
          </div>
          <div class="btn_general_red" @click="descopera_app">
            Descoperă aplicația
          </div>
        </div>
    </sweet-modal> -->
    <!-- MODAL VERSIUNE BETA--> 

     <!-- MODAL VERSIUNE BETA--> 
    <sweet-modal  ref="modal_bucatarie_inchisa" class="modal_bucatarie_inchisa" blocking v-on:open="vezi_modal" v-on:close="close_html"> 
       <div class="profilul_meu_content" >
       <div  class="mesaj_bucatarie_inchisa">
          <div v-if="$i18n.locale == 'ro'">{{msg_status_Closed_ro}}</div>
          <div v-else-if="$i18n.locale == 'en'">{{msg_status_Closed_en}}</div>
        </div>
      </div>
    </sweet-modal>
    <!-- MODAL VERSIUNE BETA--> 
  
    <sweet-modal  ref="modal_addresses" class="modal_comenzi modal_adrese"  v-on:open="vezi_modal" v-on:close="close_html">   
      <div class="modal_adrese_start">  
        <div class="orders_title_content">
            <div class="element_mobile"><img src= '@/assets/images/logo-tiki-bistro.png' /></div>
            <div class="orders_title">{{ $t('my_addresses') }}</div>
            <div v-if="adrese_user.length == 0" class="orders_nr">{{ $t('adrese_neadaugate') }}</div>
            <div v-if="adrese_user.length > 0" class="orders_nr">{{adrese_user.length }} {{ $t('adrese') }}</div>
        </div>
        <div class="orders_list ">
            <div v-for="adresa in adrese_user" :key="adresa.id"  class="box_general_profil vezi_adresa" :address_id="adresa.address_id">
              <div class="label_form_profil">
                <div class="adresa_user">{{adresa.address}}</div>
                <div class="adresa_descriere">{{adresa.description}}</div>
              </div>
              <div class="icon_edit_profil">
                <i class="ri-arrow-right-s-line "></i>
              </div>
            </div>
          <div class="adauga_adresa_noua">{{ $t('adauga_adresa_noua') }}</div>
        </div>
        
      </div>
      <div class="modal_adrese_adauga">
          <i class="ri-arrow-left-s-line inapoi_modal_checkout inapoi_modal_start_adrese"></i>
          <div class="orders_title_content">
            <div class="element_mobile"><img src= '@/assets/images/logo-tiki-bistro.png' /></div>
            <div class="orders_title">{{ $t('adauga_adresa_noua') }}</div>
        </div>
        <div class="container_background">
          <div class="position_relative">
            <!-- <input name="adresa_noua" placeholder="Enter address" value="" class="input_activ_adauga adresa_noua" autocomplete="none"> --> 
            <input id="pac-input-adresa-noua" name="locatie_checkout" class="locatie_checkout" type="text" :placeholder="$t('introduceti_adresa_noua')" autocomplete="none" />
            <i class="ri-send-plane-line icon_locatie_checkout"></i>
          </div>
          <div class="position_relative margin_top_20">
            <input type="text" name="descriere_adresa_noua" :placeholder="$t('detalii_adresa')" value="" class="input_activ_adauga descriere_adresa_noua" autocomplete="none">
            <i class="ri-building-4-line icon_locatie_checkout"></i>
          </div>
          <button class="save_address buton_dezactivat">{{ $t('save_address') }}</button>
          <div v-if="eroare_adauga_adresa_noua !='' " class="eroare">{{ eroare_adauga_adresa_noua }}</div>

          <div class="mapssssss">
            <div class="pac-card" id="pac-card-adresa-noua">
              <div>
                <div id="title">Autocomplete search</div>
                <div id="type-selector" class="pac-controls">
                  <input type="radio" checked="checked" name="type" id="changetype-address" />
                  <label for="changetype-address">address</label>
                </div>
                <br />
                <div id="strict-bounds-selector" class="pac-controls">
                  <input type="checkbox" id="use-location-bias-adresa-noua" value="" />
                  <label for="use-location-bias-adresa-noua">Bias to map viewport</label>

                  <input type="checkbox" id="use-strict-bounds-adresa-noua" value="" checked />
                  <label for="use-strict-bounds-adresa-noua">Strict bounds</label>
                </div>
              </div>
              <div id="pac-container">
              </div>
            </div>
            <div id="map-adresa-noua"></div>
            <div id="infowindow-content-adresa-noua">
              <span id="place-name" class="title"></span><br />
              <span id="place-address"></span>
            </div>
          </div>
        </div>

        
      </div>
      <div class="modal_adrese_editeaza">
          <i class="ri-arrow-left-s-line inapoi_modal_checkout inapoi_modal_start_adrese"></i>
          <div class="orders_title_content">
            <div class="element_mobile"><img src= '@/assets/images/logo-tiki-bistro.png' /></div>
            <div class="orders_title">{{ $t('edit_address') }}</div>
        </div>
        <div class="container_background">
          <div class="position_relative">
            <!-- <input name="adresa_editata" placeholder="Enter address" :value="adresa_edit" class="input_activ_edit adresa_editata" autocomplete="none"> -->

            <input id="pac-input-adresa-editata" name="adresa_editata" :value="adresa_edit" class="input_activ_edit adresa_editata" type="text" :placeholder="$t('introduceti_adresa_editata')" autocomplete="none"/>
            <i class="ri-send-plane-line icon_locatie_checkout"></i>
          </div>
          <div class="position_relative margin_top_20">
            <input name="descriere_adresa_editata" autocomplete="none" placeholder="Building, floor, door, notes" :value="descriere_adresa_edit" class="input_activ_edit descriere_adresa_noua descriere_adresa_editata">
            <i class="ri-building-4-line icon_locatie_checkout"></i>
          </div>
          <div v-if="id_adresa_edit != 0" class="edit_address buton_dezactivat" :id_adresa="id_adresa_edit">{{ $t('edit_address') }}</div>
          <div v-if="id_adresa_edit != 0" class="delete_address" :id_adresa="id_adresa_edit">{{ $t('delete_address') }}</div>
          <div v-if="id_adresa_edit == 0" class="adresa_default" >{{ $t('adresa_default') }}</div>
          <div v-if="eroare_adauga_adresa_editata !='' " class="eroare">{{ eroare_adauga_adresa_editata }}</div>

          <div class="mapssssss">
            <div class="pac-card" id="pac-card-adresa-editata">
              <div>
                <div id="title">Autocomplete search</div>
                <div id="type-selector" class="pac-controls">
                  <input type="radio" checked="checked" name="type" id="changetype-address" />
                  <label for="changetype-address">address</label>
                </div>
                <br />
                <div id="strict-bounds-selector" class="pac-controls">
                  <input type="checkbox" id="use-location-bias-adresa-editata" value="" />
                  <label for="use-location-bias-adresa-editata">Bias to map viewport</label>

                  <input type="checkbox" id="use-strict-bounds-adresa-editata" value="" checked />
                  <label for="use-strict-bounds-adresa-editata">Strict bounds</label>
                </div>
              </div>
              <div id="pac-container">
              </div>
            </div>
            <div id="map-adresa-editata"></div>
            <div id="infowindow-content-adresa-editata">
              <span id="place-name" class="title"></span><br />
              <span id="place-address"></span>
            </div>
          </div>
        </div>
      </div>
       

    </sweet-modal>
    <sweet-modal  ref="modal_orders" class="modal_comenzi"  v-on:open="vezi_modal" v-on:close="close_html">   
      <div class="order_modal_start">  
        <div class="orders_title_content">
            <div class="element_mobile"><img src= '@/assets/images/logo-tiki-bistro.png' /></div>
            <div v-if="comenzi.length != 0 && final_axios == 1" class="orders_title">{{ $t('my_orders') }}</div>
            <div v-if="comenzi.length != 0 && final_axios == 1" class="orders_nr">{{ comenzi.length}} {{ $t('orders') }}</div>
            
        </div>
        <div v-if="comenzi.length == 0 && final_axios == 1" class="no_orders">
                <img src='@/assets/images/no_orders.svg'/>
                <div class="no_title">{{ $t('no_orders') }}</div>
                <div class="no_descriere">{{ $t('no_orders_desc') }}</div>
            </div>
        <div v-else-if="comenzi.length > 0 && final_axios == 1" class="scroll_modal">
            <div class="orders_list ">
                <div v-for="comanda in comenzi" :key="comanda.id" class="orders_box vezi_comanda"   :id_comanda="comanda.order_id">
                  <div  class="orders_icon ">
                      <i v-if=" comanda.status == '2'" class="ri-time-line status_nepreluat"></i>
                      <i  v-else-if="  comanda.status == '3'" class="ri-knife-line status_gatire"></i>
                      <i v-else-if="  comanda.status == '4'" class="ri-motorbike-line status_asteptare"></i>
                      <i v-else-if=" comanda.status == '0' " class="ri-close-line status_anulat"></i>
                      <i v-else-if=" comanda.status == '1' " class="ri-check-line status_complet"></i>
                      
                  </div>
                  <div class="orders_box_right">
                    <div class="orders_right_top">
                        <div class="orders_id">
                          {{ $t('order') }} #{{comanda.uniqueid}}
                        </div>
                        <div class="orders_pret">{{comanda.total_price + comanda.delivery_price | tofixed}} Lei</div>  
                    </div>
                   
                    <div class="orders_right_bottom">
                        <div v-if="comanda.status == '2'" class="orders_status status_nepreluat">{{ $t('comanda_nepreluata') }}</div>
                        <div v-else-if="comanda.status == '3'" class="orders_status status_gatire">{{ $t('la_bucatarie') }}</div>
                        <div v-else-if="comanda.status == '4'" class="orders_status status_asteptare">{{ $t('pe_drum') }}</div>
                        <div v-else-if="comanda.status == '0' " class="orders_status status_anulat">{{ $t('canceled') }}</div>
                        <div v-else-if="comanda.status == '1'" class="orders_status status_complet">{{ $t('completed') }}</div>
                        <div class="orders_date">{{ comanda.created_at | formatDate }}</div>
                        
                    </div>
                  </div>
                  <div class="orders_icon_right"><i class="ri-arrow-right-s-line"></i></div>
                </div>

            </div>  
        </div>
       
      </div>
      <div class="order_modal_2">
         <i class="ri-arrow-left-s-line inapoi_modal_comanda_start"></i>
         <div class="orders_title_content">
            <div class="element_mobile"><img src= '@/assets/images/logo-tiki-bistro.png' /></div>
            <div class="orders_title">{{ $t('order') }} #{{ detalii_comanda.uniqueid }}</div>
            <div class="status_data">
              <div v-if="detalii_comanda.status == '2'" class="orders_status status_nepreluat">{{ $t('comanda_nepreluata') }}</div>
              <div v-else-if="detalii_comanda.status == '3'" class="orders_status status_gatire">{{ $t('la_bucatarie') }}</div>
              <div v-else-if="detalii_comanda.status == '4'" class="orders_status status_asteptare">{{ $t('pe_drum') }}</div>
              <div v-else-if="detalii_comanda.status == '0' " class="orders_status status_anulat">{{ $t('canceled') }} </div>
              <div v-else-if="detalii_comanda.status == '1'" class="orders_status status_complet">{{ $t('completed') }} </div>
               {{$t('on')}} {{detalii_comanda.created_at | formatDate}}</div>
        </div>
        <div class="produse_comanda_container">
            <div class="boxuri_produse_comanda">
                <div class="box_produse_comanda" v-for="detalii_comanda_produse in this.detalii_comanda.products" :key="detalii_comanda_produse.id">   
                  <img class="imagine_produs_comanda_2" :src=verificaPozaProdus(detalii_comanda_produse.product_id) />
                  <div class="nume_pret_produs_comanda">
                      <div class="nume_produs_comanda">{{ detalii_comanda_produse.additional.name }}</div>
                      <div class="atribute_suplimentare">
                            <span class="box_atribut_detaliu" v-for="atribut_produs_comanda in detalii_comanda_produse.attributes" :key="atribut_produs_comanda.id">
                                  {{atribut_produs_comanda.name_ro}}
                              </span>
                      </div>
                      <div class="pret_produs_comanda">
                        <div class="cantitate_pret_bucata">{{ detalii_comanda_produse.quantity }} x {{detalii_comanda_produse.product_price}} LEI</div>
                        <div class="pret_total_produs_comanda">{{ parseFloat(detalii_comanda_produse.quantity * detalii_comanda_produse.product_price).toFixed(2)}} RON</div>
                      </div>
                  </div>
                </div>
            </div>
            <div class="subtotal_delivery_total_container">
                <div class="subtotal_delivery_total">
                    <div class="item1_subtotal_delivery_total">Sub total</div>
                    <div class="item2_subtotal_delivery_total">{{ detalii_comanda.total_price }} Lei</div>
                </div>
                <div class="subtotal_delivery_total">
                    <div class="item1_subtotal_delivery_total">Delivery</div>
                    <div class="item2_subtotal_delivery_total">{{ detalii_comanda.delivery_price }} Lei</div>
                </div>
                <div class="subtotal_delivery_total">
                    <div class="item1_subtotal_delivery_total culoare_orange">Total</div>
                    <div class="item2_subtotal_delivery_total culoare_orange">{{ detalii_comanda.total_price + detalii_comanda.delivery_price | tofixed }} Lei</div>
                </div>
            </div>

            <div class="btn_general_red comanda_recurenta" @click="comanda_recurenta()">Comanda din nou</div>

            <div v-if="detalii_comanda.note != ''" class="note_comanda_div">"{{ detalii_comanda.note }}"</div>

            <div v-if="detalii_comanda.payment_method == 'cash'" class="comanda_cash_div" v-html="$t('comanda_cash')"></div>
            <div v-else class="comanda_card_div" v-html="$t('comanda_card')"></div>

        </div>
      </div>
    </sweet-modal>
    <!-- <sweet-modal  ref="modal_cards" class="modal_cards modal_favorite"  v-on:open="vezi_modal" v-on:close="close_html">
        <div class="element_mobile padding_mobile"><img src= '@/assets/images/logo-tiki-bistro.png' /></div>    
        <div class="titlu_modal_favorite">{{ $t('my_cards') }}</div>
        <div class="produse_favorite carduri_container">
          <div class="card_box">
              <div class="imagine_detalii">
                <div class="img_card"><img src= '@/assets/images/card_img.png' /></div> 
                <div class="detalii_card">
                    <div class="nr_card">
                      •••• •••• •••• 4321
                    </div>
                    <div class="titlu_card">Mastercard - 04/28</div>
                </div>
              </div>
          <div class="editeaza_card"><i class="ri-arrow-right-s-line"></i></div>
        </div>
        <div class="adauga_card">{{ $t('add_new_card') }}</div>
        </div>
    </sweet-modal> -->

    

    <sweet-modal  ref="modal_favorite" class="modal_favorite"  v-on:open="vezi_modal" v-on:close="close_html"> 
      <div class="element_mobile padding_mobile"><img src= '@/assets/images/logo-tiki-bistro.png' /></div>    
       <div v-if="this.produse_favorite_state_full.length > 0" class="titlu_modal_favorite">{{ $t('my_favorites') }}</div>
       <div class="produse_favorite scroll_modal"  v-if="this.produse_favorite_state_full.length > 0 && this.end_call == true">
         


         <div class="produs_favorit" v-for="(produs_favorit,index) in this.produse_favorite_state_full" :key="produs_favorit.id">
           <div class="imagine_produs_fav"  v-for="imagine in produs_favorit.main_image" :key="imagine.resizedpath">
              <img :src="imagine.resizedpath"/>
           </div>
           <div class="produs_favorit_dreapta">
             <div class="nume_pret_produs_fav">
                <div class="nume_favorit"  v-if="$i18n.locale == 'ro'">{{ produs_favorit.additional.name_ro }}</div>
                <div class="nume_favorit"  v-else>{{ produs_favorit.additional.name_en }}</div>
                <div class="sterge_produs_favorite"  @click="deleteItemFavorit(index, produs_favorit.id)"><i class="ri-delete-bin-6-line"></i></div>
                
             </div>
             <div class="descriere_plus_fav">
               <div class="descriere_fav">{{ produs_favorit.additional.description | strippedContent}}</div>
               <div class="plus_favorit" @click="afisare_modal_produs(produs_favorit.id, $event)" ><i class="ri-add-line"></i></div>
               
             </div>
             <div class="pret_plus_favorite">
                <div class="pret_favorit">{{ produs_favorit.price}} Lei</div>
                
             </div>
           </div>
         </div>
       </div>
       <div  v-if="this.produse_favorite_state_full.length == 0 && this.end_call == true" class="produse_favorite_zero">
          <img src="@/assets/images/no_favorite.svg" >
          <div class="titlu_produse_favorite_zero">{{ $t('no_favorites') }}</div>
          <div class="descriere_produse_favorite_zero">{{ $t('fara_produs_favorit') }}</div>
       </div>
       

    </sweet-modal>
     <sweet-modal  ref="modal_setari" class="modal_settings"  v-on:open="vezi_modal" v-on:close="close_html">
       <div class="titlu_modal_setari"> 
        <div class="element_mobile"><img src= '@/assets/images/logo-tiki-bistro.png' /></div>
        <div class="titlu_setari">{{ $t('settings') }}</div>
      </div>
      <div class="content_setari_modal">
        <div class="box_general_setari_limba">
          <div class="label_form_profil">
            <div class="label_profil">{{ $t('language') }}</div>
            <div v-if="$i18n.locale == 'ro'" class="input_profil limba_setari">Romana</div>
            <div v-else-if="$i18n.locale == 'en'" class="input_profil limba_setari">English</div>
          </div>
          <div class="setari_limba_box">
              <div class="setari_schimba" v-for="entry in languages" :key="entry.title" @click="changeLocale(entry.language)">
                  <img :src= 'entry.flag' /> {{entry.title}}
              </div>
          </div>    
          <div class="icon_sageata_dreapta"><i class="ri-arrow-down-s-line jos"></i></div>
          <div class="icon_sageata_dreapta"><i class="ri-arrow-up-s-line sus"></i></div>
        </div>
        <div class="box_general_profil schimba_adresa_setari">
          <div class="adresa_completa_modal_start" v-if="adresa_selectata.prima_adresa !='' && adresa_selectata.adresa_2 !='' ">
            <div class="adresa_user">{{ adresa_selectata.prima_adresa }}</div>
            <div class="adresa_descriere">{{ adresa_selectata.adresa_2 }}</div>
          </div>
          <div v-else class="label_form_profil">
            <div class="label_profil">{{ $t('location') }}</div>
            <input type="text" name="locatie_setari" value="Enabled" class="input_profil locatie_setari" autocomplete="none">
          </div>
          <div class="icon_sageata_dreapta"><i class="ri-arrow-right-s-line"></i></div>
        </div>
        <!-- change password -->
        <div class="box_general_profil" style="cursor: pointer;" v-on:click="$refs.modal_resetare_parola.open()">
          <div class="label_form_profil">
            <div class="label_profil">{{ $t('change_pass_text') }}</div>
            <div class="input_profil">{{ $t('change_pass_text2') }}</div>  
          </div>  
         <div class="icon_sageata_dreapta"><i class="ri-arrow-right-s-line"></i></div>
        </div>
        <!-- change password -->
        <div class="data_acces_box display_none_box">
          <div class="img_data">
            <img src="@/assets/images/data_access.png">
          </div>
          <div class="data_text">
            <div class="data_titlu">{{ $t('data_access') }}</div>
            <div class="data_desc">{{ $t('data_access_text') }}</div>
          </div>
        </div>
        <div class="box_general_profil display_none_box">
          <div class="label_form_profil">
            <div class="label_profil">{{ $t('location_access') }}</div>
            <input type="text" name="locatie_acces_setari" value="Enabled" class="input_profil locatie_acces_setari">
          </div>
          <div class="switch_locatie">
            <label class="switch">
              <input type="checkbox">
              <span class="slider round"></span>
            </label>
          </div>
        </div>
        <div class="box_general_profil display_none_box">
          <div class="label_form_profil">
            <div class="label_profil">{{ $t('recive_notif') }}</div>
            <input type="text" name="notificari_setari" value="Enabled" class="input_profil notificari_setari">
          </div>
          <div class="switch_locatie">
            <label class="switch">
              <input type="checkbox">
              <span class="slider round"></span>
            </label>
          </div>
        </div>
        <div class="box_general_profil display_none_box">
          <div class="label_form_profil">
            <div class="label_profil">{{ $t('recive_newsletters') }}</div>
            <input type="text" name="newsletters_setari" value="Enabled" class="input_profil newsletters_setari">
          </div>
          <div class="switch_locatie">
            <label class="switch">
              <input type="checkbox">
              <span class="slider round"></span>
            </label>
          </div>
        </div>
        <div class="box_general_profil display_none_box">
          <div class="label_form_profil">
            <div class="label_profil">{{ $t('recive_special_offers') }}</div>
            <input type="text" name="special_setari" value="Enabled" class="input_profil special_setari">
          </div>
          <div class="switch_locatie">
            <label class="switch">
              <input type="checkbox">
              <span class="slider round"></span>
            </label>
          </div>
        </div>
        </div>
        <div class="modal_locatii_setari">

            <i class="ri-arrow-left-s-line inapoi_modal_checkout inapoi_modal_start_setari"></i>
            <div class="selecteaza_adresa">{{ $t('selecteaza_adresa') }}</div>
            <div class="sectiune_jos_modal">
              <div class="orders_list ">
                <div v-for="adresa in adrese_user" :key="adresa.id"  class="box_general_profil adresa_pt_livrare_setari" :address_lat="adresa.address_lat" :address_lng="adresa.address_lng" :address_id="adresa.address_id" :address_pret="adresa.price" :address_minute="adresa.delivery_time">
                      <div class="label_form_profil">
                        <div :adresa_user_livrare="adresa.address" class="adresa_user adresa_user_livrare">{{adresa.address}}</div>
                        <div :adresa_descriere_livrare="adresa.description" class="adresa_descriere adresa_descriere_livrare">{{adresa.description}}</div>
                      </div>
                    <div class="icon_edit_profil">
                      <i class="ri-arrow-right-s-line "></i>
                    </div>
                </div>
              </div>
            </div>
        </div>
    </sweet-modal>
    <sweet-modal class="modal_produs_style" ref="modal_produs" v-on:close="close" v-on:open="vezi_modal">
        <div class="imagine_produs padding_zero_img">
          <div v-if="array_imagini_produs.length > 1" class="slider_img">
            <VueSlickCarousel v-bind="settings_imagine_produs"  ref="imagine_produs_modal" v-if="array_imagini_produs.length">
                <img v-for="imag_prod in array_imagini_produs" :key="imag_prod.id" :src= imag_prod.resizedpath />
            </VueSlickCarousel>
          </div>
          <img v-else :src="imagine_produs"/>
          <div class="pret_produs_modal">
            <div class="box_pret_container" v-if="discount_procent > 0">
              <div class="pret_produs_modal_taiat">{{ pret }} lei</div>
              <div class="ppp" >{{ discount_price_final }} lei</div>
            </div>
            <div class="box_pret_container" v-else>
              <div class="ppp" >{{ pret}} lei</div>
            </div>

            </div>
          <i class="ri-heart-fill heart_absolute favorit element_mobile" :datastatus="0" :dataid="id_produs"   v-if="filter(id_produs)"></i>
          <i v-else class="ri-heart-line heart_absolute nefavorit element_mobile" :datastatus="1" :dataid="id_produs"></i>
          <div class="reducere_icon" v-if="discount_procent > 0">
              -{{discount_procent}}%
          </div>
        </div>
        <div class="continut_modal">
          <div class="box_pret_container" v-if="discount_procent > 0">
              <div class="pret_produs_modal_taiat">{{ pret }} lei</div>
          </div>
          <div class="nume_produs_modal"  v-if="$i18n.locale == 'ro'">{{nume_produs}}</div>
          <div class="nume_produs_modal"  v-else-if="$i18n.locale == 'en'">{{nume_produs_en}}</div>
          
          <div class="reducere_icon" v-if="discount_procent > 0">
              -{{discount_procent}}%
          </div>
          <div class="descriere_produs_modal"  v-if="$i18n.locale == 'ro'" v-html="descriere_produs"></div>
          <div class="descriere_produs_modal"  v-else-if="$i18n.locale == 'en'" v-html="descriere_produs_en"></div>
          <div v-if="alergeni_ro !='' && $i18n.locale == 'ro'" class="alergeni">
            <div class="titlu_alergeni">Alergeni:</div>
            <div class="cuprins_alergeni">{{alergeni_ro}}</div>
          </div>
          <div v-else-if="alergeni_en !='' && $i18n.locale == 'en'" class="alergeni">
            <div class="titlu_alergeni">Allergens:</div>
            <div class="cuprins_alergeni">{{alergeni_en}}</div>
          </div>
          <div v-else class="alergeni">
          </div>

          <div class="extra_modal" v-if="optiuni_extra.length > 1">
            <div class="titlu_extra_modal">{{ $t('alege_optiuni') }}</div>
            <div class="container_checkbox">
                <label v-for="(iii,index) in optiuni_extra" :key="iii.external_id" :index="index" class="container checkbox_flex opt_extra" :dataname="iii.text">
                  <div class="nume_extra" v-if="$i18n.locale == 'ro'">{{iii.text}}</div>
                  <div class="nume_extra" v-else>{{iii.text}}</div>
                  <input type="radio" name="checkbox_optiune" :checked="index == 0" class="checkbox_optiune"  @click="()=>{selectOptiune(iii.text,iii.external_id,id_produs)}" :value="[iii.text,iii.external_id,id_produs]">
                  <span class="checkmark"></span>
                </label>
            </div> 
          </div>
           <div class="title_box_livrare">
            <div class="title_livrare">{{ $t('nota_produs_individual') }}</div>
             <textarea name="nota_produs_individual" rows="2" maxlength="150" class="nota_produs_individual" v-model="nota_produs_individual"></textarea>
            
          </div>
          <div class="extra_modal">
            <div class="titlu_extra_modal" v-if="ingrediente_extra.length > 0">{{ $t('adauga_ingrediente') }}</div>
            <div class="container_checkbox">
                <label v-for="ie in ingrediente_extra" :key="ie.id" class="container checkbox_flex prod_extra" :dataname="ie.name_ro" :datapret="ie.price">
                  <div class="nume_extra" v-if="$i18n.locale == 'ro'">{{ie.name_ro}}</div>
                  <div class="nume_extra" v-else>{{ie.name_en}}</div>
                  <input type="checkbox" @click="()=>{selectExtra(ie.name_ro,ie.price,ie.id,id_produs)}" :value="[ie.name_ro,ie.price,ie.id,id_produs] " >
                  <span class="checkmark"></span>
                  <div class="pret_extra">{{ie.price}} lei</div>
                </label>
            </div> 
          </div>   
        </div>
        <div v-if="this.$store.state.status_bucatarie == '1' || (this.$store.state.status_bucatarie == '2' && preparing_time == 'Fast')" class="adauga_produs_modal">
                <div class="nr_produse_modal">
                   <font-awesome-icon v-on:click="countMinus()" :icon="{ prefix: 'fas', iconName: 'minus' }" class="minus_plus minus_modal"/>
                   <div class="numar_modal">{{ count }} </div>
                   <font-awesome-icon v-on:click="count++" :icon="{ prefix: 'fas', iconName: 'plus' }" class="minus_plus plus_modal"/>
                </div>
                <div class="adauga_cos_modal" :dataid="id_produs"  @click="adaugaProdusInCos">{{ $t('adauga_in_cos') }}</div>
        </div>
        <div v-else-if="this.$store.state.status_bucatarie == '2' && preparing_time == 'Normal'" class="mesaj_bucatarie_inchisa">
          <div v-if="$i18n.locale == 'ro'">{{msg_status_Fast_ro}}</div>
          <div v-else-if="$i18n.locale == 'en'">{{msg_status_Fast_en}}</div>
        </div>
        <div v-else-if="this.$store.state.status_bucatarie == '0'" class="mesaj_bucatarie_inchisa">
          <div v-if="$i18n.locale == 'ro'">{{msg_status_Closed_ro}}</div>
          <div v-else-if="$i18n.locale == 'en'">{{msg_status_Closed_en}}</div>
        </div>
    </sweet-modal>

    <sweet-modal  ref="modal_resetare_parola" class="modal_resetare_parola"  v-on:open="vezi_modal" v-on:close="close_html">
       <div class="titlu_modal_setari"> 
        <div class="element_mobile"><img src= '@/assets/images/logo-tiki-bistro.png' /></div>
        <div class="titlu_setari">{{ $t('change_pass_text') }}</div>
      </div>
      <div class="content_setari_modal">
         <div class="label_gg">
            <input autocomplete="none" type="password" name="parola_noua" class="input_gg parola_noua" :placeholder="$t('parola_noua')">
          </div>
           <div class="label_gg">
            <input autocomplete="none" type="password" name="parola_noua1" class="input_gg parola_noua1" :placeholder="$t('parola_noua1')">
          </div>
          <div class="btn_gg" @click="reseteazaParola()">{{ $t('reset_pass_btn') }}</div>
          <div v-if="$i18n.locale == 'ro'" class="eroare eroare_reset">{{eroare_generala}}</div>
          <div v-else-if="$i18n.locale == 'en'" class="eroare eroare_reset">{{eroare_generala}}</div>          
      </div>
    </sweet-modal>

    <div class="alerta_generala alerta_verde modal_confirmare"><i class="ri-checkbox-circle-line"></i>{{ $t('produs_adaugat_cos') }}</div>
    <div class="alerta_generala alerta_verde modal_autentificare_facebook"><i class="ri-checkbox-circle-line"></i>{{ $t('autentificare_succes') }}</div>
    <div class="alerta_generala alerta_verde contact_trimis"><i class="ri-checkbox-circle-line"></i>{{ $t('contact_trimis') }}</div>
    <div class="alerta_generala alerta_verde modal_editare_profil"><i class="ri-checkbox-circle-line"></i>{{ $t('date_editate') }}</div>
    <div class="alerta_generala alerta_verde alerta_telefon_null"><i class="ri-checkbox-circle-line"></i>{{ $t('alerta_telefon_null') }}</div>
    <div class="alerta_generala alerta_verde modal_adaugat_la_favorite"><i class="ri-checkbox-circle-line"></i>{{ $t('produs_adaugat_favorite') }}</div>
    <div class="alerta_generala alerta_rosu modal_scos_de_la_favorite"><i class="ri-close-line"></i>{{ $t('produs_sters_favorite') }}</div>
    <div class="alerta_generala alerta_rosu modal_logout"><i class="ri-close-line"></i>{{ $t('text_logout') }}</div>
    <div class="alerta_generala alerta_verde cod_retrimis"><i class="ri-checkbox-circle-line"></i>{{ $t('cod_retrimis') }}</div>
    <div class="alerta_generala alerta_rosu cod_netrimis"><i class="ri-close-line"></i>{{ $t('cod_netrimis') }}</div>
    <div class="alerta_generala alerta_verde adresa_adaugata"><i class="ri-checkbox-circle-line"></i>{{ $t('adresa_adaugata') }}</div>


    <section v-if="cosCumparaturi.produse.length > 0 && cos_mobile == 1" class="sectiune_fixa_mobil" >
        <div class="nrproduse_pret"  @click="deschide_cos_cumparaturi">
          <div class="nrproduse"  v-if="total_produse_cos != 1">{{ total_produse_cos }} {{ $t('produse_in_cos') }}</div>
          <div class="nrproduse" v-else>{{ total_produse_cos }} {{ $t('one_product') }}</div>
          <div class="pretul_total">{{parseFloat(total_pret_cos).toFixed(2)}} Lei</div>
        </div>
        <div v-if="this.$store.getters.isAuthenticated" class="comanda_acum get_informatii" v-on:click="showModal_header_comanda()" >{{ $t('comanda_acum') }}</div>
        <div v-else class="comanda_acum get_informatii" v-on:click="showModal_header_comanda_neautentificat()">{{ $t('comanda_acum') }}</div>
    </section>

     <div v-if="loading == 1" class="loader_container">
          <div class="loader_tiki"><img src="/img/logo-tiki-bistro.1c5fcf96.png"></div>
      </div>


      

  </div>
</template>

<script>
    import moment from 'moment'
    import Vue from 'vue';
    import VueFilterDateFormat from 'vue-filter-date-format';
    import i18n from '@/plugins/i18n';
    import persistentState from 'vue-persistent-state'

    Vue.use(VueFilterDateFormat);
    import $ from 'jquery'
    import { SweetModal } from 'sweet-modal-vue'
    import { mapActions } from "vuex";
    import axios from 'axios';
    import {mapState, mapGetters} from 'vuex'
    import Vuex from 'vuex';
    import { initFbsdk } from '@/config/fb.js'
    import VueSlickCarousel from 'vue-slick-carousel'
    import 'vue-slick-carousel/dist/vue-slick-carousel.css'
    // optional style for arrows & dots
    import 'vue-slick-carousel/dist/vue-slick-carousel-theme.css'

    Vue.use(Vuex); 
    

    import VuePhoneNumberInput from 'vue-phone-number-input';
    import 'vue-phone-number-input/dist/vue-phone-number-input.css';
    import server_config from '@/server_config/server_config.json';
    
    Vue.component('VuePhoneNumberInput', VuePhoneNumberInput);

    import VueAwesomeSwiper from 'vue-awesome-swiper'
    import 'swiper/swiper-bundle.css';
  Vue.use(VueAwesomeSwiper, /* { default options with global component } */)

    //import VueAddtohomescreen from "@owliehq/vue-addtohomescreen";
    //Vue.use(VueAddtohomescreen);

    let initialState = {
      str: 'persist me', 
      cosCumparaturi: {
        produse: []
      },
      produse_favorite_state : [],
      produse_favorite_state_full : [],
      number: 42,
      telefon : '',
      telefon_comanda : '',
      cod_tara : '',
      email: '',
      nume: '',
      adresa_generala : '',
      adresa_secundara : '',
      pret_livrare : 0,
      durata_livrare : ''
    }
    
    Vue.use(persistentState, initialState)
    
    export default {
      name: 'App', 
      components: {  
        SweetModal,
        VueSlickCarousel   
      },
      metaInfo () {
        return {
          script: [{
            src: `https://maps.googleapis.com/maps/api/js?key=AIzaSyBohXmUieNzUosdOTQmI0XQpz9kwjHv8ao&libraries=places,geometry&v=weekly`,
            async: true,
            defer: true,
            callback: () => this.MapsInit() // will declare it in methods
          }]
        }
      },
      computed: { 
        total_pret_cos() {
          if(this.cosCumparaturi.produse.length == 0)
          { 
            var total = 0;
            return total;
          } 
          else
          {
            return this.cosCumparaturi.produse.reduce( (acc, produs_cos) => {
              var total_extra = 0;
              if(produs_cos.produse_extra.length > 0)
              {
                Object.entries(produs_cos.produse_extra).forEach(([key, val]) => { 
                    total_extra += val[1] 
                });
              }


              if(produs_cos.discount_procent > 0)
                 total =  acc + (produs_cos.cantitate_produs * produs_cos.discount_price_final) + (total_extra * produs_cos.cantitate_produs);  
              else
                 total =  acc + (produs_cos.cantitate_produs * produs_cos.pret_produs) + (total_extra * produs_cos.cantitate_produs);   
              return total;
            }, 0)
            
          }
          
        },
        isLoggedIn: function() {
          return this.$store.getters.isAuthenticated;
        },
          ...mapState([
              "cart"
          ]),
          ...mapGetters([
              "cartSize",
              "cartTotalAmount"
          ]), 
      },   
      data() 
      {
        return {
          settings_imagine_produs: {
            arrows: false,
            dots: true,
            slidesToShow: 1,
          
          },
          array_imagini_produs: [],
          cos_mobile: 0,
          eroare_adauga_adresa_noua : "",
          eroare_adauga_adresa_editata : "",
          nume_user: "",
          detalii_comanda: [],
          prenume_user: "",
          email: "",
          telefon: "",
          discount_price_final : "",
          discount_procent : '',
          end_call : false,
          vm: {
              searchPlace: '',
              location: {}
          },
          categorii : [], 
          search_key: "",
          search_key_adresa_livrare: '',
          adresa_selectata : {
            prima_adresa: "",
            adresa_2 : "",
          },
          prima_adresa_neschimbata : "",
          metoda_de_plata : "",
          metoda_de_plata_en : "",
          value_plata : 0,
          count_produs : 1,
          articole : [],
          languages: [
              { flag: require('@/assets/images/icon-romanian-flag.png'), language: 'ro', title: 'Romana' },
              { flag: require('@/assets/images/Group 2225.png'), language: 'en', title: 'English' }
          ],
          form: {
            email: "",
            password: "",
          },
          telefon_comanda_nefinalizat : '',
          telefon_valid : false,
          cod_tara_nefinalizat: "",
          comenzi: [],
          comanda_minima: "",
          comanda_minima_livrare_gratuita: "",
          adrese_user: [],
          adresa_edit: "",
          eroare_telefon_ro : "",
          eroare_generala : "",
          eroare_generala_en : "",
          eroare_telefon_en : "",
          id_adresa_edit: "",
          descriere_adresa_edit: "",
          tranzactie_ok : 2,
          form_register: {
            email: "",
            password: "",
            last_name : "",
            first_name : "",
            phone: "",
            country_code: "",
          },
          livrare: {
            adresa_livrare: "",
            bloc : "",
            telefon : ""
          },
          showError: false,
          showError_editare: false,
          erori_inputuri : {
              eroare_update_profil : false    
          },
          produse_favorite_state : [],
          eroare_recuperare_parola: "",
          nume_produs : "",
          descriere_produs : "",
          nume_produs_en : "",
          descriere_produs_en : "",
          preparing_time : "",
          imagine_produs : "",
          pret : "",
          ingrediente_extra : [],
          optiuni_extra : [],
          optiuni_extra_produs : [],
          id_produs : '', 
          count: 1,
          alergeni_ro:"",
          alergeni_en:"",
          external_id : "",
          produse_extra: [],
          pret_produs_comanda: "",
          array_produse : [],
          phone : "",
          tip_tranzactie : 2,
          tacamuri_check : 0,
          checkout_id : '',
          transaction_id : '',
          code_voucher : "",
          voucher_status_id : 0,
          voucher_type : 0,
          voucher_value : 0,
          voucher_activ : 0,
          pret_dupa_voucher_fara_livrare : 0,
          voucher_currency : '',
          total_voucher : 0,
          pret_final_comanda : 0,
          produse_array_cos : [],
          pret_livrare_general : 0,
          adauga : '',
          eroare_cod_telefon : '',
          eroare_cod_telefon_en : '',
          loading : 0,
          final_axios : 0,
          total_produse_cos : 0,
          location_gmaps: '',
          searchResults: [],
          service: null,
          eroare_comanda_minima: "",
          eroare_comanda_minima_en : "",
          eroare_completare_adresa : "",
          eroare_completare_adresa_en : "",
          eroare_completare_adresa_nr : "",
          eroare_completare_adresa_nr_en : "",
          msg_status_Closed_en : "",
          msg_status_Closed_ro : "",
          msg_status_Fast_en : "",
          msg_status_Fast_ro : "",
          msg_status_Manual_closed_en : "",
          msg_status_Manual_closed_ro : "",
          eroare_produs_bucatarie : "",
          lat_adresa : "",
          lng_adresa : "",
          lat_adresa_edit : "",
          lng_adresa_edit : "",
          lat_adresa_edit_old : "",
          lng_adresa_edit_old : "",
          lat_adresa_add_old: "",
          lng_adresa_add_old: "",
          takeaway : false,
          informatii_comanda : "",
          nota_produs_individual : "",
          url_delivery : 0,
          adresa_a_fost_setata : 0,
          location_shop : '',
          points_of_interest : 0,
          voucher_description : '',
          voucher_categories : [],
          voucher_products : [],
          pret_cos_fara_voucher : 0,
          pret_cos_produse_conditionate : 0,
          categorii_standard_produs : []
        }
      },
      watch:{    
        total_pret_cos: {
          handler: function (total_f) {
            if(total_f == 0)
            {
              this.pret_final_comanda = this.pret_livrare;
              this.pret_dupa_voucher_fara_livrare = 0;
            }
            else
            { 
              this.preiaInformatiiUser();
              this.calculeazaVoucher(this.voucher_type,this.total_pret_cos,this.voucher_value,this.voucher_categories,this.voucher_products);

              if(this.total_pret_cos >= this.comanda_minima || this.address_id == 0)
              {
                this.eroare_comanda_minima = "";
                this.eroare_comanda_minima_en = "";
              }
              else
              {
                this.eroare_comanda_minima = "Comanda minimă este de "+this.comanda_minima+' lei. Transportul nu este inclus.';
                this.eroare_comanda_minima_en = "The minimum order is "+this.comanda_minima+' lei. The transport is not included.'; 
              }
            }
            this.actualizareTotalCos();  
          }
        },
        

        pret_livrare: {
          handler: function () {
            this.calculeazaVoucher(this.voucher_type,this.total_pret_cos,this.voucher_value,this.voucher_categories,this.voucher_products);   
          }  
        },
        $route(to, from){
          
          if(from.name == 'Search')
            $('.search_header').val('');
          
          if(to.name != 'Search' && to.hash.indexOf("p-") === -1)
          {
            $("html").scrollTop(0);
            this.loading = 1;
          }

          let vm = this;
          var hash = window.location.hash.substr(1);

          if(hash != '' && hash.indexOf("p-") === -1)
          {     
            
            vm.loading = 1;
            setTimeout(function(){
              if ($('.'+hash).length) {
                $("html, body").animate({ scrollTop: $('.'+hash).offset().top - 230 }, 0);
              }
              vm.loading = 0;
            },3000);
            // var offTop =$('.'+hash).offset().top - 130;
            // setTimeout(function(){
            //  $("html, body").scrollTop(offTop);
            //  },1000);
          }   
          else
          {
            
            setTimeout(function(){
                vm.loading = 0;
            },2000);
          }
        },
      },
      created() {
          this.$root.$refs.Header = this;
      },
      methods: {
        verificareLogin() {
          // var data_login = localStorage.getItem('date_login');
          // var data_curenta_login = new Date();

          // var diff = Math.abs(new Date(data_curenta_login) - new Date(data_login));
          // var minutes = Math.floor((diff/1000)/60);

          // if(minutes > 43200 && this.isLoggedIn == true)
          // {
          //   this.logout()
          //   localStorage.removeItem('token')
          //   localStorage.removeItem('date_login')
          // }
        },
        adaugare_adresa_noua() {
            
            const map = new window.google.maps.Map(
                document.getElementById("map-adresa-noua"),
                {
                  center: { lat: 47.151726, lng: 27.587914 },
                  zoom: 10,
                  mapTypeControl: false,
                  disableDefaultUI: true,
                  zoomControl: false,
                  gestureHandling: 'none',
                  
                }
              );
            const card = document.getElementById("pac-card-adresa-noua");
              const input = document.getElementById("pac-input-adresa-noua");
              const biasInputElement = document.getElementById(
                "use-location-bias-adresa-noua"
              );
              const strictBoundsInputElement = document.getElementById(
                "use-strict-bounds-adresa-noua"
              );

              const options = {
                strictBounds: true,
                componentRestrictions: { country: ["ro"] }
              };

              map.controls[window.google.maps.ControlPosition.TOP_LEFT].push(card);

              const autocomplete = new window.google.maps.places.Autocomplete(input, options);

              // Bind the map's bounds (viewport) property to the autocomplete object,
              // so that the autocomplete requests use the current map bounds for the
              // bounds option in the request.
              autocomplete.bindTo("bounds", map);

              const infowindow = new window.google.maps.InfoWindow();
              const infowindowContent = document.getElementById(
                "infowindow-content-adresa-noua"  
              );


              if(infowindowContent == null)
              {
                
                const infowindowContent = document.getElementById(
                  "infowindow-content-adresa-noua"  
                );
              }

              infowindow.setContent(infowindowContent);

              const marker = new window.google.maps.Marker({
                map,
                anchorPoint: new window.google.maps.Point(0, -29)
              });

              autocomplete.addListener("place_changed", () => {
                infowindow.close();
                marker.setVisible(false);

                const place = autocomplete.getPlace();

                if (!place.geometry || !place.geometry.location) {
                  // User entered the name of a Place that was not suggested and
                  // pressed the Enter key, or the Place Details request failed.
                  window.alert("No details available for input: '" + place.name + "'");
                  return;
                }

                // If the place has a geometry, then present it on a map.
                if (place.geometry.viewport) {
                  map.fitBounds(place.geometry.viewport);
                  map.setZoom(10);
                } else {
                  map.setCenter(place.geometry.location);
                  map.setZoom(10);
                }

                marker.setPosition(place.geometry.location);
                marker.setVisible(true);


                infowindowContent.children["place-name"].textContent = place.name;
                infowindowContent.children["place-address"].textContent = place.formatted_address;
                infowindow.open(map, marker);

                var types_points = place.types;

                if(types_points.includes('point_of_interest'))
                {
                  this.points_of_interest = 1;
                }
                else
                {
                  this.points_of_interest = 0;
                }
                  var adresa_input_initial = $('#pac-input-adresa-noua').val();


                if(place.name != '' && place.geometry.location.lat() != '' && place.geometry.location.lng() != '' && (this.points_of_interest == 1 || (this.points_of_interest == 0 && adresa_input_initial.match(/\d+/g) != null)))
                {
                  
                  this.location_gmaps = place.name;
                  this.prima_adresa_neschimbata  = place.name; 

                  this.eroare_completare_adresa = "";
                  this.eroare_completare_adresa_en = "";
                  this.eroare_completare_adresa_nr = "";
                  this.eroare_completare_adresa_nr_en = "";
                  this.lat_adresa = place.geometry.location.lat();
                  this.lng_adresa = place.geometry.location.lng();
                  this.lat_adresa_add_old = place.geometry.location.lat();
                  this.lng_adresa_add_old = place.geometry.location.lng(); 
                }
                else
                {
                  this.eroare_completare_adresa_nr = "Adresă invalidă - vă rugăm adăugați și numarul străzii";
                  this.eroare_completare_adresa_nr_en = "Invalid address. Please add the street name and number!";
                  
                  // this.lat_adresa = '';
                  // this.lng_adresa = '';
                }
              });

              function setupClickListener(id) {
                const radioButton = document.getElementById(id);
                radioButton.addEventListener("click", () => {
                  autocomplete.setTypes();
                  input.value = "";
                });
              }

              setupClickListener("changetype-address");
              
              
        },
        countMinus() {
          if(this.count > 1)
            this.count--;
          else
            this.count = 1;
        },
        adaugare_adresa_editata() {     
            const map = new window.google.maps.Map(
                document.getElementById("map-adresa-editata"),
                {
                  center: { lat: 47.151726, lng: 27.587914 },
                  zoom: 10,
                  mapTypeControl: false,
                  disableDefaultUI: true,
                  zoomControl: false,
                  gestureHandling: 'none'
                }
              );
              const card = document.getElementById("pac-card-adresa-editata");
              const input = document.getElementById("pac-input-adresa-editata");
              const biasInputElement = document.getElementById(
                "use-location-bias-adresa-editata"
              );
              const strictBoundsInputElement = document.getElementById(
                "use-strict-bounds-adresa-editata"
              );

              const options = {
                strictBounds: true,
                componentRestrictions: { country: ["ro"] }
              };

              map.controls[window.google.maps.ControlPosition.TOP_LEFT].push(card);

              const autocomplete = new window.google.maps.places.Autocomplete(input, options);

              // Bind the map's bounds (viewport) property to the autocomplete object,
              // so that the autocomplete requests use the current map bounds for the
              // bounds option in the request.
              autocomplete.bindTo("bounds", map);

              const infowindow = new window.google.maps.InfoWindow();
              const infowindowContent = document.getElementById(
                "infowindow-content-adresa-editata"  
              );

              infowindow.setContent(infowindowContent);

              const marker = new window.google.maps.Marker({
                map,
                anchorPoint: new window.google.maps.Point(0, -29)
              });

              autocomplete.addListener("place_changed", () => {
                infowindow.close();
                marker.setVisible(false);

                const place = autocomplete.getPlace();

                if (!place.geometry || !place.geometry.location) {
                  // User entered the name of a Place that was not suggested and
                  // pressed the Enter key, or the Place Details request failed.
                  window.alert("No details available for input: '" + place.name + "'");
                  return;
                }

                // If the place has a geometry, then present it on a map.
                if (place.geometry.viewport) {
                  map.fitBounds(place.geometry.viewport);
                  map.setZoom(10);
                } else {
                  map.setCenter(place.geometry.location);
                  map.setZoom(10);
                }

                marker.setPosition(place.geometry.location);
                marker.setVisible(true);
                infowindowContent.children["place-name"].textContent = place.name;
                infowindowContent.children["place-address"].textContent = place.formatted_address;
                infowindow.open(map, marker);

                var types_points = place.types;

                if(types_points.includes('point_of_interest'))
                {
                  this.points_of_interest = 1;
                }
                else
                {
                  this.points_of_interest = 0;
                }
                  var adresa_input_initial = $('#pac-input-adresa-editata').val();


                if(place.name != '' && place.geometry.location.lat() != '' && place.geometry.location.lng() != '' && (this.points_of_interest == 1 || (this.points_of_interest == 0 && adresa_input_initial.match(/\d+/g) != null)))
                {

                  this.location_gmaps = place.name;
                  this.prima_adresa_neschimbata  = place.name; 

                  this.adresa_edit = place.name;

                  this.eroare_completare_adresa = "";
                  this.eroare_completare_adresa_en = "";
                  this.eroare_completare_adresa_nr = "";
                  this.eroare_completare_adresa_nr_en = "";
                  this.lat_adresa_edit = place.geometry.location.lat();
                  this.lng_adresa_edit = place.geometry.location.lng(); 
                }
                else
                {
                  this.eroare_completare_adresa_nr = "Adresă invalidă - vă rugăm adăugați și numarul străzii";
                  this.eroare_completare_adresa_nr_en = "Invalid address. Please add the street name and number!";
                  // this.lat_adresa = '';
                  // this.lng_adresa = '';
                }
              });

              // Sets a listener on a radio button to change the filter type on Places
              // Autocomplete.
              function setupClickListener(id) {
                const radioButton = document.getElementById(id);
                radioButton.addEventListener("click", () => {
                  autocomplete.setTypes();
                  input.value = "";
                });
              }

              setupClickListener("changetype-address");
              
              // biasInputElement.addEventListener("change", () => {
              //   autocomplete.bindTo("bounds", map);
              //   input.value = "";
              // });
              // console.log(strictBoundsInputElement);
              // strictBoundsInputElement.addEventListener("change", () => {
              //   autocomplete.setOptions({
              //     strictBounds: strictBoundsInputElement.checked
              //   });

              //   if (strictBoundsInputElement.checked) {
              //     biasInputElement.checked = strictBoundsInputElement.checked;
              //     autocomplete.bindTo("bounds", map);
              //   }
              //   input.value = "";
              // });
        },
        descopera_app(){
          $('.sweet-action-close').click();
        },
        deschide_cos_cumparaturi(){
          $('.cos_header').click();
        },
        adauga_adresa_noua_buton(){
          $('.locatie_checkout_modal_3').val('');
           $('.modal_2_checkout').hide(100);  
            $('.modal_3_checkout').fadeIn(100);
        },
        selecteazaAdresa(result) {

          this.prima_adresa_neschimbata  = result;
          
          if(result != '' && result.match(/\d+/g) != null || this.lat_adresa == '')
          {
            this.location_gmaps = result;

            $('.locatie_checkout_modal_3').val('');
            
            this.eroare_completare_adresa = "";
            this.eroare_completare_adresa_en = "";
            this.eroare_completare_adresa_nr = "";
            this.eroare_completare_adresa_nr_en = "";
          }
          else
          {
            this.eroare_completare_adresa_nr = "Adresă invalidă - vă rugăm adăugați și numarul străzii";
            this.eroare_completare_adresa_nr_en = "Invalid address. Please add the street name and number!";
          }
        },
        MapsInit() {
              const map = new window.google.maps.Map(
                document.getElementById("map"),
                {
                  center: { lat: 47.151726, lng: 27.587914 },
                  zoom: 10,
                  mapTypeControl: false,
                  disableDefaultUI: true,
                  zoomControl: false,
                  gestureHandling: 'none'
                  
                }
              );
              const card = document.getElementById("pac-card");
              const input = document.getElementById("pac-input");
              const biasInputElement = document.getElementById(
                "use-location-bias"
              );
              const strictBoundsInputElement = document.getElementById(
                "use-strict-bounds"
              );

              const options = {
                strictBounds: true,
                componentRestrictions: { country: ["ro"] }
              };

              map.controls[window.google.maps.ControlPosition.TOP_LEFT].push(card);

              const autocomplete = new window.google.maps.places.Autocomplete(input, options);

              // Bind the map's bounds (viewport) property to the autocomplete object,
              // so that the autocomplete requests use the current map bounds for the
              // bounds option in the request.
              autocomplete.bindTo("bounds", map);

              const infowindow = new window.google.maps.InfoWindow();
              const infowindowContent = document.getElementById(
                "infowindow-content"  
              );

              infowindow.setContent(infowindowContent);

              const marker = new window.google.maps.Marker({
                map,
                anchorPoint: new window.google.maps.Point(0, -29)
              });

              autocomplete.addListener("place_changed", () => {
                infowindow.close();
                marker.setVisible(false);

                const place = autocomplete.getPlace();

                if (!place.geometry || !place.geometry.location) {
                  // User entered the name of a Place that was not suggested and
                  // pressed the Enter key, or the Place Details request failed.
                  window.alert("No details available for input: '" + place.name + "'");
                  return;
                }

                // If the place has a geometry, then present it on a map.
                if (place.geometry.viewport) {
                  map.fitBounds(place.geometry.viewport);
                  map.setZoom(10);
                } else {
                  map.setCenter(place.geometry.location);
                  map.setZoom(10);
                }

                marker.setPosition(place.geometry.location);
                marker.setVisible(true);

                infowindowContent.children["place-name"].textContent = place.name;
                infowindowContent.children["place-address"].textContent =
                place.formatted_address;
                infowindow.open(map, marker);

                var types_points = place.types;

                if(types_points.includes('point_of_interest'))
                {
                  this.points_of_interest = 1;
                }
                else
                {
                  this.points_of_interest = 0;
                }
                var adresa_input_initial = $('#pac-input').val();


                if(place.name != '' && place.geometry.location.lat() != '' && place.geometry.location.lng() != '' && (this.points_of_interest == 1 || (this.points_of_interest == 0 && adresa_input_initial.match(/\d+/g) != null)))
                {
                  this.location_gmaps = place.name;
                  this.prima_adresa_neschimbata  = place.name; 

                  $('.locatie_checkout_modal_3').val('');
                  $(".locatie_checkout_modal_3").attr("readonly", false);
                  this.eroare_completare_adresa = "";
                  this.eroare_completare_adresa_en = "";
                  this.eroare_completare_adresa_nr = "";
                  this.eroare_completare_adresa_nr_en = "";
                  this.lat_adresa = place.geometry.location.lat();
                  this.lng_adresa = place.geometry.location.lng();                         
                }
                else
                {
                  this.eroare_completare_adresa_nr = "Adresă invalidă - vă rugăm adăugați și numarul străzii";
                  this.eroare_completare_adresa_nr_en = "Invalid address. Please add the street name and number!";
                  setTimeout(function(){
                    var num = $("#pac-input").val();
                    $("#pac-input").focus().val('').val(num);
                  },400);  
                  
                }
              });

              // Sets a listener on a radio button to change the filter type on Places
              // Autocomplete.
              function setupClickListener(id) {
                const radioButton = document.getElementById(id);
                radioButton.addEventListener("click", () => {
                  autocomplete.setTypes();
                  input.value = "";
                });
              }

              setupClickListener("changetype-address");
              
              biasInputElement.addEventListener("change", () => {
                autocomplete.bindTo("bounds", map);
                input.value = "";
              });

              strictBoundsInputElement.addEventListener("change", () => {
                autocomplete.setOptions({
                  strictBounds: strictBoundsInputElement.checked
                });

                if (strictBoundsInputElement.checked) {
                  biasInputElement.checked = strictBoundsInputElement.checked;
                  autocomplete.bindTo("bounds", map);
                }
                input.value = "";
              });
        },
         verificaPozaProdus: function(id_produs) {
          this.array_produse.forEach((entry) => {   
              if(entry.id_produs == id_produs)
              {
                window.poza_produs_finala = entry.poza_produs;
              }    
          });
          return window.poza_produs_finala;
        },
        loginWithFacebook () {

          var that = this;
          window.FB.login(response => {
            window.FB.api('/me', 'get', { access_token: response.authResponse.accessToken, fields: 'id,gender,email, last_name, first_name' }, function(response) {
                
                var response_fb = response;
                if(response_fb.id != "" && response.name != "" && response_fb.email != "")
                {
                    axios.post('https://xpanel.tikibistro.ro/api/auth/login_facebook', {
                        facebook_id : response_fb.id,
                        email : response_fb.email,
                        first_name : response_fb.first_name,
                        last_name : response_fb.last_name,
                    }, {
                      withCredentials: false,
                    }).then((result) => {

                      localStorage.setItem('token', result.data.data[0].token); 
                      $('.inapoi_modal_start').click();
                      $('.sweet-action-close').click();
                      that.$store.commit("setUser", response_fb.email);
                       if(/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
                          $('.modal_autentificare_facebook').animate({ "right": "10px"}, "slow");
                        } else{
                            $('.modal_autentificare_facebook').animate({ "right": "20px"}, "slow");
                        }
                      setTimeout(function(){
                        if(/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
                          $('.modal_autentificare_facebook').animate({"right": "-1000px"}, "slow");
                        } else{
                          $('.modal_autentificare_facebook').animate({"right": "-100vw"}, "slow");
                        }
                      }, 2000);

                      that.preiaInformatiiUser();
                    }, (error) => {
                      var json = JSON.stringify(error);
                      var actiune = 'https://xpanel.tikibistro.ro/api/auth/login_facebook';
                      that.trimiteLog(actiune,json); 
                    }); 
                }
                else
                {
                  alert('Nu s-au putut obtine datele de logare');
                }
            });
          }, { scope: 'email' })
        },
        calculeazaVoucher(voucher_type,total_pret_cos,valoare_voucher,categories,products){
          this.pret_cos_produse_reduse = 0;
          this.pret_cos_fara_voucher = 0;
          this.pret_cos_produse_conditionate = 0;


          Object.entries(this.cosCumparaturi.produse).forEach(([key, val]) => { 
               
              if(val.discount_procent == 0)
              {

                var exista_in_array_reduceri = false;
                var reduceri_conditionate = false;
                if(products != undefined && products != null)
                {
                  reduceri_conditionate = true;
                  if(products.includes(val.id_produs.toString()))
                    exista_in_array_reduceri = true;
                }
                var categorii_produs = val.categorii_standard_produs;
                if(categorii_produs != undefined && categories != null)
                {
                  reduceri_conditionate = true;
                  Object.entries(categorii_produs).forEach(([key1, val_categorie]) => {  
                      if(categories.includes(val_categorie.id.toString()))
                        exista_in_array_reduceri = true;
                  });
                }


                if((reduceri_conditionate == false && exista_in_array_reduceri == false) || (reduceri_conditionate == true && exista_in_array_reduceri == true))
                {       
                  this.pret_cos_fara_voucher += val.cantitate_produs * val.pret_produs;
                  if(val.produse_extra.length > 0)
                  {
                    Object.entries(val.produse_extra).forEach(([key1, val1]) => { 
                        this.pret_cos_fara_voucher += val.cantitate_produs * val1[1];
                    });
                  }
                }
                else
                {
                 
                  this.pret_cos_produse_conditionate += val.cantitate_produs * val.pret_produs;
                  if(val.produse_extra.length > 0)
                  {
                    Object.entries(val.produse_extra).forEach(([key1, val1]) => { 
                        this.pret_cos_produse_conditionate += val.cantitate_produs * val1[1];

                    });
                  }
                }     
              }
              else{
                this.pret_cos_produse_reduse = this.pret_cos_produse_reduse + val.cantitate_produs * val.discount_price_final;
                if(val.produse_extra.length > 0)
                {
                  Object.entries(val.produse_extra).forEach(([key1, val1]) => {
                    if(voucher_type == 2)
                    {
                      var pret_extra_combinat = val.cantitate_produs * val1[1];
                      this.pret_cos_produse_reduse += pret_extra_combinat - (pret_extra_combinat  * (valoare_voucher / 100));
                    }
                      
                    else
                      this.pret_cos_produse_reduse += val.cantitate_produs * val1[1];

                  });
                }
              }
          });

          if(voucher_type == 1)
          {  
            this.$store.state.total_pret_cos = total_pret_cos - valoare_voucher;   
            this.voucher_activ = 1;
            this.voucher_currency = 'Lei';  
            this.pret_final_comanda = total_pret_cos - valoare_voucher + this.pret_livrare;
            this.pret_dupa_voucher_fara_livrare = (total_pret_cos - valoare_voucher).toFixed(2);

            if(this.pret_dupa_voucher_fara_livrare >= this.comanda_minima_livrare_gratuita)
              {
                this.pret_livrare = 0;
              }
              else
              {
                this.pret_livrare = this.pret_livrare_general;
              }
          }
          else if(voucher_type == 2)
          {   
            this.$store.state.total_pret_cos = (this.pret_cos_fara_voucher - (this.pret_cos_fara_voucher  * (valoare_voucher / 100))) + this.pret_cos_produse_reduse + this.pret_cos_produse_conditionate;
            this.voucher_activ = 1;
            this.voucher_currency = '%';
            this.pret_final_comanda = (this.pret_cos_fara_voucher - (this.pret_cos_fara_voucher * (valoare_voucher / 100))) + this.pret_cos_produse_reduse + this.pret_livrare + this.pret_cos_produse_conditionate;
            this.pret_dupa_voucher_fara_livrare = ((this.pret_cos_fara_voucher - (this.pret_cos_fara_voucher * (valoare_voucher / 100))) + this.pret_cos_produse_reduse + this.pret_cos_produse_conditionate).toFixed(2);
            if(this.pret_dupa_voucher_fara_livrare >= this.comanda_minima_livrare_gratuita)
            {
              this.pret_livrare = 0;
            }
            else
            {
              this.pret_livrare = this.pret_livrare_general;
            }   
          }
          else if (voucher_type == 0)
          {
               if(total_pret_cos >= this.comanda_minima_livrare_gratuita)
              {
                this.pret_livrare = 0;
              }
              else
              {
                this.pret_livrare = this.pret_livrare_general;
              }
              this.voucher_activ = 0;
              this.pret_final_comanda = total_pret_cos + this.pret_livrare;
              this.pret_dupa_voucher_fara_livrare = 0;
          }
        },
        aplicaVoucher: function() {
          var cod_voucher = $('.voucher_input').val().toUpperCase();   
          var token = localStorage.token;
          if(!token)
          {
            this.$methods.logout()
            localStorage.removeItem('token')
          }
          else
          {  

            if(cod_voucher != '')
            {
              $(".aplica_voucher").attr("disabled", true);
              axios.post('https://xpanel.tikibistro.ro/api/voucher/check', {
                  code : cod_voucher,
              }, {
                headers: {
                  "Access-Control-Allow-Origin" : "*",
                  "Authorization": `Bearer ${token}`, 
                },
                withCredentials: false,
              }).then((result) => {
                   $(".aplica_voucher").attr("disabled", false);
                  if(result.data.status == 'VALID')
                  {
                      this.code_voucher = cod_voucher;
                      this.voucher_status_id = 2;
                      this.voucher_type = result.data.voucher.type;
                      this.voucher_value = result.data.voucher.value;
                      this.voucher_description = result.data.voucher.description;
                      this.voucher_categories = result.data.voucher.categories;
                      this.voucher_products = result.data.voucher.products;

                     
                      if(this.lat_adresa != '')
                      {    
                        
                        this.calculeazaTransportGetArea(this.lat_adresa, this.lng_adresa, this.adresa_generala, this.total_pret_cos);
                      }
                      else
                      {
                        
                        this.calculeazaVoucher(result.data.voucher.type,this.total_pret_cos,result.data.voucher.value,this.voucher_categories,this.voucher_products);
                      }
                  }
                  else
                  {
                      this.voucher_status_id = 1;
                  }
              }, (error) => {
                $(".aplica_voucher").attr("disabled", false);
                var json = JSON.stringify(error);
                var actiune = 'https://xpanel.tikibistro.ro/api/voucher/check'+' COD:'+cod_voucher;
                this.trimiteLog(actiune,json); 
              }); 
            }
            else
            {
              $(".aplica_voucher").attr("disabled", false);
              this.voucher_status_id = 1;
            }
          }
        },
        stergeVoucher: function() {
          this.code_voucher = "";
          this.voucher_status_id = 0;
          this.voucher_type = 0;
          this.voucher_value = 0;
          this.voucher_activ = 0;
          this.voucher_description = '';
          this.voucher_categories = [];
          this.voucher_products = [];
          this.calculeazaVoucher(this.voucher_type,this.total_pret_cos,this.voucher_value,this.voucher_categories,this.voucher_products);
          
        },
        filter(id_produs){
          return this.produse_favorite_state.filter(n=>n===id_produs).length===0?false:true; 
        },

        afisareCheckoutInitial() {
            $('.modal_2_checkout').hide();
            $('.modal_3_checkout').hide();
            $('.modal_4_checkout').hide();
            $('.modal_5_checkout').hide();
            $('.modal_6_checkout').hide();
            $('.modal_start_checkout').fadeIn(100);
            $('html').removeClass("modal_afisat");
        },
        stergeAdresa() {
          this.adresa_selectata.prima_adresa = "";
          this.adresa_selectata.adresa_2 = "";
          this.address_id = 0;
          this.adresa_generala = "";
          this.adresa_secundara = "";
          this.lat_adresa = "";
          this.lng_adresa = "";
          this.value_plata = 0;
          this.metoda_de_plata = '';
          this.metoda_de_plata_en = '';
        },
        deschide_modal_telefon(){
            $('.modal_profilul_meu_tel .profil_ascuns_mobile').hide();
            $('.modal_profilul_meu_tel .profilul_meu_content').hide();
            $('.modal_telefon_general').fadeIn(100);
        },
        inapoi_modal_start_profil_user(){
          $('.modal_telefon_general').hide();
           $('.modal_profilul_meu_tel .profilul_meu_content').fadeIn(100);
            $('.modal_profilul_meu_tel .profil_ascuns_mobile').fadeIn(100);
        },
         inapoi_modal_telefon_profil_user(){
          $('.modal_verificare_telefon').hide();
           $('.modal_telefon_general').fadeIn(100);
        },
        inapoi_modal_telefon_checkout_user(){
          $('.modal_4_checkout_verificare_telefon').hide();
           $('.modal_4_checkout').fadeIn(100);
        },
        inapoi_modal_telefon_null(){
          $('.verificare_telefon_null').hide();
           $('.modal_telefon_null_1').fadeIn(100);
        },
        
        selectExtra(nume,pret,id,id_produs){
          
            var produsul_exista = false;
            var that = this;
            if(this.produse_extra.length > 0)
            {
              $.each(this.produse_extra, function(key, value) {
  
                var id_produs_isset = value[3];
                var id_extra_isset = value[2];


                if(id_produs_isset == id_produs && id_extra_isset == id)
                {
                    produsul_exista = true;
                    that.produse_extra.splice(key,1);
                    return false;
                }
              });
            }

            if(produsul_exista == false)
            {
               var array_extra = [nume,pret,id,id_produs];
               this.produse_extra.push(array_extra)
            }     
        },
        selectOptiune(nume,id,id_produs){ 
          var array_optiuni_extra = [];
          array_optiuni_extra.push(nume,id);
          this.optiuni_extra_produs = array_optiuni_extra;
        },
        adaugaProdusInCos() {  
            if(this.id_produs != '')
            {
              var total_extra = 0;
              if(this.produse_extra.length > 0)
              {
                let total = [];
                Object.entries(this.produse_extra).forEach(([key, val]) => { 
                    total.push(val[1]) 
                });
                total_extra =  total.reduce(function(total, num){ return total + num }, 0)
              }


              let produs_cos = {
                id_produs : this.id_produs,
                nume_produs: this.nume_produs,
                descriere_produs : this.descriere_produs,
                nume_produs_en: this.nume_produs_en,
                descriere_produs_en : this.descriere_produs_en,
                poza_produs : this.imagine_produs,
                cantitate_produs : this.count,
                pret_produs: this.pret,
                discount_price_final : this.discount_price_final,
                discount_procent : this.discount_procent,
                produse_extra: this.produse_extra,
                external_id: this.external_id,
                total_extra : total_extra,
                total_produs: this.pret + (total_extra),
                preparing_time : this.preparing_time,
                optiuni_extra_produs : this.optiuni_extra_produs,
                nota_produs_individual : this.nota_produs_individual,
                categorii_standard_produs : this.categorii_standard_produs
              }
              if(this.cosCumparaturi.produse.some(id_pr => id_pr.id_produs === produs_cos.id_produs))
              {
                let i=0;
                var produsul_nu_exista = true;
                
                for(i=0;i<this.cosCumparaturi.produse.length;i++)
                {
                    if(this.cosCumparaturi.produse[i].id_produs === produs_cos.id_produs && JSON.stringify(this.cosCumparaturi.produse[i].produse_extra) == JSON.stringify(this.produse_extra) && JSON.stringify(this.cosCumparaturi.produse[i].optiuni_extra_produs) == JSON.stringify(this.optiuni_extra_produs) && this.cosCumparaturi.produse[i].nota_produs_individual == this.nota_produs_individual)
                    {
                      this.cosCumparaturi.produse[i].cantitate_produs = this.cosCumparaturi.produse[i].cantitate_produs + produs_cos.cantitate_produs;
                      produsul_nu_exista = false;
                    }
                }
                if(produsul_nu_exista == true)
                {
                  this.cosCumparaturi.produse.push(produs_cos);
                }
              }
              else
              {
                this.cosCumparaturi.produse.push(produs_cos);
              }
              $('.sweet-action-close').click();
              if(/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
                  $('.modal_confirmare').animate({ "right": "10px"}, "slow");
                } else{
                    $('.modal_confirmare').animate({ "right": "20px"}, "slow");
                }
              setTimeout(function(){
                if(/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
                  $('.modal_confirmare').animate({"right": "-1000px"}, "slow");
                } else{
                  $('.modal_confirmare').animate({"right": "-100vw"}, "slow");
                }
              }, 2000);
              this.produse_extra = [];

            }
            else
            {
              alert('Acest produs nu este disponibil.');
            }

        }, 
        vezi_modal() {

          this.eroare_generala = '';
          this.eroare_generala_en = '';
         
          $('html').addClass("modal_afisat");
          
  
          if ($(window).width() < 992) 
          {
            $(".adauga_produs_modal").css("position", "static");
            $('.sweet-content-content').addClass('padding_b_phone');
          }
        },
        close_html() {
          $('html').removeClass("modal_afisat");
        }, 
        close() {
            this.count = 1
            this.nume_produs = ""
            this.descriere_produs = ""
            this.nume_produs_en = ""
            this.descriere_produs_en = ""
            this.imagine_produs = ""
            this.external_id = ""
            this.pret = ""
            this.alergeni_ro = ""
            this.alergeni_en = ""
            this.ingrediente_extra = ""
            this.optiuni_extra = ""
            this.produse_extra = []
            this.id_produs = ""
            this.alergeni_ro = ""
            this.alergeni_en = ""
            $('html').removeClass("modal_afisat");
            $('.sweet-content-content').removeClass('padding_b_phone');
            window.location.hash = "p-";            
          }, 
          showModal_header_comanda(){
            this.$refs.modal_checkout.open();
          },
          // show_modal_versiune_beta(){
          //   if(sessionStorage.getItem('modal_beta') != '1'){
          //       this.$refs.modal_versiune_beta.open();
          //       sessionStorage.setItem('modal_beta','1')
          //   }
          // },
          show_modal_bucatarie_inchisa(){
            if(localStorage.getItem('modal_bucatarie_inchisa') != '1'){
                this.$refs.modal_bucatarie_inchisa.open();
                localStorage.setItem('modal_bucatarie_inchisa','1')
            }
          },
          showModal_header_comanda_neautentificat(){
            this.$refs.modal_profil_neautentificat.open();
          },
        recupereazaParola(){
          this.eroare_recuperare_parola = "";
          var email_cont =$('.email_parola_uitata').val();

            axios.post('https://xpanel.tikibistro.ro/api/user/restore_password', {
                    email : email_cont
                }, {
                  headers: {
                    "Access-Control-Allow-Origin" : "*",
                  },
                  withCredentials: false,
                }).then((response) => {
                  $('.modal4').hide();
                  $('.modal5').fadeIn(100);

                }, (error) => {
                  this.eroare_recuperare_parola = error.response.data.errors[0];
                  var json = JSON.stringify(error.response.data.errors[0]);
                  var actiune = 'https://xpanel.tikibistro.ro/api/user/restore_password'+' EMAIL:'+email_cont;
                  this.trimiteLog(actiune,json); 

                });  
        },
        
        actualizareTotalCos() {
          var i = 0;
          Array.from(this.cosCumparaturi.produse).forEach(element => 
            i += element.cantitate_produs  
          );
          this.total_produse_cos = i;
        },
        trimiteComandaFinalaPlatita(t,s) {
            // var token = localStorage.token;

            // if(token == '')
            // {
            //   this.$methods.logout()
            //   localStorage.removeItem('token')
            // }
            // else
            // {
            //   axios.get('https://xpanel.tikibistro.ro/api/profile?t='+Number(new Date()),  { 
            //     withCredentials: false,
            //     headers: {
            //       "Access-Control-Allow-Origin" : "*",
            //       "Content-type": "Application/json",
            //       "Authorization": `Bearer ${token}`
            //     }      
            //   })
            //   .then((response) => {
            //     var json_localstorage = JSON.stringify(localStorage);
            //     this.trimiteLog('Corelare local storage:',json_localstorage);

            //     this.address_id = localStorage.getItem("id_adresa");
            //     this.informatii_comanda = localStorage.getItem("informatii_comanda");
            //     this.tacamuri_check = localStorage.getItem("tacamuri_check");
            //     this.code_voucher = localStorage.getItem("voucher");
            //     this.$store.state.durata_livrare = localStorage.getItem("durata_livrare");
            //     this.$store.commit('seteazaDurataLivrare',localStorage.getItem("durata_livrare"));
 
            //     var adresa_existenta = 0;
            //     response.data.data.address.forEach(adresa_finala => { 
            //       if(this.address_id == adresa_finala.address_id)
            //       {
            //         adresa_existenta = 1;
            //         if(this.address_id == 0)
            //         {
            //           this.takeaway = true;
            //         }
            //         else
            //         {
            //           this.takeaway = false;
            //         }

            //         this.adresa_selectata.prima_adresa = adresa_finala.address;
            //         this.adresa_selectata.adresa_2 = adresa_finala.description;
            //         this.adresa_generala = adresa_finala.address;
            //         this.adresa_secundara = adresa_finala.description;
            //         this.lat_adresa = adresa_finala.address_lat;
            //         this.lng_adresa = adresa_finala.address_lng;
            //       }  
            //     })

            //     if(adresa_existenta == 0)
            //     {
            //       var json_adresa = JSON.stringify(response.data.data.address);
            //       json_adresa += JSON.stringify('Id adresa: '+this.address_id);

            //       var actiune_adresa = 'Adresa negasita!';
            //       this.trimiteLog(actiune_adresa,json_adresa); 
            //     }
                
            //     this.telefon_comanda = localStorage.getItem("telefon_comanda");
            //     this.cod_tara = localStorage.getItem("cod_tara");
            //     this.nume_user = response.data.data.first_name;
            //     this.prenume_user = response.data.data.last_name;

            //     //daca informatiile despre client sunt corecte, trimitem comanda

            //     var produse_array = [];
            //     for(var i=0; i<this.cosCumparaturi.produse.length;i++)
            //     {
            //       var produse_extra_array = [];
            //       var obj = {};
            //       obj["id"] = this.cosCumparaturi.produse[i].id_produs;
            //       obj["quantity"] = this.cosCumparaturi.produse[i].cantitate_produs;
            //       obj["note"] = this.cosCumparaturi.produse[i].optiuni_extra_produs[0] + ' ' + this.cosCumparaturi.produse[i].nota_produs_individual;
            //       obj["half"] = false; 
            //       obj["child"] = false;
            //       obj["external_id"] = this.cosCumparaturi.produse[i].optiuni_extra_produs[1];

            //       if(this.cosCumparaturi.produse[i].produse_extra)
            //       {
            //         for(var j=0; j<this.cosCumparaturi.produse[i].produse_extra.length;j++)
            //         {
            //             var obj1 = {};
            //             obj1["id"] = this.cosCumparaturi.produse[i].produse_extra[j][2];
            //             obj1["name_ro"] = this.cosCumparaturi.produse[i].produse_extra[j][0];
            //             obj1["quantity"] = this.cosCumparaturi.produse[i].cantitate_produs; 
            //             produse_extra_array.push(obj1);
            //         }
            //       }

            //       obj["attributes"] = produse_extra_array;
            //       produse_array.push(obj);
            //     }

            //     this.produse_array_cos = produse_array;
            //     axios.post('https://xpanel.tikibistro.ro/api/add-order', {
            //         full_name: this.nume_user+' '+this.prenume_user,
            //         address: this.adresa_generala,
            //         address_description: this.adresa_secundara,
            //         address_lat : this.lat_adresa,
            //         address_lng : this.lng_adresa,
            //         phone: this.telefon_comanda,
            //         products:produse_array,
            //         with_bonus: false,
            //         birth_date: "",
            //         takeaway: this.takeaway,
            //         voucher_code : this.code_voucher,
            //         country_code : this.cod_tara,
            //         payment_method : 'card',
            //         note : this.informatii_comanda,
            //         order_cuttery : this.tacamuri_check,
            //         vivaOrderId : s,
            //         vivaTransactionId : t
            //     }, {
            //       headers: {
            //         "Access-Control-Allow-Origin" : "*",
            //         "Authorization": `Bearer ${token}`, 
            //       },
            //       withCredentials: false,
            //     }).then((succes) => {
            //         this.$store.commit('seteazaTipTranzactie',1);
            //         this.$store.state.total_pret_cos = 0;
            //         this.pret_final_comanda = 0;
            //         this.cosCumparaturi.produse = [];
            //         this.code_voucher = "";
            //         this.voucher_status_id = 0;
            //         this.voucher_type = 0;
            //         this.voucher_value = 0;
            //         this.voucher_currency = '';
            //         this.total_voucher = 0;
            //         this.eroare_comanda_minima = "";
            //         this.eroare_comanda_minima_en = "";
            //         this.informatii_comanda = '';
            //         this.golireSessionStorage();
                    
            //       }, (error) => {
            //         this.$store.commit('seteazaTipTranzactie',0);
            //         this.$store.commit('seteazaEroare',error.response.data.errors[0]);
            //         var informatii = {
            //           full_name: this.nume_user+' '+this.prenume_user,
            //           address: this.adresa_generala,
            //           address_description: this.adresa_secundara,
            //           address_lat : this.lat_adresa,
            //           address_lng : this.lng_adresa,
            //           phone: this.telefon_comanda,
            //           products:produse_array,
            //           with_bonus: false,
            //           birth_date: "",
            //           takeaway: this.takeaway,
            //           voucher_code : this.code_voucher,
            //           country_code : this.cod_tara,
            //           payment_method : 'card',
            //           note : this.informatii_comanda,
            //           order_cuttery : this.tacamuri_check
            //         };
            //         var json = JSON.stringify(error.response.data);
            //         json += JSON.stringify(informatii);
            //         json += JSON.stringify(localStorage);

            //         var actiune = 'https://xpanel.tikibistro.ro/api/add-order#comanda care a trecut prin viva wallet, t:'+t+', s:'+s;
            //         this.trimiteLog(actiune,json); 
            //     });  

            //   }, (error) => {

            //   });   
            // }
        },


        golireSessionStorage() {
          localStorage.removeItem('id_adresa');
          localStorage.removeItem('informatii_comanda');
          localStorage.removeItem('tacamuri_check');
          localStorage.removeItem('voucher');
          localStorage.removeItem('telefon_comanda');
          localStorage.removeItem('cod_tara');
          localStorage.removeItem('durata_livrare');
        },
        test_comanda_plata() {
            var token = localStorage.token;

            if(token == '')
            {
              this.$methods.logout()
              localStorage.removeItem('token')
            }
            else
            {

              
              axios.get('https://xpanel.tikibistro.ro/api/profile?t='+Number(new Date()),  { 
                withCredentials: false,
                headers: {
                  "Access-Control-Allow-Origin" : "*",
                  "Content-type": "Application/json",
                  "Authorization": `Bearer ${token}`
                }      
              })
              .then((response) => {

                this.address_id = localStorage.getItem("id_adresa");
                this.informatii_comanda = localStorage.getItem("informatii_comanda");
                this.tacamuri_check = localStorage.getItem("tacamuri_check");
                this.code_voucher = localStorage.getItem("voucher");

                response.data.data.address.forEach(adresa_finala => { 
                  if(this.address_id == adresa_finala.address_id)
                  {
                    if(this.address_id == 0)
                    {
                      this.takeaway = true;
                    }
                    else
                    {
                      this.takeaway = false;
                    }

                    this.adresa_selectata.prima_adresa = adresa_finala.address;
                    this.adresa_selectata.adresa_2 = adresa_finala.description;
                    this.adresa_generala = adresa_finala.address;
                    this.adresa_secundara = adresa_finala.description;
                    this.lat_adresa = adresa_finala.address_lat;
                    this.lng_adresa = adresa_finala.address_lng;
                  }  
                })
                
                this.telefon_comanda = localStorage.getItem("telefon_comanda");
                this.cod_tara = localStorage.getItem("cod_tara");
                this.nume_user = response.data.data.first_name;
                this.prenume_user = response.data.data.last_name;

                //daca informatiile despre client sunt corecte, trimitem comanda

                var produse_array = [];
                for(var i=0; i<this.cosCumparaturi.produse.length;i++)
                {
                  var produse_extra_array = [];
                  var obj = {};
                  obj["id"] = this.cosCumparaturi.produse[i].id_produs;
                  obj["quantity"] = this.cosCumparaturi.produse[i].cantitate_produs;
                  obj["note"] = this.cosCumparaturi.produse[i].optiuni_extra_produs[0]  + ' ' + this.cosCumparaturi.produse[i].nota_produs_individual;
                  obj["half"] = false; 
                  obj["child"] = false;
                  obj["external_id"] = this.cosCumparaturi.produse[i].optiuni_extra_produs[1];

                  if(this.cosCumparaturi.produse[i].produse_extra)
                  {
                    for(var j=0; j<this.cosCumparaturi.produse[i].produse_extra.length;j++)
                    {
                        var obj1 = {};
                        obj1["id"] = this.cosCumparaturi.produse[i].produse_extra[j][2];
                        obj1["name_ro"] = this.cosCumparaturi.produse[i].produse_extra[j][0];
                        obj1["quantity"] = this.cosCumparaturi.produse[i].cantitate_produs; 
                        produse_extra_array.push(obj1);
                    }
                  }

                  obj["attributes"] = produse_extra_array;
                  produse_array.push(obj);
                }

                this.produse_array_cos = produse_array;
               

              }, (error) => {

              });   
            }
        },


        calculeazaTransportGetArea(latitudine, longitudine, adresa, total){

          
         // this.calculeazaVoucher(this.voucher_type,total,this.voucher_value);
          var total_gen = 0;
          if(this.pret_dupa_voucher_fara_livrare != 0)
              total_gen = this.pret_dupa_voucher_fara_livrare;
          else
          {
            this.calculeazaVoucher(this.voucher_type,total,this.voucher_value,this.voucher_categories,this.voucher_products);

            if(this.pret_dupa_voucher_fara_livrare != 0)
                total_gen = this.pret_dupa_voucher_fara_livrare;
            else
                total_gen = this.total_pret_cos;
                
          }
              

          var token = localStorage.token;

            if(token == '')
            {
              this.$methods.logout()
              localStorage.removeItem('token')
            }
            else
            {
             axios.post('https://xpanel.tikibistro.ro/api/get-area', {
              address: adresa,
              sum: total_gen,
              address_lat: latitudine,
              address_lng: longitudine,

              }, {
                headers: {
                  "Access-Control-Allow-Origin" : "*",
                  "Authorization": `Bearer ${token}`, 
                },
                withCredentials: false,
              }).then((succes) => {
                  this.pret_livrare_general = parseFloat(succes.data.data.price);
                  this.durata_livrare = succes.data.data.delivery_time;
                  this.pret_livrare = parseFloat(succes.data.data.price);
                  this.comanda_minima_livrare_gratuita = parseFloat(succes.data.data.min_command_free_zone);
                  this.comanda_minima = parseFloat(succes.data.data.min_command);
                  
                }).catch(error => {
                   var json = JSON.stringify(error);

                    var actiune = 'https://xpanel.tikibistro.ro/api/get-area'+' DATE:'+adresa+','+total_gen+','+latitudine+','+longitudine;
                    this.trimiteLog(actiune,json); 
              });
          }
        },
        preiaInformatiiUser() {
            var token = localStorage.token;
            if(token == '')
            {
              this.$methods.logout()
              localStorage.removeItem('token')
            }
            else
            {
              axios.get('https://xpanel.tikibistro.ro/api/profile?t='+Number(new Date()),  { 
                withCredentials: false,
                headers: {
                  "Access-Control-Allow-Origin" : "*",
                  "Content-type": "Application/json",
                  "Authorization": `Bearer ${token}`
                }      
              })
              .then((response) => {   
                // if(this.adresa_selectata.prima_adresa == '' && response.data.data.address[0].address_id != 0)
                // {
                //   this.adresa_selectata.prima_adresa = response.data.data.address[0].address;
                //   this.adresa_selectata.adresa_2 = response.data.data.address[0].description;
                //   this.address_id = response.data.data.address[0].address_id;
                //   this.adresa_generala = this.adresa_selectata.prima_adresa;
                //   this.adresa_secundara = this.adresa_selectata.adresa_2;
                //   this.lat_adresa = response.data.data.address[0].address_lat;
                //   this.lng_adresa = response.data.data.address[0].address_lng;     
                // }

                

                if(response.data.data.phone == "")
                {
                    this.$refs.modal_telefon_null.open()
                }

                if(!this.telefon_comanda)
                {
                  this.telefon_comanda = response.data.data.phone;
                }
                this.cod_tara = response.data.data.country_code;
                this.nume_user = response.data.data.first_name;
                this.telefon_comanda_nefinalizat = response.data.data.phone;
                this.cod_tara_nefinalizat = response.data.data.country_code;
                this.prenume_user = response.data.data.last_name;
                this.email = response.data.data.email;
                


                var total_gen = 0;
                if(this.pret_dupa_voucher_fara_livrare != 0)
                    total_gen = this.pret_dupa_voucher_fara_livrare;
                else
                    total_gen = this.total_pret_cos;

                if(this.address_id == "0")
                {
                  this.pret_livrare_general = 0;
                  this.pret_livrare = 0;
                }
                else
                {
                  if(this.lat_adresa != '' && this.lng_adresa != '')
                    this.calculeazaTransportGetArea(this.lat_adresa, this.lng_adresa, this.adresa_generala, total_gen);
                } 
               //this.calculeazaVoucher(this.voucher_type,total_gen,this.voucher_value);

                
              }, (error) => {
              });   
            }
        },
        generareProduseCos() {
            var produse_array = [];
            for(var i=0; i<this.cosCumparaturi.produse.length;i++)
            {
              var produse_extra_array = [];
              var obj = {};
              obj["id"] = this.cosCumparaturi.produse[i].id_produs;
              obj["quantity"] = this.cosCumparaturi.produse[i].cantitate_produs;
              obj["note"] = this.cosCumparaturi.produse[i].optiuni_extra_produs[0]  + ' ' + this.cosCumparaturi.produse[i].nota_produs_individual;
              obj["half"] = false; 
              obj["child"] = false;
              obj["external_id"] = this.cosCumparaturi.produse[i].optiuni_extra_produs[1];

              if(this.cosCumparaturi.produse[i].produse_extra)
              {
                for(var j=0; j<this.cosCumparaturi.produse[i].produse_extra.length;j++)
                {
                    var obj1 = {};
                    obj1["id"] = this.cosCumparaturi.produse[i].produse_extra[j][2];
                    obj1["name_ro"] = this.cosCumparaturi.produse[i].produse_extra[j][0];
                    obj1["quantity"] = this.cosCumparaturi.produse[i].cantitate_produs; 
                    produse_extra_array.push(obj1);
                }
              }
              obj["attributes"] = produse_extra_array;
              produse_array.push(obj);
            }
            this.produse_array_cos = produse_array;
        },
        afisare_modal_produs(id,$event) {
          
            var clase;
            window.location.hash = "p-";
            if($event == '')
               clase = [];
            else
               clase = $event.target.className;
            //this.loading = 1;
            if(clase.indexOf('heart_absolute') < 0){
              axios.get('https://xpanel.tikibistro.ro/api/products/getProductById/'+id+'?t='+Number(new Date()), {
                withCredentials: false,
              })
              .then((response) => {   
                if(response.data.data[0] != undefined)
                { 
                  this.nota_produs_individual = "";
                  this.nume_produs = response.data.data[0].additional.name_ro;
                  this.descriere_produs = response.data.data[0].additional.description_ro; 
                  this.nume_produs_en = response.data.data[0].additional.name_en;
                  this.descriere_produs_en = response.data.data[0].additional.description_en;
                  this.preparing_time = response.data.data[0].preparing_time;
                  this.discount_procent = response.data.data[0].discount;
                  this.discount_price_final = response.data.data[0].price_final;
                  this.categorii_standard_produs = response.data.data[0].categories;
                  
                  if(response.data.data[0].images.length > 0)
                  {
                    this.array_imagini_produs =response.data.data[0].images;
                    this.imagine_produs = response.data.data[0].main_image[0].resizedpath;
                  }
                  else
                  {
                    this.array_imagini_produs = [];
                    this.imagine_produs = '/img/about_us_1.1c8c627b.png';
                  }
                  this.pret = response.data.data[0].price;
                  this.alergeni_en = response.data.data[0].alergeni_en;
                  this.alergeni_ro = response.data.data[0].alergeni_ro;
                  this.ingrediente_extra = response.data.data[0].attributes;
                  this.optiuni_extra = response.data.data[0].options;
                  this.id_produs = response.data.data[0].id; 
                  this.external_id = response.data.data[0].options[0].external_id;
                  this.optiuni_extra_produs = [response.data.data[0].options[0].text,response.data.data[0].options[0].external_id]
                  //this.loading = 0;
                  window.location.hash = "p-"+response.data.data[0].id;
                  if(this.ingrediente_extra.length > 0 || (clase.indexOf('cumpara_box') < 0 && clase.indexOf('ri-add-line') < 0))
                  {
                    this.$refs.modal_produs.open();
                  }   
                  else
                    this.adaugaProdusInCos();
                
                }
              }, (error) => {
                this.loading = 0;

                var json = JSON.stringify(error);
                var actiune = 'https://xpanel.tikibistro.ro/api/products/getProductById/'+id;
                this.trimiteLog(actiune,json); 
              });   
            }
        },
        afisare_modal_produs_comanda_recurenta(product) {    
          
          this.product_id = product.product_id;  
          
          axios.get('https://xpanel.tikibistro.ro/api/products/getProductById/'+this.product_id+'?t='+Number(new Date()), {
            withCredentials: false,
          })
          .then((response) => {   
            if(response.data.data[0] != undefined)
            { 
              this.count = product.quantity;
              this.nota_produs_individual = product.note;


              this.produse_extra = [];
              if(product.attributes.length > 0)
                this.selectExtra(product.attributes[0].name_ro,parseFloat(product.attributes[0].price),product.area_id,this.product_id);
              

              this.nume_produs = response.data.data[0].additional.name_ro;
              this.descriere_produs = response.data.data[0].additional.description_ro; 
              this.nume_produs_en = response.data.data[0].additional.name_en;
              this.descriere_produs_en = response.data.data[0].additional.description_en;
              this.preparing_time = response.data.data[0].preparing_time;
              this.discount_procent = response.data.data[0].discount;
              this.discount_price_final = response.data.data[0].price_final;
              
              if(response.data.data[0].images.length > 0)
              {
                this.array_imagini_produs =response.data.data[0].images;
                this.imagine_produs = response.data.data[0].main_image[0].resizedpath;
              }
              else
              {
                this.array_imagini_produs = [];
                this.imagine_produs = '/img/about_us_1.1c8c627b.png';
              }
              this.pret = response.data.data[0].price;
              this.alergeni_en = response.data.data[0].alergeni_en;
              this.alergeni_ro = response.data.data[0].alergeni_ro;
              this.ingrediente_extra = response.data.data[0].attributes;
              this.optiuni_extra = response.data.data[0].options;
              this.id_produs = response.data.data[0].id; 
              this.external_id = response.data.data[0].options[0].external_id;
              this.optiuni_extra_produs = [response.data.data[0].options[0].text,response.data.data[0].options[0].external_id]
              
              this.adaugaProdusInCos();
            
            }
          }, (error) => {
            this.loading = 0;

            var json = JSON.stringify(error);
            var actiune = 'https://xpanel.tikibistro.ro/api/products/getProductById/'+this.product_id;
            this.trimiteLog(actiune,json); 
          });   
            
        },
        trimiteLog(actiune,json) {
          var token = localStorage.token;
          if(token != undefined)
          {
            axios.post('https://xpanel.tikibistro.ro/api/add-log', {
                action: actiune,
                description: json,
            }, {
              headers: {
                "Access-Control-Allow-Origin" : "*",
                "Authorization": `Bearer ${token}`, 
              },
              withCredentials: false,
            }).then((response) => {
                
              }, (error) => {
              });  
          }
          else 
          {
            console.log('neconectat');
          }
        },
        calculPretLivrare() {
          var token =localStorage.token;
           axios.get('https://xpanel.tikibistro.ro/api/profile?t='+Number(new Date()),  { 
            withCredentials: false,
            headers: {
              "Access-Control-Allow-Origin" : "*",
              "Content-type": "Application/json",
              "Authorization": `Bearer ${token}`
            }      
          })
          .then((response) => {
            var total_cos = this.total_pret_cos;
            
            if(this.address_id != 0)
            {
              if(total_cos >= this.comanda_minima_livrare_gratuita)
              {
                this.pret_livrare  = 0;
              }
              else
              {
                if(this.adresa_selectata.prima_adresa)
                {  
                  this.pret_livrare  = response.data.data.address[0].price;
                }
              }  
            }
            else
            {
              this.pret_livrare = 0;
            }
          }, (error) => {
          });   
        },
        updatePhoneNumber(data) {
          if(data.isValid == true)
          {
            this.telefon_valid = true;
          }
          else
          {
            this.telefon_valid = false;
          }
          if(String(data.nationalNumber).charAt(0) == "0" && data.nationalNumber.length == 10)
            this.telefon_comanda_nefinalizat = Number(data.nationalNumber.substring(1));
          else
            this.telefon_comanda_nefinalizat = data.nationalNumber;
            this.cod_tara_nefinalizat =  "+"+data.countryCallingCode;         
        },
        comanda_recurenta() {
          
          this.detalii_comanda.products.forEach(product => {    
              this.afisare_modal_produs_comanda_recurenta(product);
          })    

        },
        reseteazaParola() {
          var parola = $('.parola_noua').val();
          var parola1 = $('.parola_noua1').val();
          var err = false;


          if(parola == '' || parola1 == '')
          {
              err = true;
              this.eroare_generala = 'Completați toate câmpurile';
              this.eroare_generala_en = 'Complete all fields';
          }
          if(parola != parola1 && (parola != '' && parola1 != ''))
          {
              err = true;
              this.eroare_generala = 'Parolele nu corespund';
              this.eroare_generala_en = 'The password are not the same';
          }

          if(err == false)
          {
            this.eroare_generala = '';
            this.eroare_generala_en = '';
            
            axios.post('https://xpanel.tikibistro.ro/api/user/change_password', {
                password: parola,   
            }, {
              headers: {
                "Access-Control-Allow-Origin" : "*",
                "Authorization": `Bearer ${localStorage.token}`, 
              },
              withCredentials: false,
            }).then((succes) => {
                $('.parola_noua').val('');
                $('.parola_noua1').val('');
                $('.eroare_reset').addClass('response_succes');
                this.eroare_generala = 'Parola a fost schimbată cu succes';
                this.eroare_generala_en = 'The password was changed';
              }).catch(error => {
                this.eroare_generala = 'A apărut o eroare';
                this.eroare_generala_en = 'An error has been occurred';

                var json = JSON.stringify(error);

                var actiune = 'https://xpanel.tikibistro.ro/api/user/change_password'+' DATE:'+parola;
                this.trimiteLog(actiune,json); 
            });
          }

        },
        trimiteComandaApi(produse_array) {
          if(this.$store.state.status_bucatarie != '0')
          {
              this.loading = 1;
              var token = localStorage.token;
              var sts = '';
              var pm = '';
              if(this.address_id == 0)
                this.takeaway = true;

              if(this.value_plata == 2)
              {
                pm = 'card';
                sts = 'pending';
              }
              else {
                pm = 'cash';
                sts = '';
              }

              axios.post('https://xpanel.tikibistro.ro/api/add-order-status', {
                full_name: this.nume_user+' '+this.prenume_user,
                address: this.adresa_generala,
                address_description: this.adresa_secundara,
                phone: this.telefon_comanda,
                products:produse_array,
                with_bonus: false,
                birth_date: "",
                takeaway: this.takeaway,
                voucher_code : this.code_voucher,
                country_code : this.cod_tara,
                address_lat : this.lat_adresa,
                address_lng : this.lng_adresa,
                payment_method : pm,
                status : sts,
                note : this.informatii_comanda,
                order_cuttery : this.tacamuri_check
            }, {
              headers: {
                "Access-Control-Allow-Origin" : "*",
                "Authorization": `Bearer ${token}`, 
              },
              withCredentials: false,
            }).then((succes) => {
              
              console.log(succes.data);
              var oid = succes.data.data.order_id;

                if(this.value_plata == 1)
                {
                  this.$store.commit('seteazaTipTranzactie',1);
                  $(".confirma_comanda_activ").attr("disabled", false);
                  $('.modal_start_checkout').hide();
                  $('.modal_6_checkout').fadeIn(100);
                  this.$store.state.total_pret_cos = 0;
                  this.pret_final_comanda = 0;
                  this.loading = 0;
                  this.cosCumparaturi.produse = [];
                  this.code_voucher = "";
                  this.voucher_status_id = 0;
                  this.voucher_type = 0;
                  this.voucher_value = 0;
                  this.voucher_currency = '';
                  this.total_voucher = 0;
                  this.eroare_comanda_minima = "";
                  this.eroare_comanda_minima_en = "";
                  this.informatii_comanda = '';
                  this.golireSessionStorage();
                }
                else if(this.value_plata == 2)
                {
                  axios.post('https://xpanel.tikibistro.ro/api/payment/addOrder?t='+Number(new Date()), {
                      amount: this.pret_final_comanda * 100,
                      name: this.nume_user+' '+this.prenume_user,
                      email: this.email,   
                      description :"",
                      oid : oid
                  }, {
                    headers: {
                      "Access-Control-Allow-Origin" : "*",
                    },
                    withCredentials: false,
                  }).then((successAddOrder) => {
                      $(this).attr("disabled", false);
                      this.transaction_id = successAddOrder.data;  

                      var json = JSON.stringify(successAddOrder);
                      var actiune = 'https://www.vivapayments.com/web/checkout?ref='+successAddOrder.data;
                      this.trimiteLog(actiune,json);

                      //GOLESC TOT DACA E OK
                      this.$store.state.total_pret_cos = 0;
                      this.pret_final_comanda = 0;
                      this.loading = 0;
                      this.cosCumparaturi.produse = [];
                      this.code_voucher = "";
                      this.voucher_status_id = 0;
                      this.voucher_type = 0;
                      this.voucher_value = 0;
                      this.voucher_currency = '';
                      this.total_voucher = 0;
                      this.eroare_comanda_minima = "";
                      this.eroare_comanda_minima_en = "";
                      this.informatii_comanda = '';
                      //this.golireSessionStorage();
                       
                      window.location.replace('https://www.vivapayments.com/web/checkout?ref='+successAddOrder.data);

                    }, (error) => {
                      $(this).attr("disabled", false);

                      alert(error.response.data.errors[0]);
                      var json = JSON.stringify(error.response.data.errors[0]);

                      var actiune = 'https://xpanel.tikibistro.ro/api/payment/addOrder'+' DATE:'+this.pret_final_comanda * 100+', '+this.email;
                      this.trimiteLog(actiune,json);
                  });  
                }
                
              }).catch(error => {
                alert(error.response.data.errors[0]);
                this.$store.commit('seteazaTipTranzactie',0);
                $(".confirma_comanda_activ").attr("disabled", false);
                this.loading = 0;

                var json = JSON.stringify(error.response.data.errors[0]);

                  var actiune = 'https://xpanel.tikibistro.ro/api/add-order-status'+' DATE:'+this.nume_user+' '+this.prenume_user+', '+this.adresa_generala+', '+this.adresa_secundara+', '+this.telefon_comanda+', '+produse_array+', '+this.code_voucher+', '+this.cod_tara+', '+this.lat_adresa+', '+this.lng_adresa;
                  this.trimiteLog(actiune,json); 
            });
          }
          else
          { 
              $(".confirma_comanda_activ").attr("disabled", false);
              alert('Bucătăria este închisă. Vă rugăm reveniți mai târziu!');     
              var actiune = 'Trimitere comanda cu bucatarie inchisa';
              var json = JSON.stringify('https://xpanel.tikibistro.ro/api/add-order-status'+' Bucatarie inchisa | DATE:'+this.nume_user+' '+this.prenume_user+', '+this.adresa_generala+', '+this.adresa_secundara+', '+this.telefon_comanda+', '+produse_array+', '+this.code_voucher+', '+this.cod_tara+', '+this.lat_adresa+', '+this.lng_adresa);
              this.trimiteLog(actiune,json); 
          }   
        },
         adaugaProdusFavoritInCos(id) {
            axios.get('https://xpanel.tikibistro.ro/api/products/getProductById/'+id+'?t='+Number(new Date()), {
              withCredentials: false
            })
            .then((response) => {
              this.nume_produs = response.data.data[0].additional.name_ro;
              this.descriere_produs = response.data.data[0].additional.description_ro;
              this.nume_produs_en = response.data.data[0].additional.name_en;
              this.descriere_produs_en = response.data.data[0].additional.description_en;
              this.imagine_produs = response.data.data[0].images[0].path;
              this.pret = response.data.data[0].price;
              this.ingrediente_extra = response.data.data[0].attributes;
              this.optiuni_extra = response.data.data[0].options;
              this.id_produs = response.data.data[0].id; 
              this.external_id = response.data.data[0].options[0].external_id;
              let produs_cos = {
                id_produs : this.id_produs,
                external_id: this.external_id,
                nume_produs: this.nume_produs,
                descriere_produs : this.descriere_produs,
                nume_produs_en: this.nume_produs_en,
                descriere_produs_en : this.descriere_produs_en,
                poza_produs : this.imagine_produs,
                produse_extra: [],
                cantitate_produs : 1,
                pret_produs: this.pret,
                total_produs: this.pret
              }
               if(this.cosCumparaturi.produse.some(id_pr => id_pr.id_produs === produs_cos.id_produs))
                {
                  let i=0;
                  var produsul_nu_exista = true;
                  for(i=0;i<this.cosCumparaturi.produse.length;i++)
                  {
                      if(this.cosCumparaturi.produse[i].id_produs === produs_cos.id_produs && JSON.stringify(this.cosCumparaturi.produse[i].produse_extra) == JSON.stringify(this.produse_extra))
                      {
                        this.cosCumparaturi.produse[i].cantitate_produs = this.cosCumparaturi.produse[i].cantitate_produs + produs_cos.cantitate_produs;
                        produsul_nu_exista = false;
                      }
                  }
                  if(produsul_nu_exista == true)
                  {
                    this.cosCumparaturi.produse.push(produs_cos);
                  }
                }
                else
                {
                  this.cosCumparaturi.produse.push(produs_cos);
                }

               if(/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
                    $('.modal_confirmare').animate({ "right": "10px"}, "slow");
                  } else{
                      $('.modal_confirmare').animate({ "right": "20px"}, "slow");
                  }
                setTimeout(function(){
                  if(/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
                    $('.modal_confirmare').animate({"right": "-1000px"}, "slow");
                  } else{
                    $('.modal_confirmare').animate({"right": "-100vw"}, "slow");
                  }
                }, 2000);
             

            }, (error) => {
            }); 


           
         }, 
        deleteItem(index) {
          this.$delete(this.cosCumparaturi.produse,index)
        },
        vezi_contul_profilului() {
          $('.menu_item_profil').toggleClass('menu_item_profil_activ');
          $('.meniu_profil_ascuns').fadeToggle(100);
          $('.sageata_block').toggleClass('sageata_none');
        },
        
        deleteItemFavorit(index,id_produs) {
          var token = localStorage.token;
          this.$delete(this.produse_favorite_state_full,index)
          var that = this;
          axios.post('https://xpanel.tikibistro.ro/api/favorite', {
              product_id: id_produs,
              status: 0,
          }, {
            headers: {
              "Access-Control-Allow-Origin" : "*",
              "Authorization": `Bearer ${token}`, 
            },
            withCredentials: false,
          }).then((response) => {

              if(/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
                  $('.modal_scos_de_la_favorite').animate({ "right": "10px"}, "slow");
                } else{
                    $('.modal_scos_de_la_favorite').animate({ "right": "20px"}, "slow");
                }
              setTimeout(function(){
                if(/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
                  $('.modal_scos_de_la_favorite').animate({"right": "-1000px"}, "slow");
                } else{
                  $('.modal_scos_de_la_favorite').animate({"right": "-100vw"}, "slow");
                }
              }, 2000);
              axios.get('https://xpanel.tikibistro.ro/api/favorite?t='+Number(new Date()),  { 
                withCredentials: false,
                headers: {
                  "Access-Control-Allow-Origin" : "*",
                  "Content-type": "Application/json",
                  "Authorization": `Bearer ${token}`
                }      
              })
              .then((response) => {
                that.produse_favorite_state = [];
                $.each(response.data.data,function(i,produs){
                    that.produse_favorite_state.push(produs.id);
                });   
              }, (error) => {

              }); 
            }, (error) => {
              var json = JSON.stringify(error);

              var actiune = 'https://xpanel.tikibistro.ro/api/favorite'+' DATE:';
              this.trimiteLog(actiune,json); 
             });   
        },
        deleteItemCount(cantitate,index)
        {
          if(cantitate > 0)
          {
            return true;
          }
          else
          {
            this.$delete(this.cosCumparaturi.produse,index)
          }

        },
        vezi_dw_profil() {
            $('.modal_profilul_meu_date').fadeToggle(100);
            $('.i_inactiv').toggleClass('i_activ');
        },
        changeLocale(locale) {
            i18n.locale = locale;
            localStorage.Lang=locale;
        },
        ...mapActions(["LogIn"]),
        async submit() {
          const User = new FormData();
          User.append("email", this.form.email);
          User.append("password", this.form.password);
          try {
             await this.LogIn(User);
              //this.$router.push("/");
              if(this.$store.eroare_generala_bool == true)
              {
                this.showError = true
              }
              else
              {


                this.produse_favorite_state = [];
                this.produse_favorite_state_full = [];
                var token = localStorage.token;
                  var t = this;
                  if(token){
                      if(token == '')
                      {
                        localStorage.removeItem('token')
                      } 
                      else
                      {  
                        axios.get('https://xpanel.tikibistro.ro/api/favorite?t='+Number(new Date()),  { 
                          withCredentials: false,
                          headers: {
                            "Access-Control-Allow-Origin" : "*",
                            "Authorization": `Bearer ${token}`
                          }      
                        })
                        .then((response) => {   
                          $.each(response.data.data,function(i,produs){
                              t.produse_favorite_state.push(produs.id);
                              t.produse_favorite_state_full.push(produs);
                          });
                          
                          this.$store.commit('seteazaProduseFavorite',t.produse_favorite_state);

                        }, (error) => {
                        });   
                      }
                  }  



                this.showError = false
              }
          }
           catch (error) {
            this.showError = true
            console.log(error);
          }
        },
        ...mapActions(["Register"]),
        async submit_register() {
          try {
            await this.Register(this.form_register);
            this.$router.push("/");
            this.showError = false
          } catch (error) {
            this.showError = true
          }
        },
        async logout() {
          this.produse_favorite_state = [];
          localStorage.removeItem('token');
          this.$store.commit('seteazaProduseFavorite',[]);
          this.cod_tara = "";
          this.telefon_comanda = "";
          this.telefon_comanda_nefinalizat = "";
          this.cod_tara_nefinalizat = "";
          this.form_register.email = "";
          this.form_register.password = "";
          await this.$store.dispatch("LogOut");
          //this.$router.push("/");
          
        },
    },
   
    beforeMount() {


      

      this.$store.commit('seteazaIdApp',server_config.id_app);

      if(window.location.hostname == 'test.tikibistro.ro' || window.location.hostname == '192.168.1.80' )
        this.url_delivery = 1;
      this.$store.commit('seteazaEroare','');
      //this.test_comanda_plata();

      this.actualizareTotalCos();

      axios.get('https://xpanel.tikibistro.ro/api/kitchen?t='+Number(new Date()),  { 
          withCredentials: false,
          headers: {
          "Access-Control-Allow-Origin" : "*"
          }      
      })
      .then((response) => {   
        
          var status_buc = response.data.status;
          status_buc = status_buc.toString().replace(/\s/g, '');
          this.$store.commit('seteazaStatusBucatarie',status_buc);
      }, (error) => {
          this.$store.commit('seteazaStatusBucatarie',1);
      }); 


      setInterval(() => {
        
         axios.get('https://xpanel.tikibistro.ro/api/kitchen?t='+Number(new Date()),  { 
              withCredentials: false,
              headers: {
              "Access-Control-Allow-Origin" : "*"
              }      
          })
          .then((response) => {   
              var status_buc = response.data.status;
              status_buc = status_buc.toString().replace(/\s/g, '');
              this.$store.commit('seteazaStatusBucatarie',status_buc);
          }, (error) => {
              this.$store.commit('seteazaStatusBucatarie',1);
          }); 
      }, 30000);
     
      $(function() { 
        $(".nr_tel_general").keyup(function () { 
            if (this.value.length == 1) {
              $(this).next('.nr_tel_general').focus();
            }
        });

        $('.nr_tel_general').keydown(function(e) {
            if ((e.which == 8 || e.which == 46) && $(this).val() =='') {
                $(this).prev('.nr_tel_general').focus();
            }
        });
          

          $('.telefon_profil').on('keypress', function(key) {
              if(key.charCode < 48 || key.charCode > 57) {
                return false;
              }
          });
      });


      axios.get('https://xpanel.tikibistro.ro/api/config?t='+Number(new Date()),  { 
        withCredentials: false,
        headers: {
          "Access-Control-Allow-Origin" : "*",
          "Authorization": `Bearer ${token}`
        }      
      })
      .then((response) => {   
        //this.comanda_minima = parseFloat(response.data[0][0].general.min_command);
        //this.comanda_minima_livrare_gratuita = parseFloat(response.data[0][0].general.min_command_free_zone);
        this.msg_status_Closed_en = response.data[0][0].general.msg_status_Closed_en;
        this.msg_status_Closed_ro = response.data[0][0].general.msg_status_Closed_ro;
        this.msg_status_Fast_en = response.data[0][0].general.msg_status_Fast_en;
        this.msg_status_Fast_ro = response.data[0][0].general.msg_status_Fast_ro;
        this.msg_status_Manual_closed_en = response.data[0][0].general.msg_status_Manual_closed_en;
        this.msg_status_Manual_closed_ro = response.data[0][0].general.msg_status_Manual_closed_ro;
      }, (error) => {
        console.log(error);
      });  
      
      this.produse_favorite_state = [];
      this.produse_favorite_state_full = [];
      var token = localStorage.token;
        var t = this;
        if(token){
            if(token == '')
            {
              localStorage.removeItem('token')
            } 
            else
            {  
              axios.get('https://xpanel.tikibistro.ro/api/favorite?t='+Number(new Date()),  { 
                withCredentials: false,
                headers: {
                  "Access-Control-Allow-Origin" : "*",
                  "Authorization": `Bearer ${token}`
                }      
              })
              .then((response) => {   
                $.each(response.data.data,function(i,produs){
                    t.produse_favorite_state.push(produs.id);
                    t.produse_favorite_state_full.push(produs);
                });
                
                this.$store.commit('seteazaProduseFavorite',t.produse_favorite_state);

              }, (error) => {
                if(error.response.data.errors[0] == 'Token invalid')
                {
                  t.$store.dispatch("LogOut")
                  localStorage.removeItem('token')
                }
              });   
            }
        }

      axios.get('https://xpanel.tikibistro.ro/api/categories?t='+Number(new Date()),{ 
          withCredentials: false,
          params: { location: this.$store.state.location_shop }
        }) 
        .then(response => { 
            var array_categorii = [];
            response.data.data.forEach(category => { 
              if((category.products.length > 0 && category.slug != 'test' && this.url_delivery == 0) || (category.products.length > 0 && this.url_delivery == 1))
                  array_categorii.push(category);
            })
            this.categorii = array_categorii;

            this.categorii.forEach((value) => {
                value.products.forEach((produs) => {
                  var obj = {};
                  if(produs.images[0] != undefined)
                  {
                    this.array_produse.push({'id_produs' :produs.id , 'poza_produs' : produs.images[0].thumbnailpath }) 
                  }
                  else
                  {
                    this.array_produse.push({'id_produs' :produs.id , 'poza_produs' : "/img/logo-tiki-bistro.1c5fcf96.png" })   
                  } 
                });
            });

            if(window.location.hash.indexOf("p-") >= 0 && window.location.hash != 'p-')
            {
              
                var slug_prod = window.location.hash.split("p-");
                var slug_prod_final = slug_prod[1];

               if(slug_prod_final != '')
                this.afisare_modal_produs(slug_prod_final,'');
              
            }
        }) 
      
         
        //  $('body').on('click','.logo ',function(){
        //    $("html").scrollTop(0);
        // });
        $('body').on('click','.box_medota_plata',function(){
            $('.box_medota_plata').removeClass('box_medota_plata_activ');
            $(this).addClass('box_medota_plata_activ');
            that.metoda_de_plata = $(this).attr('tip_plate');
            that.metoda_de_plata_en = $(this).attr('tip_plate_en');
            that.value_plata = parseInt($(this).attr('value_plata'));

            if(that.metoda_de_plata !='' || that.metoda_de_plata_en !='')
            {
              $('.modal_start_checkout').fadeIn(100);
              $('.sweet-box-actions').fadeIn(100);
              $('.modal_5_checkout').hide();
            }
        });
        var that = this;
        $('body').on('click','.vezi_produse_favorite',function(){
          that.produse_favorite_state = [];
          that.produse_favorite_state_full = [];
          that.end_call = false;  
          var token = localStorage.token;

          if(token == '')
          {
            that.$methods.logout()
            localStorage.removeItem('token')
          }
          else
          {   

            axios.get('https://xpanel.tikibistro.ro/api/favorite?t='+Number(new Date()),  { 
              withCredentials: false,
              headers: {
                "Access-Control-Allow-Origin" : "*",
                "Authorization": `Bearer ${token}`
              }      
            })
            .then((response) => {   
              $.each(response.data.data,function(i,produs){
                  that.produse_favorite_state.push(produs.id);
                  that.produse_favorite_state_full.push(produs);
              });
              
              that.$store.commit('seteazaProduseFavorite',that.produse_favorite_state);
              that.end_call = true;

            }, (error) => {
            console.log(error);
            }); 
          }  
      });

      $('body').on('click','.vezi_comenzi_btn',function(){
        var token = localStorage.token;
        that.final_axios = 0;

        if(token == '')
        {
          this.$methods.logout()
          localStorage.removeItem('token')
        }
        else
        {
          axios.get('https://xpanel.tikibistro.ro/api/orders?t='+Number(new Date()),  { 
            withCredentials: false,
            headers: {
              "Access-Control-Allow-Origin" : "*",
              "Content-type": "Application/json",
              "Authorization": `Bearer ${token}` 
            }      
          })
          .then((response) => {

            if(response.data.data.length > 0)
              that.comenzi = response.data.data;
              that.final_axios = 1;
             
          }, (error) => {
          console.log(error);
          });   
        } 
      });

      $('body').on('click','.inchide_popup',function(){
          $('.sweet-action-close').click();
      });
      $('body').on('click','.vezi_adresele',function(){
        var token = localStorage.token;

        if(token == '')
        {
          this.$methods.logout()
          localStorage.removeItem('token')
        }
        else
        {
          
          axios.get('https://xpanel.tikibistro.ro/api/profile?t='+Number(new Date()),  { 
            withCredentials: false,
            headers: {
              "Access-Control-Allow-Origin" : "*",
              "Content-type": "Application/json",
              "Authorization": `Bearer ${token}`
            }      
          })
          .then((response) => {
            that.adrese_user = response.data.data.address;
          }, (error) => {
          console.log(error);
          });   
        }
      });

     
      var eroare = this;
       $('body').on('click','.save_address_activ',function(){
         $(this).attr("disabled", true);
        var token = localStorage.token;
        eroare.eroare_adauga_adresa_noua = "";

        if(token == '')
        {
          this.$methods.logout()
          localStorage.removeItem('token')
        }
        else
        {
          if($('#pac-input-adresa-noua').val() != '')
          {
            var adresa_noua = $('#pac-input-adresa-noua').val();
            var descriere_adresa_noua = $('.descriere_adresa_noua').val();

           if(that.lat_adresa_add_old == "" && that.lng_adresa_add_old == "")
          {
              eroare.eroare_adauga_adresa_noua = "Completează strada și numărul și selectează adresa ta din Google Maps";
              $(this).attr("disabled", false);
          } 
          else
          {
            axios.post('https://xpanel.tikibistro.ro/api/user/add_address', {
                    address : adresa_noua,
                    description : descriere_adresa_noua,
                    address_lat : that.lat_adresa,
                    address_lng : that.lng_adresa
                }, {
                  headers: {
                    "Access-Control-Allow-Origin" : "*",
                    "Authorization": `Bearer ${token}`, 
                  },
                  withCredentials: false,
                }).then((response) => {
                  axios.get('https://xpanel.tikibistro.ro/api/profile?t='+Number(new Date()),  { 
                      withCredentials: false,
                      cache: false,
                      headers: {
                        "Access-Control-Allow-Origin" : "*",
                        "Content-type": "Application/json",
                        "Authorization": `Bearer ${token}`
                      }      
                    })
                    .then((response) => {
                      that.adrese_user = response.data.data.address;   
                      $('.modal_adrese_adauga').hide();
                      $('.modal_adrese_editeaza').hide();
                      $('.modal_adrese_start').fadeIn(100);
                      $('.save_address').removeClass('save_address_activ');
                      $('.sweet-box-actions').fadeIn(100);
                      $(this).attr("disabled", false);
                      that.lat_adresa_add_old = "";
                      that.lng_adresa_add_old = "";


                      if(/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
                        $('.adresa_adaugata').animate({ "right": "10px"}, "slow");
                      } else{
                          $('.adresa_adaugata').animate({ "right": "20px"}, "slow");
                      }
                      setTimeout(function(){
                        if(/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
                          $('.adresa_adaugata').animate({"right": "-1000px"}, "slow");
                        } else{
                          $('.adresa_adaugata').animate({"right": "-100vw"}, "slow");
                        }
                      }, 2000);

                      setTimeout(function(){
                        location.reload();  
                      },2000);
                      
                    }, (error) => {
                    console.log(error);
                      $(this).attr("disabled", false);
                    }); 
                    
                    
                }, (error) => {
                  $(this).attr("disabled", false);
                  that.eroare_adauga_adresa_noua = error.response.data.errors[0];

                  var json = JSON.stringify(error.response.data.errors[0]);

                  var actiune = 'https://xpanel.tikibistro.ro/api/user/add_address'+' DATE:'+adresa_noua+', '+descriere_adresa_noua+', '+that.lat_adresa+', '+that.lng_adresa;
                  this.trimiteLog(actiune,json); 

                });  
            } 
          }
          else
          {
              that.eroare_adauga_adresa_noua = 'Adresă invalidă - vă rugăm adăugați și numarul străzii';
              $(this).attr("disabled", false);
              that.adresa_selectata.prima_adresa = '';
              that.preiaInformatiiUser();
              $("#pac-input-adresa-noua").focus();
              $("#pac-input-adresa-noua").val($("#pac-input-adresa-noua").val());
          }
        }
        
      });

      

      $('body').on('click','.icon_edit_profil .ri-edit-line',function(){
          $(this).closest('.box_general_profil').find('input').focus();
      }); 

      $('body').on('click','.edit_address_activ',function(){
        var token = localStorage.token;
        var id_adresa = $(this).attr('id_adresa');
        eroare.eroare_adauga_adresa_editata = "";

        if(token == '')
        {
          this.$methods.logout()
          localStorage.removeItem('token')
        }
        else
        {
          var adresa = $('.adresa_editata').val();
          var descriere_adresa = $('.descriere_adresa_editata').val();

          console.log(eroare);
          

        if(eroare.adresa_edit != adresa && that.lat_adresa_edit_old == eroare.lat_adresa_edit && that.lng_adresa_edit_old == eroare.lng_adresa_edit)
        {
            eroare.eroare_adauga_adresa_editata = "Completează strada și numărul și selectează adresa ta din Google Maps";
        }
        else
        {
            axios.post('https://xpanel.tikibistro.ro/api/user/edit_address', {
                    address_id : id_adresa,
                    address : adresa,
                    description : descriere_adresa,
                    address_lat : eroare.lat_adresa_edit,
                    address_lng : eroare.lng_adresa_edit
                }, {
                  headers: {
                    "Access-Control-Allow-Origin" : "*",
                    "Authorization": `Bearer ${token}`, 
                  },
                  withCredentials: false,
                }).then((response) => {
                    axios.get('https://xpanel.tikibistro.ro/api/profile?t='+Number(new Date()),  { 
                      withCredentials: false,
                      headers: {
                        "Access-Control-Allow-Origin" : "*",
                        "Content-type": "Application/json",
                        "Authorization": `Bearer ${token}`
                      }      
                    })
                    .then((response) => {
                      that.adrese_user = response.data.data.address;  
                    }, (error) => {
                    console.log(error);
                    }); 
                    // $('.modal_adrese_adauga').hide();
                    // $('.modal_adrese_editeaza').hide();
                    // $('.modal_adrese_start').fadeIn(100);
                    // $('.edit_address').removeClass('edit_address_activ');
                    // $('.sweet-box-actions').fadeIn(100);
                    alert('Adresa a fost modificata cu succes.');
                    location.reload();
                }, (error) => {
                  eroare.eroare_adauga_adresa_editata = error.response.data.errors[0];
                });       
            } 
        }
      });
      

     

      // $(window).scroll(function() {
      //     var windscroll = $(window).scrollTop();
      //     if (windscroll >= 100) {
      //         $('.padding_text_container').each(function(i) {
      //             if ($(this).position().top <= windscroll - 20) {
      //                 $('.box_categorie.cat_act').removeClass('cat_act');
      //                 $('.box_categorie').eq(i).addClass('cat_act');  
      //                 var el = $('.slic_vue_overflox_x');
      //                 console.log(el.offsetLeft);

                
      //             }
      //         });
      //     } else {
      //         $('.box_categorie.cat_act').removeClass('cat_act');
      //         $('.box_categorie:first').addClass('cat_act');
      //     }
      // }).scroll();

       
      
       $('body').on('click','.delete_address',function(){
        var token = localStorage.token;
        var id_adresa = $(this).attr('id_adresa');

        if(token == '')
        {
          this.$methods.logout()
          localStorage.removeItem('token')
        }
        else
        {
          
         axios.post('https://xpanel.tikibistro.ro/api/user/delete_address', {
                address_id : id_adresa,
            }, {
              headers: {
                "Access-Control-Allow-Origin" : "*",
                "Authorization": `Bearer ${token}`, 
              },
              withCredentials: false,
            }).then((response) => {
               axios.get('https://xpanel.tikibistro.ro/api/profile?t='+Number(new Date()),  { 
                  withCredentials: false,
                  headers: {
                    "Access-Control-Allow-Origin" : "*",
                    "Content-type": "Application/json",
                    "Authorization": `Bearer ${token}`
                  }      
                })
                .then((response) => {
                  that.adrese_user = response.data.data.address;
                }, (error) => {
                console.log(error);
                }); 
                $('.modal_adrese_adauga').hide();
                $('.modal_adrese_editeaza').hide();
                $('.modal_adrese_start').fadeIn(100);
                $('.sweet-box-actions').fadeIn(100);
            }, (error) => {
              console.log(error.response.data.errors);

            });   
        }        
      });
      
      $('body').on('click','.heart_absolute',function(){
          if(localStorage.token)
          {
              var token = localStorage.token;
              var id_produs = $(this).attr('dataid');
              var status_produs = $(this).attr('datastatus');
              axios.post('https://xpanel.tikibistro.ro/api/favorite', {
                  product_id: $(this).attr('dataid'),
                  status: $(this).attr('datastatus'),
              }, {
                headers: {
                  "Access-Control-Allow-Origin" : "*",
                  "Authorization": `Bearer ${token}`, 
                },
                withCredentials: false,
              }).then(() => {
                var array_fav = [];
                array_fav = that.produse_favorite_state;
                if(status_produs == 0)
                {
                  array_fav = array_fav.filter(value => value !== Number(id_produs));
        


                  if(/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
                        $('.modal_scos_de_la_favorite').animate({ "right": "10px"}, "slow");
                      } else{
                          $('.modal_scos_de_la_favorite').animate({ "right": "20px"}, "slow");
                      }
                    setTimeout(function(){
                      if(/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
                        $('.modal_scos_de_la_favorite').animate({"right": "-1000px"}, "slow");
                      } else{
                        $('.modal_scos_de_la_favorite').animate({"right": "-100vw"}, "slow");
                      }
                    }, 2000);

                }
                else
                {
                   array_fav.push(Number(id_produs));

                    if(/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
                        $('.modal_adaugat_la_favorite').animate({ "right": "10px"}, "slow");
                      } else{
                          $('.modal_adaugat_la_favorite').animate({ "right": "20px"}, "slow");
                      }
                    setTimeout(function(){
                      if(/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
                        $('.modal_adaugat_la_favorite').animate({"right": "-1000px"}, "slow");
                      } else{
                        $('.modal_adaugat_la_favorite').animate({"right": "-100vw"}, "slow");
                      }
                    }, 2000);
                  

                   
                }


                  that.produse_favorite_state = array_fav;
                
              }, (error) => {
                  console.log(error);
              });
          }
          else
          {
            $('.click_autentificare').click();
          }   
      });
      
      $('body').on('click','.vezi_adresa',function(){
            that.adaugare_adresa_editata();
            var id_adresa = $(this).attr('address_id');
            that.adresa_edit = "";
            that.eroare_adauga_adresa_editata = "";
            that.id_adresa_edit = "";
            that.descriere_adresa_edit= "";
            var token = localStorage.token;
            that.lat_adresa_edit = '';
            that.lng_adresa_edit = '';
            that.lat_adresa_edit_old = '';
            that.lng_adresa_edit_old = '';

          if(token == '')
          {
            this.$methods.logout()
            localStorage.removeItem('token')
          }
          else
          {
            
            axios.get('https://xpanel.tikibistro.ro/api/profile?t='+Number(new Date()),  { 
              withCredentials: false,
              headers: {
                "Access-Control-Allow-Origin" : "*",
                "Content-type": "Application/json",
                "Authorization": `Bearer ${token}`
              }      
            })
            .then((response) => {
              for(var i=0; i<response.data.data.address.length;i++)
              {
                if(response.data.data.address[i].address_id == id_adresa)
                {
                  that.id_adresa_edit = response.data.data.address[i].address_id;
                  that.adresa_edit = response.data.data.address[i].address;
                  that.descriere_adresa_edit= response.data.data.address[i].description;
                  that.lat_adresa_edit = response.data.data.address[i].address_lat;
                  that.lng_adresa_edit = response.data.data.address[i].address_lng;
                  that.lat_adresa_edit_old = response.data.data.address[i].address_lat;
                  that.lng_adresa_edit_old = response.data.data.address[i].address_lng;
                }
              }
              
              
              $('.modal_adrese_start').hide();
              $(this).closest('.sweet-modal').find('.sweet-box-actions').hide();
              $('.modal_adrese_editeaza').show();
              
                
            }, (error) => {
            console.log(error);
            
            });   
          }
          
        });
        

        $(document).mouseup(function(e) 
        {
            var container = $("input");

            // if the target of the click isn't the container nor a descendant of the container
            if (!container.is(e.target) && container.has(e.target).length === 0) 
            {
                container.blur();
            }
        });


        $('body').on('click','.get_informatii',function(){ 
          that.preiaInformatiiUser();  
          that.adresa_selectata.prima_adresa = "";
          that.adresa_selectata.adresa_2 = "";
          that.address_id = 0;
          that.adresa_generala = "";
          that.adresa_secundara = "";
          that.lat_adresa = "";
          that.lng_adresa = "";
          that.value_plata = 0;
          that.metoda_de_plata = '';
          that.metoda_de_plata_en = '';
          that.stergeVoucher();        

        });
        $('body').on('click','.vezi_setari',function(){
           
            var token = localStorage.token;

            if(token == '')
            {
              this.$methods.logout()
              localStorage.removeItem('token')
            }
            else
            {
              
              axios.get('https://xpanel.tikibistro.ro/api/profile?t='+Number(new Date()),  { 
                withCredentials: false,
                headers: {
                  "Access-Control-Allow-Origin" : "*",
                  "Content-type": "Application/json",
                  "Authorization": `Bearer ${token}`
                }      
              })
              .then((response) => {
                if(that.adresa_selectata.prima_adresa == '')
                {
                  that.adresa_selectata.prima_adresa = response.data.data.address[0].address;
                  that.adresa_selectata.adresa_2 = response.data.data.address[0].description;
                  that.address_id = response.data.data.address[0].address_id;
                  that.adresa_generala = that.adresa_selectata.prima_adresa;
                  that.adresa_secundara = that.adresa_selectata.adresa_2;
                  
                  that.durata_livrare = response.data.data.address[0].delivery_time;
                }
                
                  
              }, (error) => {
              console.log(error);
              });   
            }
             
            });
        
       
        
        $('body').on('click','.box_general_setari_limba',function(){
               if($('.setari_limba_box').hasClass('setari_limba_box_activ'))
                {
                    $('.sus').hide();
                    $('.jos').fadeIn(100);
                    $('.setari_limba_box').removeClass('setari_limba_box_activ');
                }
                else
                {
                    $('.jos').hide();
                    $('.sus').fadeIn(100);
                    $('.setari_limba_box').addClass('setari_limba_box_activ');
                }
            
        });
        


      
    
    //  $('body').on('click','.imagine_autentificat',function(){
    //    that.preiaInformatiiUser();
    // });

      
     $('body').on('click','.vezi_comanda',function(){
      var token = localStorage.token;

        if(!token)
        {
          this.$methods.logout()
          localStorage.removeItem('token')
        }
        else
        {
          var id_comanda = $(this).attr('id_comanda');
          axios.get('https://xpanel.tikibistro.ro/api/orders/'+id_comanda+'?t='+Number(new Date()),  { 
            withCredentials: false,
            headers: {
              "Access-Control-Allow-Origin" : "*",
              "Content-type": "Application/json",
              "Authorization": `Bearer ${token}`
            }      
          })
          .then((response) => {
            $(this).closest('.sweet-modal').find('.sweet-box-actions').hide();
            $('.order_modal_start').hide();
            $('.order_modal_2').fadeIn(100);
            
            that.detalii_comanda = response.data.data[0];
          }, (error) => {
          console.log(error);

          });   
        }

    });


     $('body').on('click','.vezi_profil_autentificat',function(){

       that.preiaInformatiiUser();
      // var token = localStorage.token;

      //   if(!token)
      //   {
      //     this.$methods.logout()
      //     localStorage.removeItem('token')
      //   }
      //   else
      //   {
          
      //     axios.get('https://xpanel.tikibistro.ro/api/profile?t='+Number(new Date()),  { 
      //       withCredentials: false,
      //       headers: {
      //         "Access-Control-Allow-Origin" : "*",
      //         "Content-type": "Application/json",
      //         "Authorization": `Bearer ${token}`
      //       }      
      //     })
      //     .then((response) => {
      //       that.cod_tara = response.data.data.country_code;
      //       that.nume_user = response.data.data.first_name;
      //       that.prenume_user= response.data.data.last_name;
      //       that.email = response.data.data.email;
      //       that.telefon = response.data.data.phone;
      //       that.telefon_comanda = response.data.data.phone;
      //       that.telefon_comanda_nefinalizat = response.data.data.phone;
      //       that.cod_tara_nefinalizat = response.data.data.country_code;

      //     }, (error) => {
      //     console.log(error);

      //     });   
      //   }

    });
    
    var eroare_editare = this;
     $('body').on('click','.save_profil',function(){
       $(this).attr("disabled", true);
       var token = localStorage.token;

       var nume_profil = $('.nume_profil').val();
       var prenume_profil = $('.prenume_profil').val();
       var email_profil = $('.email_profil').val();
       var telefon_profil = eroare_editare.telefon_comanda;


       axios.post('https://xpanel.tikibistro.ro/api/profile', {
            first_name : nume_profil,
            last_name : prenume_profil,
            phone : telefon_profil,
            email : email_profil,
            country_code : eroare_editare.cod_tara,
        }, {
          headers: {
            "Access-Control-Allow-Origin" : "*",
            "Authorization": `Bearer ${token}`, 
          },
          withCredentials: false,
        }).then(() => {
           $(this).attr("disabled", false);
          $('.sweet-action-close').click();
          if(/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
            $('.modal_editare_profil').animate({ "right": "10px"}, "slow");
          } else{
              $('.modal_editare_profil').animate({ "right": "20px"}, "slow");
          }
        setTimeout(function(){
          if(/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
            $('.modal_editare_profil').animate({"right": "-1000px"}, "slow");
          } else{
            $('.modal_editare_profil').animate({"right": "-100vw"}, "slow");
          }
        }, 2000);
          eroare_editare.showError_editare = false;
        }, (error) => {
          $(this).attr("disabled", false);
          console.log(error);
          eroare_editare.showError_editare = true;
          var json = JSON.stringify(error);

          var actiune = 'https://xpanel.tikibistro.ro/api/profile'+' DATE:'+nume_profil+', '+prenume_profil+', '+telefon_profil+', '+email_profil+', '+eroare_editare.cod_tara;
          this.trimiteLog(actiune,json);
        }); 
    });
      var user = this;
      $('body').on('click','.confirma_comanda_activ',function(){
        user.eroare_comanda_minima = "";
        user.eroare_comanda_minima_en = "";

        var cos_total_cu_reducere ;
        var comanda_ok = false;


        axios.get('https://xpanel.tikibistro.ro/api/kitchen?t='+Number(new Date()),  { 
            withCredentials: false,
            headers: {
            "Access-Control-Allow-Origin" : "*"
            }      
        })
        .then((response) => {   
            var status_buc = response.data.status;
            status_buc = status_buc.toString().replace(/\s/g, '');
            user.$store.commit('seteazaStatusBucatarie',status_buc);
        }, (error) => {
            user.$store.commit('seteazaStatusBucatarie',1);
        }); 


        if(user.$store.state.status_bucatarie == '2')
        {
          var array_produse_fast = [];
          Object.entries(user.cosCumparaturi.produse).forEach(([key, val]) => { 
              if(val.preparing_time == 'Normal')
              {
                comanda_ok = true;
                array_produse_fast.push([val['nume_produs'], val['nume_produs_en']]);
              }
          });
        }

        cos_total_cu_reducere = parseFloat(user.total_pret_cos)

        if(cos_total_cu_reducere >= user.comanda_minima && comanda_ok == false)
        {
          $(this).attr("disabled", true);
          //var token = localStorage.token;
          user.generareProduseCos();

          localStorage.setItem("telefon_comanda", user.telefon_comanda);
          localStorage.setItem("cod_tara", user.cod_tara);
          localStorage.setItem("durata_livrare", user.durata_livrare);
          localStorage.setItem("id_adresa", user.address_id);
          localStorage.setItem("informatii_comanda", user.informatii_comanda);   
          localStorage.setItem("tacamuri_check", user.tacamuri_check);  
          localStorage.setItem("voucher", user.code_voucher); 

          console.log(localStorage);

          if(user.value_plata == 1)
          {
              user.trimiteComandaApi(user.produse_array_cos); 
          }
          else if(user.value_plata == 2)
          {
              // axios.post('https://xpanel.tikibistro.ro/api/payment/addOrder?t='+Number(new Date()), {
              //     amount: user.pret_final_comanda * 100,
              //     name: user.nume_user+' '+user.prenume_user,
              //     email: user.email,   
              //     description :""   
              // }, {
              //   headers: {
              //     "Access-Control-Allow-Origin" : "*",
              //   },
              //   withCredentials: false,
              // }).then((successAddOrder) => {
              //     $(this).attr("disabled", false);
              //     user.transaction_id = successAddOrder.data;  

              //     var json = JSON.stringify(successAddOrder);
              //     var actiune = 'https://www.vivapayments.com/web/checkout?ref='+successAddOrder.data;
              //     user.trimiteLog(actiune,json);
                  
              //     window.location.replace('https://www.vivapayments.com/web/checkout?ref='+successAddOrder.data);

              //   }, (error) => {
              //     $(this).attr("disabled", false);

              //     alert(error.response.data.errors[0]);
              //     var json = JSON.stringify(error.response.data.errors[0]);

              //     var actiune = 'https://xpanel.tikibistro.ro/api/payment/addOrder'+' DATE:'+user.pret_final_comanda * 100+', '+user.email;
              //     user.trimiteLog(actiune,json);

              // });  
              user.trimiteComandaApi(user.produse_array_cos); 
          }
          else
          {
            $(".confirma_comanda_activ").attr("disabled", false);
            user.eroare_comanda_minima = "Selectați metoda de plată.";
            user.eroare_comanda_minima_en = "Select the payment method."; 
          }
        }
        else
        {
          if(cos_total_cu_reducere < user.comanda_minima && user.address_id != 0)
          {  
            user.eroare_comanda_minima = "Comanda minimă este de "+user.comanda_minima+' lei. Transportul nu este inclus.';
            user.eroare_comanda_minima_en = "The minimum order is "+user.comanda_minima+' lei. The transport is not included.';     
          }      
            if(array_produse_fast && array_produse_fast.length > 0)
            {
              var eroare_produs_bucatarie;
              if(user.$i18n.locale == 'ro')
              {
                  eroare_produs_bucatarie = "Produsele urmatoare sunt indisponibile: <br/>"
              }
              else
              {
                  eroare_produs_bucatarie = "This products are not available: <br/>"
              }
              array_produse_fast.forEach(produs => { 
                  if(user.$i18n.locale == 'ro')
                  {
                      eroare_produs_bucatarie += produs[0]+'<br/>'
                  }
                  else
                  {
                      eroare_produs_bucatarie += produs[1]+'<br/>'
                  }
              })
              user.eroare_produs_bucatarie = eroare_produs_bucatarie;
            }
            else
            {
               user.eroare_produs_bucatarie = '';
            }
        }
 
        // axios.post('https://xpanel.tikibistro.ro/api/add-order', {
        //     full_name: user.nume_user+' '+user.prenume_user,
        //     address: user.adresa_generala,
        //     address_description: user.adresa_secundara,
        //     phone: user.telefon_comanda,
        //     products:produse_array,
        //     with_bonus: false,
        //     note: "", 
        //     birth_date: "",
        //     takeaway: false
        // }, {
        //   headers: {
        //     "Access-Control-Allow-Origin" : "*",
        //     "Authorization": `Bearer ${token}`, 
        //   },
        //   withCredentials: false,
        // }).then((succes) => {
        //     console.log(succes);
        //     $('.modal_start_checkout').hide();
        //     $('.sweet-box-actions').hide();
        //      $('.modal_6_checkout').fadeIn(100);
        //      user.cosCumparaturi.produse = [];
        //   }, (error) => {
        //   console.log(error);
        // });  
      });
    },
    updated() {
      if(this.$route.name == 'Meniu' || this.$route.name == 'Bucatarie')
      {
        this.cos_mobile = 1;
      }
      else{
        this.cos_mobile = 0;
      }

      if(this.$store.state.status_bucatarie == 0)
        this.show_modal_bucatarie_inchisa();
    },
    mounted () {
      //this.show_modal_versiune_beta();
    //  window.history.pushState(null, "", window.location.href);
    //   window.onpopstate = function() {
    //       window.location.href();
         
    //   };


      this.verificareLogin();

    

      if(this.pret_livrare == '')
        this.pret_livrare = 0;

      var total_gen = 0;
      if(this.pret_dupa_voucher_fara_livrare != 0)
          total_gen = this.pret_dupa_voucher_fara_livrare;
      else
          total_gen = this.total_pret_cos; 
        
      this.calculeazaVoucher(this.voucher_type,total_gen,this.voucher_value,this.voucher_categories,this.voucher_products);

      initFbsdk();
         $(window).scroll(function() { 
          if($('.meniu_produse2').length)
          { 
            var distance = 0;
            if ($(window).width() > 992) 
              distance = 200;
            else
              distance = 300;
            
            if (  $(window).scrollTop() >= distance) {
              $('.meniu_produse2').addClass('cautare_fixa');
            }
            else
            {
              $('.meniu_produse2').removeClass('cautare_fixa');
            }
          }
        });

      if(localStorage.Lang!=null) this.$i18n.locale=localStorage.Lang;  

      


       var menuOpen = false

        var init = () => {
            $(".menu__toggle, .menu__blackout, .close_menu, .menu_item, .categorii_item, .bottom_item ").on("click", menuToggle)
        }

        var menuToggle = () => {
            $(".menu__blackout").fadeToggle();
            if(menuOpen) { // close menu
                $('html,body').css('cssText', 'overflow-y:auto;');
                $(".menu__collapse").css({
                    left: "-80vw",
                    right: "100vw"
                });
            } else { // open menu
                $('html,body').css('cssText', 'overflow:hidden;');
                $(".menu__collapse").css({
                    left: "0",
                    right: "20vw"
                });
            }

            menuOpen = !menuOpen;
        }
        //  $(document).ready(init);
        $(init);
        var menuCos = false

        var init1 = () => {
            $(".cos_header, .nrproduse_pret, .menu__blackout1, .close_menu1, .logo_cos_mobile ,.buton_cos_general").on("click", menuToggle1)
        }

        var menuToggle1 = () => {

          // var total_gen = 0;
          // if(this.pret_dupa_voucher_fara_livrare != 0)
          //     total_gen = this.pret_dupa_voucher_fara_livrare;
          // else
          //     total_gen = this.total_pret_cos;

          //this.calculeazaVoucher(this.voucher_type,total_gen,this.voucher_value);

            $(".menu__blackout1").fadeToggle();

            if(menuCos) { // close menu
                $('html,body').css('cssText', 'overflow-y:auto;');
               
                $(".menu__collapse1").css({
                    left: "auto",
                    right: "-100vw"
                });
            } else { // open menu
                $('html,body').css('cssText', 'overflow-y:hidden;');
                $(".menu__collapse1").css({
                     left: "auto",
                     right: "0"  
                });
            }

            menuCos = !menuCos;

        }

        // $(document).ready(init1);
         $(init1);
        $('body').on('click','.categorii_item',function(){
            $('.close_menu').click();
            //location.reload();
        });
         $('body').on('click','.limba',function(){
            $(this).next().fadeToggle(100);
        });
        $('body').on('click','.lang_item',function(){
            $(this).parent().hide();
        });

        var variabila_this = this;

        $('body').on('click','.afisare_checkout',function(){
            variabila_this.code_voucher = '';
            variabila_this.voucher_type = 0;
            variabila_this.voucher_value = 0;
            variabila_this.voucher_activ = 0;
            variabila_this.voucher_status_id = 0;
            variabila_this.adresa_a_fost_setata = 0;
            if(variabila_this.durata_livrare == undefined)
              variabila_this.durata_livrare = 0;
        });
        
        $('body').on('click','.inapoi_modal_start_setari',function(){
            $('.content_setari_modal').fadeIn(100);
            $('.sweet-box-actions').fadeIn(100);
            $('.modal_locatii_setari').hide();
        });
        $('body').on('click','.inapoi_modal_comanda_start',function(){
            $('.order_modal_start').fadeIn(100);
            $('.sweet-box-actions').fadeIn(100);
            $('.order_modal_2').hide();
        });



        
        $('body').on('click','.schimba_adresa_setari',function(){
            $('.content_setari_modal').hide();
            $(this).closest('.sweet-modal').find('.sweet-box-actions').hide();
            $('.modal_locatii_setari').fadeIn(100);
            
            var token = localStorage.token;
 
            if(token == '')
            {
              this.$methods.logout()
              localStorage.removeItem('token')
            }
            else
            { 
              
              axios.get('https://xpanel.tikibistro.ro/api/profile?t='+Number(new Date()),  { 
                withCredentials: false,
                headers: {
                  "Access-Control-Allow-Origin" : "*",
                  "Content-type": "Application/json",
                  "Authorization": `Bearer ${token}`
                }      
              })
              .then((response) => {

                variabila_this.adrese_user = response.data.data.address;

                
                  
              }, (error) => {
              console.log(error);
              });   
            }
        });
        $('body').on('click','.completeaza_adresa',function(){
            variabila_this.eroare_completare_adresa_nr = "";
            variabila_this.eroare_completare_adresa_nr_en = "";
            $('#pac-input').val('');



            $('.modal_start_checkout').hide();
            $(this).closest('.sweet-modal').find('.sweet-box-actions').hide();
            $('.modal_2_checkout').fadeIn(100);
            variabila_this.vm.searchPlace="";
            variabila_this.searchResults = [];
            variabila_this.location_gmaps = "";

           
            
            var token = localStorage.token;

            if(token == '')
            {
              this.$methods.logout()
              localStorage.removeItem('token')
            }
            else
            {
              
              axios.get('https://xpanel.tikibistro.ro/api/profile?t='+Number(new Date()),  { 
                withCredentials: false,
                headers: {
                  "Access-Control-Allow-Origin" : "*",
                  "Content-type": "Application/json",
                  "Authorization": `Bearer ${token}`
                }      
              })
              .then((response) => {

                variabila_this.adrese_user = response.data.data.address;

                
                  
              }, (error) => {
              console.log(error);
              });   
            }
        });

        $('body').on('click','.selecteaza_metoda_plata',function(){
            $('.modal_start_checkout').hide();
            $(this).closest('.sweet-modal').find('.sweet-box-actions').hide();
            $('.modal_5_checkout').fadeIn(100);
            
        });
        var addr = this;
        
         $('body').on('click','.adresa_pt_livrare_setari',function(){
              var address_id = $(this).attr('address_id');
             
              
              addr.adresa_selectata.prima_adresa = $(this).find('.adresa_user_livrare').attr('adresa_user_livrare');
 
              addr.adresa_selectata.adresa_2 = $(this).find('.adresa_descriere_livrare').attr('adresa_descriere_livrare');
              addr.adresa_generala = addr.adresa_selectata.prima_adresa;
              addr.adresa_secundara = addr.adresa_selectata.adresa_2;

              

              addr.address_id = address_id;
              addr.lat_adresa = $(this).attr('address_lat');
              addr.lng_adresa = $(this).attr('address_lng');

              var total_gen = 0;
              if(addr.pret_dupa_voucher_fara_livrare != 0)
                  total_gen = addr.pret_dupa_voucher_fara_livrare;
              else
                  total_gen = addr.total_pret_cos;

              if(address_id == '0')
              {
                addr.pret_livrare_general = 0;
                addr.pret_livrare = 0;
              }
              else
              {
                
                addr.calculeazaTransportGetArea(addr.lat_adresa, addr.lng_adresa, addr.adresa_selectata.prima_adresa, total_gen);
              }
              $('.modal_locatii_setari').hide();
              $('.sweet-box-actions').fadeIn(100);
              $('.content_setari_modal').fadeIn(100);
        });

        $('body').on('click','.adresa_pt_livrare',function(){
              var address_id = $(this).attr('address_id');
              addr.adresa_selectata.prima_adresa = $(this).find('.adresa_user_livrare').attr('adresa_user_livrare');
              addr.adresa_selectata.adresa_2 = $(this).find('.adresa_descriere_livrare').attr('adresa_descriere_livrare');
              addr.adresa_generala = addr.adresa_selectata.prima_adresa;
              addr.adresa_secundara = addr.adresa_selectata.adresa_2;
              addr.adresa_a_fost_setata = 1;
              addr.durata_livrare = $(this).attr('address_minute');

              addr.lat_adresa = $(this).attr('address_lat');
              addr.lng_adresa = $(this).attr('address_lng');

              var total_gen = 0;
              if(addr.pret_dupa_voucher_fara_livrare != 0) 
                  total_gen = addr.pret_dupa_voucher_fara_livrare;
              else
                  total_gen = addr.total_pret_cos;

              if(address_id == '0')
              {
                addr.pret_livrare_general = 0;
                addr.pret_livrare = 0;
                addr.comanda_minima = 0;
                addr.calculeazaVoucher(addr.voucher_type,total_gen,addr.voucher_value,this.voucher_categories,this.voucher_products);
              }
              else
              {             
                addr.calculeazaTransportGetArea(addr.lat_adresa, addr.lng_adresa, addr.adresa_selectata.prima_adresa, total_gen);
                
              }
              addr.address_id = address_id;

              $('.modal_2_checkout').hide();
              $('.sweet-box-actions').fadeIn(100);
              $('.modal_start_checkout').fadeIn(100);
        });

        if (window.location.href.indexOf("paid?t=") > -1) {
          var url_string = window.location.href;
          var url = new URL(url_string);
          var t = url.searchParams.get("t");
          var s = url.searchParams.get("s");

          this.checkout_id = s;
          this.transaction_id = t;

          if(t != '' && s != '')
          {
            axios.post('https://xpanel.tikibistro.ro/api/payment/processTransaction?t='+Number(new Date()), {
                order: s,
                transaction: t   
            }, {
              headers: {
                "Access-Control-Allow-Origin" : "*",
              },
              withCredentials: false,
            }).then((success) => {
                console.log(success); 
                console.log(localStorage);
                if(success.data == "Transaction registered") 
                {
                  //this.trimiteComandaFinalaPlatita(t,s);
                  this.$store.commit('seteazaTipTranzactie',1);
 
                  this.$store.state.durata_livrare = localStorage.getItem("durata_livrare");
                
                  this.golireSessionStorage();
                }
                else
                {
                  alert('eroare tranzactie');
                }
              }, (error) => {
                this.$store.commit('seteazaTipTranzactie',0);
                var informatii = {'s' : s,'t' : t};
                var json = JSON.stringify(error.response.data);
                json += JSON.stringify(informatii);
                var actiune = 'https://xpanel.tikibistro.ro/api/payment/processTransaction'+' DATE:'+s+', '+t;
                this.trimiteLog(actiune,json);
            });  
          }
        }

         $('body').on('click','.adauga_adresa_noua',function(){
           $('#pac-input-adresa-noua').val('');
           $('.descriere_adresa_noua').val('');
           $('.save_address').removeClass('save_address_activ');
            $('.modal_adrese_start').hide();
            $(this).closest('.sweet-modal').find('.sweet-box-actions').hide();
            $('.modal_adrese_adauga').fadeIn(100);  
            that.adaugare_adresa_noua();
        });
        $('body').on('click','.inapoi_modal_start_adrese',function(){
            $('.modal_adrese_adauga').hide();
             $('.modal_adrese_editeaza').hide();
            
            $('.sweet-box-actions').fadeIn(100);
            $('.modal_adrese_start').fadeIn(100); 
            

        });

        
        $('body').on('click','.autentif_email',function(){
            $('.modal_start').hide();
            $('.modal6').fadeIn(100);  
            
        });
        $('body').on('click','.mergi_la_verificare_cod',function(){
            $(this).attr("disabled", true);

           
            that.eroare_telefon_ro = "";
            that.eroare_telefon_en = "";

            if( that.telefon_comanda_nefinalizat != '')
            {
              if(that.telefon_valid == true)
              {
                $('.modal6').hide();
                $('.modal2').fadeIn(100); 
                $(this).attr("disabled", false);
                that.form_register.phone =  that.telefon_comanda_nefinalizat;
                that.form_register.country_code = that.cod_tara_nefinalizat;
                that.eroare_telefon_ro = "";
                that.eroare_telefon_en = "";
              }
              else
              {
                $(this).attr("disabled", false);
                that.eroare_telefon_ro = 'Numarul de telefon nu este valid.';
                that.eroare_telefon_en = 'This number is invalid.';
              }
            }
            else
            {
              $(this).attr("disabled", false);
              that.eroare_telefon_ro = 'Introduceți numărul de telefon.';
              that.eroare_telefon_en = 'Insert your phone number.';
            }

            
            // axios.post('https://xpanel.tikibistro.ro/api/user/checkPhone', {
            //     phone : that.telefon_comanda,
            //     country_code : that.cod_tara
            // }, {
            //   headers: {
            //     "Access-Control-Allow-Origin" : "*",
            //   },
            //   withCredentials: false,
            // }).then((response) => {
            //   if(response.data.Status == "OK")
            //   {
            //     that.eroare_telefon_ro = "";
            //     that.eroare_telefon_en = "";
            //     $('.modal6').hide();
            //     $('.modal7').fadeIn(100); 
            //     $('.nr_tel_general').val('');

            //     setTimeout(function(){
            //         $('.cod_neprimit').fadeIn(100);
            //     }, 10000);
                
            //   }
            //   $(this).attr("disabled", false);
            // }, (error) => {
            //   $(this).attr("disabled", false);
            //   that.eroare_telefon_ro = 'Numarul de telefon nu este valid.';
            //   that.eroare_telefon_en = 'This number is invalid';
            // });  
          });


        $('body').on('click','.mergi_la_verificare_cod_profil',function(){
            $(this).attr("disabled", true);

           
            that.eroare_telefon_ro = "";
            that.eroare_telefon_en = "";

            if(that.telefon_comanda_nefinalizat != '')
            {

              if(that.telefon_valid == true)
              {
                  axios.post('https://xpanel.tikibistro.ro/api/user/checkPhone?t='+Number(new Date()), {
                    phone :  that.telefon_comanda_nefinalizat,
                    country_code : that.cod_tara_nefinalizat
                }, {
                  headers: {
                    "Access-Control-Allow-Origin" : "*",
                  },
                  withCredentials: false,
                }).then((response) => {
                    if(response.data.Status == "OK")
                    {
                  

                        $('.modal_telefon_general').hide();
                        $('.modal_verificare_telefon').fadeIn(100);
                        $(this).attr("disabled", false);
                        that.form_register.phone =   that.telefon_comanda_nefinalizat.replace(/\s/g, '');
                        that.eroare_telefon_ro = "";
                        that.eroare_telefon_en = "";
                        $('.nr_tel_general').val("");

                      setTimeout(function(){
                          $('.cod_neprimit').fadeIn(100);
                      }, 10000);
                      
                    }
                    $(this).attr("disabled", false);
                    }, (error) => {
                    $(this).attr("disabled", false);
                    that.eroare_telefon_ro = 'Numarul de telefon nu este valid.';
                    that.eroare_telefon_en = 'This number is invalid';

                    var json = JSON.stringify(error);
                    var actiune = 'https://xpanel.tikibistro.ro/api/user/checkPhone'+' DATE:'+that.telefon_comanda_nefinalizat+', '+that.cod_tara_nefinalizat;
                    this.trimiteLog(actiune,json); 

                }); 
              }
                else
              {
                $(this).attr("disabled", false);
                that.eroare_telefon_ro = 'Numarul de telefon nu este valid.';
                that.eroare_telefon_en = 'This number is invalid.';
              } 
              
            }
            else
            {
              $(this).attr("disabled", false);
              that.eroare_telefon_ro = 'Introduceți numărul de telefon.';
              that.eroare_telefon_en = 'Insert your phone number.';
            }

            
            // axios.post('https://xpanel.tikibistro.ro/api/user/checkPhone', {
            //     phone : that.telefon_comanda,
            //     country_code : that.cod_tara
            // }, {
            //   headers: {
            //     "Access-Control-Allow-Origin" : "*",
            //   },
            //   withCredentials: false,
            // }).then((response) => {
            //   if(response.data.Status == "OK")
            //   {
            //     that.eroare_telefon_ro = "";
            //     that.eroare_telefon_en = "";
            //     $('.modal6').hide();
            //     $('.modal7').fadeIn(100); 
            //     $('.nr_tel_general').val('');

            //     setTimeout(function(){
            //         $('.cod_neprimit').fadeIn(100);
            //     }, 10000);
                
            //   }
            //   $(this).attr("disabled", false);
            // }, (error) => {
            //   $(this).attr("disabled", false);
            //   that.eroare_telefon_ro = 'Numarul de telefon nu este valid.';
            //   that.eroare_telefon_en = 'This number is invalid';
            // });  
          });



        $('body').on('click','.complete_tlf_activ',function(){
            $(this).attr("disabled", true);

            
           
            that.eroare_telefon_ro = "";
            that.eroare_telefon_en = "";

            if(that.telefon_comanda_nefinalizat != '')
            {

              if(that.telefon_valid == true)
              {
                  axios.post('https://xpanel.tikibistro.ro/api/user/checkPhone?t='+Number(new Date()), {
                    phone :  that.telefon_comanda_nefinalizat,
                    country_code : that.cod_tara_nefinalizat
                }, {
                  headers: {
                    "Access-Control-Allow-Origin" : "*",
                  },
                  withCredentials: false,
                }).then((response) => {
                    if(response.data.Status == "OK")
                    {
                        $('.modal_4_checkout_verificare_telefon').fadeIn(100);
                        $('.modal_4_checkout').hide();
                        $(this).attr("disabled", false);
                        that.form_register.phone =  that.telefon_comanda_nefinalizat.replace(/\s/g, '');
                        that.eroare_telefon_ro = "";
                        that.eroare_telefon_en = "";

                      setTimeout(function(){
                          $('.cod_neprimit').fadeIn(100);
                      }, 10000);
                      
                    }
                    $(this).attr("disabled", false);
                  }, (error) => {
                      $(this).attr("disabled", false);
                      that.eroare_telefon_ro = 'Numarul de telefon nu este valid.';
                      that.eroare_telefon_en = 'This number is invalid';

                      var json = JSON.stringify(error);
                      var actiune = 'https://xpanel.tikibistro.ro/api/user/checkPhone'+' DATE:'+that.telefon_comanda_nefinalizat+', '+that.cod_tara_nefinalizat;
                      that.trimiteLog(actiune,json); 
                });
             }
              else
              {
                $(this).attr("disabled", false);
                that.eroare_telefon_ro = 'Numarul de telefon nu este valid.';
                that.eroare_telefon_en = 'This number is invalid.';
              }  
              
            }
            else
            {
              $(this).attr("disabled", false);
              that.eroare_telefon_ro = 'Introduceți numărul de telefon.';
              that.eroare_telefon_en = 'Insert your phone number.';
            }

            
            // axios.post('https://xpanel.tikibistro.ro/api/user/checkPhone', {
            //     phone : that.telefon_comanda,
            //     country_code : that.cod_tara
            // }, {
            //   headers: {
            //     "Access-Control-Allow-Origin" : "*",
            //   },
            //   withCredentials: false,
            // }).then((response) => {
            //   if(response.data.Status == "OK")
            //   {
            //     that.eroare_telefon_ro = "";
            //     that.eroare_telefon_en = "";
            //     $('.modal6').hide();
            //     $('.modal7').fadeIn(100); 
            //     $('.nr_tel_general').val('');

            //     setTimeout(function(){
            //         $('.cod_neprimit').fadeIn(100);
            //     }, 10000);
                
            //   }
            //   $(this).attr("disabled", false);
            // }, (error) => {
            //   $(this).attr("disabled", false);
            //   that.eroare_telefon_ro = 'Numarul de telefon nu este valid.';
            //   that.eroare_telefon_en = 'This number is invalid';
            // });  
          });
           $('body').on('click','.complete_tlf_activ_null',function(){
            $(this).attr("disabled", true);

            that.eroare_telefon_ro = "";
            that.eroare_telefon_en = "";

            if(that.telefon_comanda_nefinalizat != '')
            {

              if(that.telefon_valid == true)
              {

                  axios.post('https://xpanel.tikibistro.ro/api/user/checkPhone?t='+Number(new Date()), {
                    phone :  that.telefon_comanda_nefinalizat,
                    country_code : that.cod_tara_nefinalizat
                }, {
                  headers: {
                    "Access-Control-Allow-Origin" : "*",
                  },
                  withCredentials: false,
                }).then((response) => {
                    if(response.data.Status == "OK")
                    {
                        $('.verificare_telefon_null').fadeIn(100);
                        $('.modal_telefon_null_1').hide();
                        $(this).attr("disabled", false);
                        that.form_register.phone =  that.telefon_comanda_nefinalizat.replace(/\s/g, '');
                        that.eroare_telefon_ro = "";
                        that.eroare_telefon_en = "";

                      setTimeout(function(){
                          $('.cod_neprimit').fadeIn(100);
                      }, 10000);
                      
                    }
                    $(this).attr("disabled", false);
                    }, (error) => {
                    $(this).attr("disabled", false);
                    that.eroare_telefon_ro = 'Numarul de telefon nu este valid.';
                    that.eroare_telefon_en = 'This number is invalid';
                    var json = JSON.stringify(error);
                    var actiune = 'https://xpanel.tikibistro.ro/api/user/checkPhone'+' DATE:'+that.telefon_comanda_nefinalizat+', '+that.cod_tara_nefinalizat;
                    that.trimiteLog(actiune,json); 
                });
             }
              else
              {
                $(this).attr("disabled", false);
                that.eroare_telefon_ro = 'Numarul de telefon nu este valid.';
                that.eroare_telefon_en = 'This number is invalid.';
              }  
              
            }
            else
            {
              $(this).attr("disabled", false);
              that.eroare_telefon_ro = 'Introduceți numărul de telefon.';
              that.eroare_telefon_en = 'Insert your phone number.';
            }
          });
          
        $('body').on('click','.cod_neprimit',function(){
            $(this).attr("disabled", true);

            
            axios.post('https://xpanel.tikibistro.ro/api/user/checkPhone?t='+Number(new Date()), {
                    phone : that.telefon_comanda,
                    country_code : that.cod_tara
                }, {
                  headers: {
                    "Access-Control-Allow-Origin" : "*",
                  },
                  withCredentials: false,
                }).then((response) => {
                  if(response.data.Status == "OK")
                  {
                    if(/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
                        $('.cod_retrimis').animate({ "right": "10px"}, "slow");
                      } else{
                          $('.cod_retrimis').animate({ "right": "20px"}, "slow");
                      }
                    setTimeout(function(){
                      if(/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
                        $('.cod_retrimis').animate({"right": "-1000px"}, "slow");
                      } else{
                        $('.cod_retrimis').animate({"right": "-100vw"}, "slow");
                      }
                    }, 2000);
                    
                  }
                  else{
                    if(/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
                        $('.cod_netrimis').animate({ "right": "10px"}, "slow");
                      } else{
                          $('.cod_netrimis').animate({ "right": "20px"}, "slow");
                      }
                    setTimeout(function(){
                      if(/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
                        $('.cod_netrimis').animate({"right": "-1000px"}, "slow");
                      } else{
                        $('.cod_netrimis').animate({"right": "-100vw"}, "slow");
                      }
                    }, 2000);
                  }
                  $(this).attr("disabled", false);
                }, (error) => {
                  var json = JSON.stringify(error);
                    var actiune = 'https://xpanel.tikibistro.ro/api/user/checkPhone'+' DATE:'+that.telefon_comanda+', '+that.cod_tara;
                    that.trimiteLog(actiune,json); 
                  if(/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
                        $('.cod_netrimis').animate({ "right": "10px"}, "slow");
                      } else{
                          $('.cod_netrimis').animate({ "right": "20px"}, "slow");
                      }
                    setTimeout(function(){
                      if(/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
                        $('.cod_netrimis').animate({"right": "-1000px"}, "slow");
                      } else{
                        $('.cod_netrimis').animate({"right": "-100vw"}, "slow");
                      }
                    }, 2000);
                  $(this).attr("disabled", false);
                });  
        });

        // $('body').on('click','.mergi_la_inregistrare',function(){
        //     $(this).attr("disabled", true);

        //     var nr1_tel = $('.nr1_tel ').val();
        //     var nr2_tel = $('.nr2_tel ').val();
        //     var nr3_tel = $('.nr3_tel ').val();
        //     var nr4_tel = $('.nr4_tel ').val();
        //     var code = nr1_tel+''+nr2_tel+''+nr3_tel+''+nr4_tel;
        //     console.log(code);
        //     that.eroare_cod_telefon = "";
        //     that.eroare_cod_telefon_en = "";
            
        //     axios.post('https://xpanel.tikibistro.ro/api/user/checkPhoneCode', {
        //             phone : that.telefon_comanda,
        //             country_code : that.cod_tara,
        //             code : code
        //         }, {
        //           headers: {
        //             "Access-Control-Allow-Origin" : "*",
        //           },
        //           withCredentials: false,
        //         }).then((response) => {
        //               if(response.data.Status == 'OK')
        //               {
        //                 $('.modal7').hide();
        //                 $('.modal2').fadeIn(100); 
        //                 that.form_register.phone =  that.phone.replace(/\s/g, '');
        //                 that.eroare_cod_telefon = "";
        //                 that.eroare_cod_telefon_en = "";
        //               }
        //               else
        //               {
        //                 that.eroare_cod_telefon = "Codul introdus este invalid. Incercati din nou!";
        //                 that.eroare_cod_telefon_en = "This code is invalid. Please try again!";
        //               }
                      
        //               $(this).attr("disabled", false);

        //         }, (error) => {
        //           $(this).attr("disabled", false);
        //           that.eroare_cod_telefon = "Introduceti codul!";
        //           that.eroare_cod_telefon_en = "Insert the code!";

        //         });  
        // });



        $('body').on('click','.actualizeaza_numar_checkout',function(){
            $(this).attr("disabled", true);

            var nr1_tel = $('.inputuri_cod_telefon_checkout .nr1_tel ').val();
            var nr2_tel = $('.inputuri_cod_telefon_checkout .nr2_tel ').val();
            var nr3_tel = $('.inputuri_cod_telefon_checkout .nr3_tel ').val();
            var nr4_tel = $('.inputuri_cod_telefon_checkout .nr4_tel ').val();
            var code = nr1_tel+''+nr2_tel+''+nr3_tel+''+nr4_tel;
            that.eroare_cod_telefon = "";
            that.eroare_cod_telefon_en = "";
            
            axios.post('https://xpanel.tikibistro.ro/api/user/checkPhoneCode?t='+Number(new Date()), {
                    phone : that.telefon_comanda_nefinalizat,
                    country_code : that.cod_tara_nefinalizat,
                    code : code
                }, {
                  headers: {
                    "Access-Control-Allow-Origin" : "*",
                  },
                  withCredentials: false,
                }).then((response) => {
                      if(response.data.Status == 'OK')
                      {
                        $('.modal_4_checkout_verificare_telefon').hide();
                        $('.modal_start_checkout').fadeIn(100); 
                        $('.sweet-box-actions').fadeIn(100); 
                        that.form_register.phone=  that.telefon_comanda_nefinalizat.replace(/\s/g, '');
                        that.telefon_comanda =   that.form_register.phone;
                        that.cod_tara = that.cod_tara_nefinalizat;
                      
                        that.eroare_cod_telefon = "";
                        that.eroare_cod_telefon_en = "";
                      }
                      else
                      {
                        that.eroare_cod_telefon = "Codul introdus este invalid. Incercati din nou!";
                        that.eroare_cod_telefon_en = "This code is invalid. Please try again!";
                      }
                      
                      $(this).attr("disabled", false);

                }, (error) => {
                  $(this).attr("disabled", false);
                  that.eroare_cod_telefon = "Introduceti codul!";
                  that.eroare_cod_telefon_en = "Insert the code!";

                  var json = JSON.stringify(error);
                    var actiune = 'https://xpanel.tikibistro.ro/api/user/checkPhoneCode'+' DATE:'+that.telefon_comanda_nefinalizat+', '+that.cod_tara_nefinalizat;
                    that.trimiteLog(actiune,json); 

                });  
        });

        $('body').on('click','.actualizeaza_numar_null',function(){
            $(this).attr("disabled", true);

            var nr1_tel = $('.inputuri_cod_telefon_null .nr1_tel ').val();
            var nr2_tel = $('.inputuri_cod_telefon_null .nr2_tel ').val();
            var nr3_tel = $('.inputuri_cod_telefon_null .nr3_tel ').val();
            var nr4_tel = $('.inputuri_cod_telefon_null .nr4_tel ').val();
            var code = nr1_tel+''+nr2_tel+''+nr3_tel+''+nr4_tel;
            that.eroare_cod_telefon = "";
            that.eroare_cod_telefon_en = "";
            
            axios.post('https://xpanel.tikibistro.ro/api/user/checkPhoneCode?t='+Number(new Date()), {
                    phone : that.telefon_comanda_nefinalizat,
                    country_code : that.cod_tara_nefinalizat,
                    code : code
                }, {
                  headers: {
                    "Access-Control-Allow-Origin" : "*",
                  },
                  withCredentials: false,
                }).then((response) => {
                      if(response.data.Status == 'OK')
                      {
                        var token = localStorage.token;
                        that.eroare_adauga_adresa_noua = "";

                        if(token == '')
                        {
                          that.$methods.logout()
                          localStorage.removeItem('token')
                        }
                        else
                        {
                            axios.post('https://xpanel.tikibistro.ro/api/profile', {
                                phone : that.telefon_comanda_nefinalizat,
                                country_code : that.cod_tara_nefinalizat,
                                first_name : that.nume_user,
                                last_name : that.prenume_user,
                                email : that.email,
                                
                            }, {
                              headers: {
                                "Access-Control-Allow-Origin" : "*",
                                "Authorization": `Bearer ${token}`, 
                              },
                              withCredentials: false,
                            }).then(() => {

                              $('.verificare_telefon_null').hide();
                              $('.modal_telefon_null_1').fadeIn(100); 
                              $('.sweet-box-actions .sweet-action-close').click(); 
                              that.form_register.phone=  that.telefon_comanda_nefinalizat.replace(/\s/g, '');
                              that.telefon_comanda =   that.form_register.phone;
                              that.cod_tara = that.cod_tara_nefinalizat;
                            
                              that.eroare_cod_telefon = "";
                              that.eroare_cod_telefon_en = "";

                              $(this).attr("disabled", false);
                              $('.sweet-action-close').click();
                              if(/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
                                $('.alerta_telefon_null').animate({ "right": "10px"}, "slow");
                              } else{
                                  $('.alerta_telefon_null').animate({ "right": "20px"}, "slow");
                              }
                            setTimeout(function(){
                              if(/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
                                $('.alerta_telefon_null').animate({"right": "-1000px"}, "slow");
                              } else{
                                $('.alerta_telefon_null').animate({"right": "-100vw"}, "slow");
                              }
                            }, 2000);
                              that.showError_editare = false;
                            }, (error) => {
                              $(this).attr("disabled", false);
                              console.log(error);
                              that.showError_editare = true;
                            }); 
                        }

                        
                      }
                      else
                      {
                        that.eroare_cod_telefon = "Codul introdus este invalid. Incercati din nou!";
                        that.eroare_cod_telefon_en = "This code is invalid. Please try again!";
                      }
                      
                      $(this).attr("disabled", false);

                }, (error) => {
                  $(this).attr("disabled", false);
                  that.eroare_cod_telefon = "Introduceti codul!";
                  that.eroare_cod_telefon_en = "Insert the code!";

                  var json = JSON.stringify(error);
                    var actiune = 'https://xpanel.tikibistro.ro/api/user/checkPhoneCode'+' DATE:'+that.telefon_comanda_nefinalizat+', '+that.cod_tara_nefinalizat;
                    that.trimiteLog(actiune,json); 

                });  
        });

        $('body').on('click','.actualizeaza_numar_profil',function(){
            $(this).attr("disabled", true);

            var nr1_tel = $('.inputuri_cod_telefon_profil .nr1_tel').val();
            var nr2_tel = $('.inputuri_cod_telefon_profil .nr2_tel').val();
            var nr3_tel = $('.inputuri_cod_telefon_profil .nr3_tel').val();
            var nr4_tel = $('.inputuri_cod_telefon_profil .nr4_tel').val();
            var code = nr1_tel+''+nr2_tel+''+nr3_tel+''+nr4_tel;
            that.eroare_cod_telefon = "";
            that.eroare_cod_telefon_en = "";
           

            axios.post('https://xpanel.tikibistro.ro/api/user/checkPhoneCode?t='+Number(new Date()), {
                    phone : that.telefon_comanda_nefinalizat,
                    country_code : that.cod_tara_nefinalizat,
                    code : code
                }, {
                  headers: {
                    "Access-Control-Allow-Origin" : "*",
                  },
                  withCredentials: false,
                }).then((response) => {
                      if(response.data.Status == 'OK')
                      {
                        $('.modal_verificare_telefon').hide();
                        $('.modal_profilul_meu_tel .profilul_meu_content').fadeIn(100); 
                        $('.modal_profilul_meu_tel .profil_ascuns_mobile').fadeIn(100); 
                        that.form_register.phone =  that.telefon_comanda_nefinalizat.replace(/\s/g, '');
                        that.telefon_comanda = that.form_register.phone;
                        that.cod_tara = that.cod_tara_nefinalizat;
                        that.eroare_cod_telefon = "";
                        that.eroare_cod_telefon_en = "";
                      }
                      else
                      {
                        that.eroare_cod_telefon = "Codul introdus este invalid. Incercati din nou!";
                        that.eroare_cod_telefon_en = "This code is invalid. Please try again!";
                      }
                      
                      $(this).attr("disabled", false);

                }, (error) => {
                  $(this).attr("disabled", false);
                  that.eroare_cod_telefon = "Introduceti codul!";
                  that.eroare_cod_telefon_en = "Insert the code!";

                  var json = JSON.stringify(error);
                    var actiune = 'https://xpanel.tikibistro.ro/api/user/checkPhoneCode'+' DATE:'+that.telefon_comanda_nefinalizat+', '+that.cod_tara_nefinalizat;
                    that.trimiteLog(actiune,json); 

                });  
        });
        $('body').on('click','.ascunde_vezi_parola ', function(){	
            var x = document.getElementsByClassName("input_parola");
            
            if (x[0].type == "password") {
              $('.ascunde_parola').fadeIn(100);
              $('.vezi_parola').hide();
                x[0].type = "text";
              } else {
                x[0].type = "password";
                $('.vezi_parola').fadeIn(100);
                $('.ascunde_parola').hide();
              }	
        });
        $('body').on('click','.ascunde_vezi_parola2 ', function(){	
            var x = document.getElementsByClassName("parola_cont_login");
            
            if (x[0].type == "password") {
              $('.ascunde_parola2').fadeIn(100);
              $('.vezi_parola2').hide();
                x[0].type = "text";
              } else {
                x[0].type = "password";
                $('.vezi_parola2').fadeIn(100);
                $('.ascunde_parola2').hide();
              }	
        });
         
        $('body').on('click','.editeaza_numarul',function(){
            $('.modal_start_checkout').hide();
            $(this).closest('.sweet-modal').find('.sweet-box-actions').hide();
            $('.modal_4_checkout').fadeIn(100);  
        });
        $('body').on('click','.inapoi_modal_2_checkout, .editeaza_adresa_livrare',function(){
            $('.modal_3_checkout').hide(); 
            $('.modal_2_checkout').fadeIn(100); 
        });
        $('body').on('click','.inapoi_modal_start_checkout',function(){
            $(this).parent().hide();
            $('.sweet-box-actions').fadeIn(100); 
            $('.modal_start_checkout').fadeIn(100); 

        });
         $('body').on('click','.inapoi_modal_start',function(){
            $(this).parent().parent().hide(); 
            $('.modal_start').fadeIn(100); 
        });
        $('body').on('click','.log_in_cont',function(){
            $('.modal_start').hide();
            $('.modal3').fadeIn(100);  
            that.showError = false;
        });
        $('body').on('click','.recuperare_parola',function(){
            $('.email_parola_uitata').val('');
            $('.modal3').hide();
            $('.modal4').fadeIn(100);  
            that.eroare_recuperare_parola = '';
        });
        $('body').on('click','.inapoi_modal_3',function(){
            $('.modal4').hide();  
            $('.modal3').fadeIn(100);
        });
        $('body').on('input','.input_activ_edit',function(){
            $(this).closest('.sweet-content-content').find('.buton_dezactivat').addClass('edit_address_activ');  
        });
        $('body').on('input','.input_activ_adauga',function(){
            $(this).closest('.sweet-content-content').find('.buton_dezactivat').addClass('save_address_activ');  
        });
        
        $('.search_header').focus( function() {
          $(this).parent().addClass('search_absolute');
          $('.close_search_header').fadeIn(100);
        });

        $('.search_header').blur( function() {
          $(this).parent().removeClass('search_absolute');
          $('.close_search_header').hide();
        });
        $('.locatie_checkout').focus( function() {
          $(this).addClass('locatie_checkout_activ');
        });

        $('.locatie_checkout').blur( function() {
          $(this).removeClass('locatie_checkout_activ');
        });
        $('.adresa_noua').focus( function() {
          $(this).addClass('locatie_checkout_activ');
        });

        $('.adresa_noua').blur( function() {
          $(this).removeClass('locatie_checkout_activ');
        });
        $('.descriere_adresa_noua').focus( function() {
          $(this).addClass('locatie_checkout_activ');
        });

        $('.descriere_adresa_noua').blur( function() {
          $(this).removeClass('locatie_checkout_activ');
        });
        var search = this;
        $('.search_header').keypress(function(event){
            var keycode = (event.keyCode ? event.keyCode : event.which);
            if(keycode == '13'){
                search.search_key = $('.search_header').val();
                window.location.href = '/search?keywords='+ search.search_key;
                $('.search_header').val(search.search_key );
                
            }
            event.stopPropagation();
        });
        var addrr = this;

        
         $('body').on('click','.complete_adr_activ',function(){
            $(this).attr("disabled", true);
   
          addrr.adresa_selectata.adresa_2 = $('.locatie_checkout_modal_3').val();
          addrr.adresa_secundara = addrr.adresa_selectata.adresa_2;
           if(addrr.adresa_selectata.adresa_2 != '')
           {
              var token = localStorage.token;
              addrr.eroare_adauga_adresa_noua = "";

              if(token == '')
              {
                this.$methods.logout()
                localStorage.removeItem('token')
              }
              else
              {
              addrr.adresa_selectata.prima_adresa = addrr.prima_adresa_neschimbata; 
              addrr.adresa_generala = addrr.prima_adresa_neschimbata; 
              axios.post('https://xpanel.tikibistro.ro/api/user/add_address', {
                      address : addrr.adresa_selectata.prima_adresa  ,
                      description : addrr.adresa_secundara,
                      address_lat : addrr.lat_adresa,
                      address_lng : addrr.lng_adresa
                  }, {
                    headers: {
                      "Access-Control-Allow-Origin" : "*",
                      "Authorization": `Bearer ${token}`, 
                    },
                    withCredentials: false,
                  }).then((response) => {     
                        var total_gen = 0;
                        if(addrr.pret_dupa_voucher_fara_livrare != 0)
                            total_gen = addrr.pret_dupa_voucher_fara_livrare;
                        else
                            total_gen = addrr.total_pret_cos;

                        if(response.data.address_id == '0')
                        {
                          addrr.pret_livrare_general = 0;
                          addrr.pret_livrare = 0;
                        }
                        else
                        {
                          addrr.address_id = response.data.data.address_id;
                          addrr.calculeazaTransportGetArea(response.data.data.address_lat, response.data.data.address_lng, response.data.data.address, total_gen);
                        }   
                        addrr.adresa_a_fost_setata = 1;
                        $('.modal_3_checkout').hide(100);  
                        $('.modal_start_checkout').fadeIn(100);
                        $('.sweet-box-actions').fadeIn(100);
                        $(this).attr("disabled", false);
                  }, (error) => {
                    addrr.eroare_adauga_adresa_noua = error.response.data.errors[0]; 
                    addrr.eroare_completare_adresa = "Nu putem identifica adresa. Va rugam sa reincercati.";
                    addrr.eroare_completare_adresa_en = "We can`t identify this address. Please enter again.";
                     $(this).attr("disabled", false);
                     addrr.adresa_selectata.prima_adresa = '';
                     addrr.preiaInformatiiUser();

                     var json = JSON.stringify(error.response.data.errors[0]);
                    var actiune = 'https://xpanel.tikibistro.ro/api/user/add_address'+' DATE:'+addrr.adresa_selectata.prima_adresa+', '+addrr.adresa_secundara+', '+addrr.lat_adresa+','+addrr.lng_adresa;
                    addrr.trimiteLog(actiune,json); 


                  });   
              }
           }
           else
           {
             $(this).attr("disabled", false);
             addrr.eroare_completare_adresa = "Completati detaliile adresei.";
             addrr.eroare_completare_adresa_en = "Complete the details for address.";
             addrr.adresa_selectata.prima_adresa = '';
             addrr.preiaInformatiiUser();
           }    
        });

        var that = this;
        // $('body').on('click','.box_produs',function(){
        //     var slug = $(this).attr('dataslug');
        //     axios.get('https://xpanel.tikibistro.ro/api/products/getProductBySlug/'+slug, {
        //       withCredentials: false,
        //     })
        //     .then((response) => {
        //       that.nume_produs = response.data.data[0].additional.name_ro;
        //       that.descriere_produs = response.data.data[0].additional.description_ro; 
        //       that.nume_produs_en = response.data.data[0].additional.name_en;
        //       that.descriere_produs_en = response.data.data[0].additional.description_en;
        //       that.imagine_produs = response.data.data[0].images[0].path;
        //       that.pret = response.data.data[0].price;
        //       that.alergeni_en = response.data.data[0].alergeni_en;
        //       that.alergeni_ro = response.data.data[0].alergeni_ro;
        //       that.ingrediente_extra = response.data.data[0].attributes;
        //       that.id_produs = response.data.data[0].id; 
        //       that.external_id = response.data.data[0].options[0].external_id;
        //     }, (error) => {
        //     console.log(error);
        //     });   
        // });

      
    },
    filters: {
        strippedContent: function(string) {
              return string.replace(/<\/?[^>]+>/ig, ""); 
        },
         formatDate: function(value) {
            return moment(String(value)).lang("ro").format('DD MMMM YYYY H:mm')
        },
        tofixed: function (value) {
            return value.toFixed(2);
        }
    },
    
   
  }
   
</script>

<style>

</style>
